"use strict";

exports.secsToString = secs => {
  let sec_num = parseInt(secs, 10);

  if (!sec_num) return '00:00';

  const hours = Math.floor(sec_num / 3600) % 24;
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;
  return [hours, minutes, seconds]
    .map(v => v < 10 ? "0" + v : v)
    .filter((v, i) => v !== "00" || i > 0)
    .join(":")
};

exports.stringToSecs = string => {
  let time = string && string.split(":");

  let secs = 0, mins = 1;
  while (time && time.length > 0) {
    let popedValue = time.pop();
    let parsedInt = parseInt(popedValue, 10);
    secs += mins * parsedInt;
    mins *= 60;
  }

  return secs;
};
