"use strict";

import { Roles } from '../constants';
import { SessionActions } from '../actions';

const _redirectToDashboard = (nextState, replace) => {
  if (nextState.location.pathname !== "/") {
    window.location.href = "/";
  }
};

const _redirectToLogin = (nextState, replace, callback) => {
  SessionActions.loadSession((err, payload) => {
    if (!(payload.user && payload.user.credentials)) {
      window.location.href = '/';
    } else {
      const { adminRole } = payload.user.credentials;

      if (adminRole === "low-admin") {
        const { pathname } = nextState.location;
        const restricted = ADMIN_ROUTES_ON_WHICH_LOW_ADMIN_IS_NOT_ALLOWED
          .find(obj => obj.path === pathname);

        if (restricted) window.location.href = restricted.redirectTo;
        
        else callback();
      }
      else {
        callback();
      }
    }
  })
};

export {
  _redirectToDashboard as redirectToDashboard,
  _redirectToLogin as redirectToLogin
}


const ADMIN_ROUTES_ON_WHICH_LOW_ADMIN_IS_NOT_ALLOWED = [
  {
    path: "/admin/conferences",
    redirectTo: "/user/conferences"
  }
];