"use strict";

import alt from '../altInstance';

import { TemplateActions } from '../actions';

class TemplateStore {

  constructor() {
    this._templates = [];
    this._hostPicCdn='';
    this._hostVideoCdn='';
    this._isBusy = false;
    this._newTemplate = null;

    this.bindListeners({
      getTemplateById: TemplateActions.GET_TEMPLATE_BY_ID,
      getTemplates : TemplateActions.GET_TEMPLATES,
      create: TemplateActions.CREATE,
      update: TemplateActions.UPDATE,
      updateStatus: TemplateActions.UPDATE_STATUS,
      search: TemplateActions.search,
      getActiveTemplates: TemplateActions.getActiveTemplates,
      uploadHostVideoPic: TemplateActions.UPLOAD_HOST_VIDEO_PIC,
      updateHostVideoPic: TemplateActions.UPDATE_HOST_VIDEO_PIC,
    });

    this.publicMethods = {
      getTemplate: (templateId) => {
        return this._templates.find(t => t._id === templateId);
      },
      getNewTemplate: () => this._newTemplate || null
    }
  };

  getTemplateById(payload) {
    if(!payload.error) {
      const template = payload.data.template;
        if(template && template._id){
          if(this._templates.findIndex(t=>t._id===template._id) >-1){
            this._templates = this._templates
              .map(t => {
                if(t._id === template._id) t = template;
                return t;
              })
          } else {
            this._templates = this._templates.concat(template);
          }

      }
    }
  };

  create(payload) {
    if(!payload.error) {
      this._templates.push(payload.data.template);
    }
  };

  update(payload) {
    if(!payload.error) {
      const {data} = payload;
      this._templates = this._templates
        .map(template => {
          if(template._id === data.template._id) {
            if(data.template.hasOwnProperty('update') && data.template.update ==='failed'){
              this._newTemplate = {name:template.name,questVideos:template.questVideos};
              template.update='failed';
            }else {
              template = data.template;
            }
          }
          return template;
        });
    }
  };

  updateStatus(payload){
    this.update(payload);
  }

  getTemplates(payload) {
    if(!payload.error)
    this._templates = payload.data.results || [];
    // if(!payload.error && payload.data.results.length>0) {
      // this._templates = payload.data.results || [];
      // payload.data.results.map(template => {
        // if(this._templates.findIndex(t=>t._id===template._id)===-1){
        //   this._templates.push(template);
        // }
      // })
    // }
  };
  search(payload) {
    console.log("payload=============",payload);
    if(!payload.error) this._templates = payload.data.results || [];
    // if(!payload.error && payload.data.results.length>0) {
      // this._templates = payload.data.results || [];
      // payload.data.results.map(template => {
        // if(this._templates.findIndex(t=>t._id===template._id)===-1){
        //   this._templates.push(template);
        // }
      // })
    // }
  };
  getActiveTemplates(payload){
    if(!payload.error) this._templates = payload.data.results || [];
  }
  uploadHostVideoPic(payload){
    if(!payload.error) {
      this._hostVideoPicUploadProgress = payload.progress;
      this._isBusy = payload.progress < 100;
      this._hostPicCdn = payload.data && payload.data.hostPicCdn;
    }
  }

  updateHostVideoPic(payload){
    const data = payload.data;

    if(!payload.error) {
      this._templates = this._templates
        .map(template => {
          if(template._id === data.template._id) {
            template = data.template;
          }
          return template;
        });
    }
  }
}

export default alt.createStore(TemplateStore, 'TemplateStore');
