'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class AnswerActions {

  openAnswerPopup(payload) {
    return (dispatch) => {
      dispatch({
        answerPopup: true,
        questionId: payload.questionId,
        editAnswerPopup: payload && payload.editAnswerPopup
          ? payload.editAnswerPopup: null
      });
    };
  };

  closeAnswerPopup() {
    return (dispatch) => {
      dispatch({ answerPopup: false });
    };
  };

  create(payload,callback){
    return(dispatch) => {
      http
        .post('/api/answers')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(error) return AlertActions.error(error);
          if(callback && typeof callback == 'function'){
            callback(error,res.body);
          }
          AlertActions.success({ message: 'Answer posted successfully' });
        });
    }
  };

  getAnswersByQuestionId(query, callback){
    return(dispatch) => {
      http
        .get('/api/answers')
        .query(query)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          if (!res.body.answers.length) { 
            AlertActions.info({message: 'No more answers'});
          }
          if(callback && typeof callback == 'function'){
            callback(err,res.body.answers);
          }
        });
    }
  };
  searchAnswers(payload){
    // console.log("inside actioooooon", payload);
    return(dispatch)=>{
      http
        .get('/api/answers/manage')
        .query(payload)
        .end((err, res)=>{
          var error = err || res.error ? ServerError(res) : null;
          // console.log("action response------------>>>>", res.body);
          dispatch({
            error: error,
            data: res.body
          });
          if(error) return AlertActions.error(error);
        })
    }
  };
  
  approveAnswer(ansId ,status, cb){
  
    return(dispatch) => {
      http
        .put( `/api/answers/${ansId}/status`)
        .send(status)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if(error) return AlertActions.error(error);
          if(cb && typeof cb =='function'){
            cb(error,res.body);
          }
        });
    }
  }

  

  toggleUpVote(payload){
    return(dispatch) => {
      http
        .put('/api/answers')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          // AlertActions.success({message: 'upvote status '+payload.upVoteStatus+'sent for answerId '+payload.answerId});
        });
    }
  }
  updateHighLights(payload){
    return(dispatch) => {
      http
        .put('/api/answers/highlights')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          // AlertActions.success({});
        });
    }
  }

  answerCreated(payload){
    // console.log("enter in answerCreated in actions------",payload);
    return(dispatch)=>{
      dispatch({
        data: payload
      });  
    }  
  }
  answerDeleted(answerId,cb) {
    return(dispatch)=>{
      http
        .delete(`/api/answer/${answerId}`)
        .send()
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });

          if(error) return AlertActions.error(error);
          if(error) return AlertActions.error(error);
          if(cb && typeof cb =='function'){
            cb(error,res.body);
          }
          // AlertActions.success({ message: "Answer deleted successfully" })
        })
    }
  };
  saveWidgetAnswers(payload, callback){
    return(dispatch) => {
      http
        .put('/api/save-widget-question-answers')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof callback === "function") callback(error);
        });
    }
  }
}

export default alt.createActions(AnswerActions);
