'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import {ServerError} from '../helpers';
import {browserHistory} from 'react-router';

class AccountActions {

  openSignUpPopup() {
    return (dispatch) => {
      dispatch({signUpPopup: true});
    };
  };

  closeSignUpPopup() {
    return (dispatch) => {
      dispatch({signUpPopup: false});
    };
  };

  openForgotPasswordPopup() {
    return (dispatch) => {
      dispatch({forgotPasswordPopup: true});
    };
  };

  closeForgotPasswordPopup() {
    return (dispatch) => {
      dispatch({forgotPasswordPopup: false});
    };
  };

  register(payload, cb) {
    return (dispatch) => {
      http
        .post('/api/accounts/register')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if (typeof cb === 'function') return cb(error);
          if (error) AlertActions.error({message: error.message});
        });
    }
  };

  forgotPassword(payload, cb) {
    return (dispatch) => {
      http
        .post('/api/accounts/forgot-password')
        .send(payload)
        .end((err, res) => {

          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === 'function') cb(error);
          dispatch({
            error: error,
            user: res.body
          });

          if (error) return AlertActions.error(error);
          AlertActions.success({message: `Password reset instructions sent to email ${payload.email}`});
        });
    }
  };

  resetPassword(payload, cb) {
    // console.log("enter in reset password action");
    return (dispatch) => {
      http
        .post('/api/accounts/reset-password')
        .send(payload)
        .end((err, res) => {
          // console.log("res.error=========",err);
          var error = err || res.error ? ServerError(res) : null;

          if (error) {
            AlertActions.error(error);
            // console.log("ready to push");
            return browserHistory.push(`/`);
          }
          dispatch({
            error: error,
            data: res.body
          });
          if (cb && typeof cb === 'function') cb(error);
          AlertActions.success({message: 'Password reset successful'});
        });
    }
  };

  updateUser(payload, cb) {
    const url = window.location.href;
    const idx = url.indexOf('email=');
    const email = idx > -1 ? url.substring(idx + 6) : "";
    
    let path = `/api/accounts/updateUser`;
    if(email != "") path = `/api/accounts/admin-update-user/${email}`;

    return (dispatch) => {
      http
        .put(path)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (cb && typeof cb === 'function') cb(error);
        });
    }
  };
  updateUserFromAdmin(payload, cb) {
    const path = `/api/accounts/admin-update-user/${payload.userEmail}`;
    delete payload.userEmail;
    return (dispatch) => {
      http
        .put(path)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (cb && typeof cb === 'function') cb(error);
        });
    }
  };

  updateUserFromUpdateUsersPg(payload) {
    return (dispatch) => {
      http
        .put('/api/accounts/admin-update-user-from-update-users')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          AlertActions.success({message: 'Updated Successfully'});
        });
    }
  };

  getProfile() {
    return dispatch => {
      const url = window.location.href;
      const idx = url.indexOf('email=');
      const email = idx > -1 ? url.substring(idx + 6) : "";
      http
        .get(`/api/accounts/getProfile`)
        .query({ userEmail: email })
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) AlertActions.error({message: error.message});
        })
    }
  }

  makeRequestToAddEmail(payload, cb) {
    return (dispatch) => {
      http
        .put('/api/accounts/add-another-email-request')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (typeof cb === 'function') cb(error, res.body);
          // if(error) return AlertActions.error(error);
          // AlertActions.success({ message: 'Updated Successfully' });
        });
    }
  };

  checkEmailAvailable(email, cb) {
    return (dispatch) => {
      http
        .get('/api/accounts/check-email-available')
        .query({email})
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          // console.log("error=========",error);
          if (typeof cb === 'function') cb(error);
          // if(error){
          //   if(window.location.pathname.indexOf('/pt') > -1){
          //     console.log("enter in if");
          //     browserHistory.push(`/user/p`);
          //   }
          //   else if(window.location.pathname.indexOf('/ct') > -1){
          //     console.log("ready to go in conf page");
          //     browserHistory.push(`/user/conferences`);
          //   }
          //   // AlertActions.error(error);
          // }
          // else{
          //   dispatch({
          //     error: error,
          //     data: res.body
          //   });
          // }
        })
    }
  }
  
  checkEmailAvailabilityForMentor(payload, cb) {
    return (dispatch) => {
      http
        .get('/api/accounts/check-email-available-for-mentor')
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === 'function') cb(error);
        })
    }
  }

  checkEmailAvailabilityForMentorQuestionLink(payload, cb) {
    return (dispatch) => {
      http
        .get('/api/question-link/check-email-available-for-mentor')
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === 'function') cb(error);
        })
    }
  }

  makeEmailPrimary(payload, callback) {
    return (dispatch) => {
      http
        .post('/api/accounts/make-email-primary')
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          AlertActions.success({message: 'Primary email changed.'});
          if (typeof callback == "function") callback();

        });
    }
  }

  deleteEmailFromEmailsArray(payload, callback) {
    return (dispatch) => {
      http
        .post('/api/accounts/delete-email')
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          AlertActions.success({message: 'Email deleted.'});
          if (typeof callback == "function") callback();

        });
    }
  }

  createAdminAccount(payload, cb) {
    return (dispatch) => {
      http
        .post('/api/accounts/create-admin')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          cb(error);
        });
    }
  };

  createHostAccount(payload, cb) {
    return (dispatch) => {
      http
        .post('/api/accounts/create-host')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          cb(error);
        });
    }
  };

  createMentorAccount(payload, cb) {
    return (dispatch) => {
      http
        .post('/api/accounts/create-mentor')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          cb(error);
        });
    }
  };

  searchCountry(payload) {
    return dispatch => {
      http
        .get('/api/account/search-country')
        .query(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) AlertActions.error({message: error.message});
        })
    }
  }

  editHostAvailability = (payload, cb) => {
    return (dispatch) => {
      http
        .post('/api/user/edit-host-availability')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({ error: error, data: res.body });
          
          if (typeof cb === 'function') cb(error, res.body);
          if (error) return AlertActions.error(error);
          
          AlertActions.success({ message: 'Availability added successfully' });
        });
    }
  };

  // updateAvailability = (payload, cb) => {
  //   return (dispatch) => {
  //     http
  //       .put('/api/user/availability/edit')
  //       .send(payload)
  //       .end((err, res) => {
  //         dispatch({
  //           err: err,
  //           data: res.body
  //         })
  //         if (typeof cb === 'function') cb(err);
  //         if (err) return AlertActions.error(err);
  //         AlertActions.success({message: 'Availability updated successfully'});
  //       })
  //   }
  // };
  // deleteAvailability = (id, cb) => {
  //   return (dispatch) => {
  //     http
  //       .delete(`/api/user/availability/${id}`)
  //       .send()
  //       .end((err, res) => {
  //         dispatch({
  //           err: err,
  //           data: res.body
  //         })
  //         if (typeof cb === 'function') cb(err);
  //         if (err) return AlertActions.error(err);
  //         AlertActions.success({message: 'Availability Deleted successfully'});
  //       })
  //   }
  // };

  update(payload, recordingId) {
    return (dispatch) => {
      http
        .put(`/api/recording/${recordingId}`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          // AlertActions.success({ message: "Updated Successfully" })
        })
    }
  };


  // createAvailabilityByAdmin = (payload, cb) => {
  //   return (dispatch) => {
  //     http
  //       .post('/api/admin/availability/add')
  //       .send(payload)
  //       .end((err, res) => {
  //         var error = err || res.error ? ServerError(res) : null;
  //         dispatch({
  //           error: error,
  //           data: res.body
  //         });
  //         if (typeof cb === 'function') cb(error);
  //         if (error) return AlertActions.error(error);
  //         AlertActions.success({message: 'Availability added successfully'});
  //       });
  //   }
  // };

  // updateAvailabilityByAdmin = (payload, cb) => {
  //   return (dispatch) => {
  //     http
  //       .put(`/api/admin/availability/edit`)
  //       .send(payload)
  //       .end((err, res) => {
  //         dispatch({
  //           err: err,
  //           data: res.body
  //         })
  //         if (typeof cb === 'function') cb(err);
  //         if (err) return AlertActions.error(err);
  //         AlertActions.success({message: 'Availability updated successfully'});
  //       })
  //   }
  // };
  // deleteAvailabilityByAdmin = (payload) => {
  //   return (dispatch) => {
  //     http
  //       .delete(`/api/admin/availability/${payload.availId}`)
  //       .send({userId: payload.userId})
  //       .end((err, res) => {
  //         dispatch({
  //           err: err,
  //           data: res.body
  //         })
  //         // if(typeof cb === 'function') cb(err);
  //         if (err) return AlertActions.error(err);
  //         AlertActions.success({message: 'Availability Deleted successfully'});
  //       })
  //   }
  // };

  // getAvailability = () => {
  //   return (dispatch) => {
  //     http
  //       .get('/api/user/getAvailability')
  //       .end((err, res) => {
  //         dispatch({
  //           err: err,
  //           data: res.body
  //         })
  //       })
  //   }
  // }
  // getAvailabilityByAdmin = (payload) => {
  //   return (dispatch) => {
  //     http
  //       .get('/api/admin/getAvailability')
  //       .query(payload)
  //       .end((err, res) => {
  //         dispatch({
  //           err: err,
  //           data: res.body
  //         })
  //       })
  //   }
  // }
  addUserInWidget = (payload, cb) => {
    return (dispatch) => {
      http
        .post('/api/widget/add-mentor')
        .send(payload)
        .end((err, res) => {
          dispatch({
            err: err,
            data: res.body
          })
          if (cb && typeof cb == 'function') {
            cb(err);
          }
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else {
            AlertActions.success({message: 'User Added Successfully!!!'});
          }
        })
    }
  }
  removeMentorInWidget = (payload, cb) => {
    return (dispatch) => {
      http
        .post('/api/widget/remove-mentor')
        .send(payload)
        .end((err, res) => {
          dispatch({
            err: err,
            data: res.body
          })
          if (cb && typeof cb == 'function') {
            cb(err);
          }
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else {
            AlertActions.success({message: 'User Removed Successfully!!!'});
          }
        })
    }
  }
  updateUserChooseWidgetsWhereUserAppearAsMentor = (payload, cb) => {
    return (dispatch) => {
      http
        .put('/api/edit/user/choose-widgets-where-user-appear-as-mentor')
        .send(payload)
        .end((err, res) => {
          dispatch({
            err: err,
            data: res.body
          })
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else {
            if (cb && typeof cb == 'function') {
              cb(null, res.body);
            }

          }
        })
    }
  }
  getUserWidgets = (payload, cb) => {
    return (dispatch) => {
      http
        .get(`/api/user/widgets/${payload.userId}`)
        .end((err, res) => {
          dispatch({
            err: err,
            data: res.body
          })
          if (cb && typeof cb === 'function') {
            const error = err || res.error ? ServerError(res) : null;
            cb(error, res.body);
          }
        })
    }
  }
  getUserById = (payload, cb) => {
    return (dispatch) => {
      http
        .get(`/api/user/${payload.userId}`)
        .end((err, res) => {
          dispatch({
            err: err,
            data: res.body
          })
          if (cb && typeof cb === 'function') {
            const error = err || res.error ? ServerError(res) : null;
            cb(error, res.body);
          }
        })
    }
  }
  updateUserById = (payload, cb) => {
    return (dispatch) => {
      http
        .put(`/api/user/${payload.userId}`)
        .send(payload)
        .end((err, res) => {
          dispatch({
            err: err,
            data: res.body
          })
          if (cb && typeof cb === 'function') {
            const error = err || res.error ? ServerError(res) : null;
            cb(error, res.body);
          }
        })
    }
  }

  checkTokenExpire(token) {
    return (dispatch) => {
      http
        .get('/api/accounts/check-token')
        .query({token: token})
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          // console.log("error=========",error);
          if (error) {
            if (window.location.pathname.indexOf('/pt') > -1) {
              // console.log("enter in if");
              browserHistory.push(`/user/p`);
            } else if (window.location.pathname.indexOf('/ct') > -1) {
              // console.log("ready to go in conf page");
              // browserHistory.push(`/user/conferences`);
            }
            // AlertActions.error(error);  
          } else {
            dispatch({
              error: error,
              data: res.body
            });
          }
        })
    }
  }

  setPassword(payload) {
    return (dispatch) => {
      http
        .post('/api/accounts/set-password')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          // console.log("window.location.hostname======",window.location.pathname);
          if (error) {
            browserHistory.push(`/`);
            return AlertActions.error(error);
          }
          if (window.location.pathname.indexOf('/pt') > -1) {
            // console.log("enter in if");
            browserHistory.push(`/user/p`);
          } else if (window.location.pathname.indexOf('/ct') > -1) {
            // console.log("ready to go in conf page");
            // browserHistory.push(`/user/conferences`);
          }

          AlertActions.success({message: 'Password set successful'});
        });
    }
  };

  getUsers(payload) {
    return (dispatch) => {
      http
        .get('/api/accounts/users')
        .query(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        });
    }
  };

  redirectToMS(payload, redirectTo) {
    return (dispatch) => {
      http
        .post('/api/accounts/cd-to-ms')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          // console.log("data -----> ", res.body);
          if (error) {
            AlertActions.error(error);
            return browserHistory.push(`/`);
          }
          if (redirectTo == 'p') {
            return browserHistory.push(`/user/p`);
          }
          AlertActions.success({message: 'DONE'});
        });
    }
  }

  searchInUsers(query) {
    return (dispatch) => {
      http
        .get('/api/accounts/search-in-users')
        .query({query})
        .end((err, res) => {

          dispatch({error: error, data: res.body});

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
        });
    }
  }

  searchInUsersAndPening(query) {
    return (dispatch) => {
      http
        .get('/api/accounts/search-in-users-and-pending')
        .query({query})
        .end((err, res) => {

          dispatch({error: error, data: res.body});
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

        });
    }
  }

  getUserByIds(query) {
    return (dispatch) => {
      http
        .get(`/api/accounts/userByIds`)
        .query(query)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          dispatch({error: error, data: res.body});

          if (error) {
            AlertActions.error(error);
          }
        })
    }
  }

  // /api/delete-and-update-accounts
  mergeAccounts(payload, callback) {
    return (dispatch) => {
      http
        .post('/api/accounts/mergeAccounts')
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          AlertActions.success({message: 'profiles merged'});
          if (typeof callback == "function") callback();

        });
    }
  }

  getUserByIdInBoth(userId, callback) {
    return (dispatch) => {
      http
        .get(`/api/user-by-id/${userId}`)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          let result = res.body.result;

          if (error) {
            AlertActions.error(error);
            result = null;
          }

          if (typeof callback == "function") {
            callback(result);
          }
        })
    }
  }
  updatePhoneNumber(payload, cb) {
    return (dispatch) => {
      http
        .post(`/api/user/update-phone`)
        .send(payload)
        .end((err, res) => {
          if (typeof cb == "function") cb();

          var error = err || res.error ? ServerError(res) : null;
          if (error)  AlertActions.error(error);
          else AlertActions.success({message: 'Phone updated successfully!'});
        })
    }
  }
  getConnectedLiAccountOfMentor(userId, callback) {
    return (dispatch) => {
      http
        .get(`/api/user/get-connected-li-accounts-for-mentor/${userId}`)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;

          if (error) AlertActions.error(error);

          if (typeof callback == "function") {
            callback(error, res);
          }
        })
    }
  }  

  discardAccountToBeDeleted() {
    return true;
  }

  mergeRightProfileInLeft() {
    return true;
  }

  deleteJobOrSchool(type, userType, index) {
    return {
      type, userType, index
    };
  }

  resetAccountToKeptsState() {
    return true;
  }

  replaceOrAdd(type, index, type2) {
    return {
      type, index, type2
    }
  }

  swap(type, index) {
    return {
      type, index
    }
  }

  cancelSwaping(type, _id) {
    return {
      type, _id
    }
  }

  changePosition(type, array) {
    return {type, array};
  }

  toggleActiveButton(_id) {
    return {_id};
  }

  sendVerificationEmail(email, callback) {
    return (dispatch) => {
      http
        .get('/api/accounts/replace-email-for-pending-user')
        .query({ email })
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          let result = res.body.result;

          if (error) {
            AlertActions.error(error);
            if(typeof callback == 'function') callback();
          }
          else {
            AlertActions.success({ message: "Verification email sent. Please check your email." });
            if(typeof callback == 'function') callback();
          }
        })
    }
  }

  getUnverifiedHostsOfOMPC () {
    return (dispatch) => {
      http
        .get('/api/accounts/get-pending-users-for-ompc')
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          
          if (error) return AlertActions.error(error);

          dispatch({ error, data: res.body });

        });
    }
  }

  checkUserPhoneVerificationCompleted (payload, callback) {
    return dispatch => {
      http
        .put('/api/accounts/check-user-phone-verify')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
            
          if (error) AlertActions.error(error);

          if (typeof callback === 'function') {
            callback(error, res.body);
          }
        })
    }
  }
  
  callAgainForVerification(payload) {
    return () => {
      http
        .post('/api/w/common/get-verification-call')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          const { message, isError } = res.body;

          if (isError) return AlertActions.error({ message });
          else return AlertActions.success({ message });
        });
    }
  }

  updateUserTimezone (payload) {
    return () => {
      http
        .put('/api/user/update-timezone')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null; 
          if (error) return AlertActions.error(error);
        });
    }
  }

  getHostAvailability () {
    return (dispatch) => {
      http
        .get('/api/user/get-host-availability')
        .end((err, res) => {
          
          const error = err || res.error ? ServerError(res) : null; 
          if (error) return AlertActions.error(error);

          dispatch({ error, data: res.body });
        });
    }
  }

  getConfDetailForMentor(mentorId, cb) {
    return (dispatch) => {
      http
        .get(`/api/user/get-conf-detail/${mentorId}`)
        .end((err, res) => {
          
          const error = err || res.error ? ServerError(res) : null; 
          if (error) AlertActions.error(error);
          if (typeof cb === 'function') {
            cb(error, res.body);
          }
          // dispatch({ error, data: res.body });
        });
    }
  }
  getErrorLogs (query, cb) {
    return (dispatch) => {
      http
        .get(`/api/winston/get-errors`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null; 
          if (error) AlertActions.error(error);
          if (typeof cb === 'function') { cb(error, res.body);}
          // dispatch({ error, data: res.body });
        });
    }
  }

 
  updateGetProfileUsingLiAccount (payload, cd) {
    return () => {
      http
        .put("/api/accounts/update-getProfileUsingLiAccount")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null; 
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "Successfully updated" });
          if (typeof cb === 'function') cd (error);
        });
    }
  }

}


export default alt.createActions(AccountActions);
