"use strict";

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class SearchActions {
  searchOrganization() {
    return dispatch => {
      dispatch();
    }
  };

  searchTag() {
    return dispatch => {
      dispatch();
    }
  };

  searchUsers(payload) {
    return dispatch => {
      http
        .get('/api/search/profiles')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };
  searchEmailTemplateName(payload) {
    console.log("payload=========",payload);
    return dispatch => {
      http
        .get('/api/search/email-template')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };

  
  contentSearchWidget(payload) {
    // hardcoded for now
    let filter = {
      widgetId:"59d2f4b6396feb0d4fddc3f3",
      limit:payload.limit || 20,
      skip : payload.skip || 0
    }
    if (payload && payload.widgetName) {
      filter.widgetId = '';
      filter.widgetName = payload.widgetName;
    }
    return dispatch => {
      http
        .get('/api/search/videos')
        .query(filter)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };
  searchProcessedVideos(payload) {
    let filter = {
      limit:payload.limit || 20,
      skip : payload.skip || 0
    }
    return dispatch => {
      http
        .get('/api/processed/videos')
        .query(filter)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };
  searchOrganizations(payload) {
    return dispatch => {
      http
        .get('/api/search/organizations')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };
  searchWidgets(payload) {
    return dispatch => {
      http
        .get('/api/search/widgets')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };

  searchAll(payload, searchTimeStamp = Date.now()) {
    return dispatch => {
      http
        .get('/api/search/advanced')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body,
            searchTerm: payload.term || payload.keyword,
            searchTimeStamp
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };

  searchQuestion(payload) {
    return dispatch => {
      http
        .get('/api/questions/search')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };

  searchFeedbackQuestion(query, cb) {
    return () => {
      http
        .get('/api/feedback-questions/search')
        .query(query)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error({ message: error.message });
          if (cb) cb(res.body);
        });
    };
  };

  getConferencesByVideoTags () {
    return dispatch => {
      http
        .get('/api/home-page/conferences')
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ error: error, data: res.body });
          if(error) AlertActions.error({ message: error.message });
        });
    };
  }

  getMoreConferencesByTagId (payload, extra, callBack) {
    return dispatch => {
      http
        .post(`/api/home-page/get-conferences-for-video-tag`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ error: error, data: res.body, extra });
          if(error) return AlertActions.error({ message: error.message });
          if (typeof callBack === 'function') callBack();
        });
    };
  }

  changeNumberOfConferencesToSkip (payload, callBack) {
    return (dispatch) => {
      dispatch(payload);
      if (typeof callBack === 'function') callBack();
    }
  }

  getMoreUntaggedVideos (query, callBack) { 
    return dispatch => {
      http
        .get(`/api/home-page/get-untagged-videos`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ error: error, data: res.body });
          if(error) return AlertActions.error({ message: error.message });
          if (typeof callBack === 'function') callBack(error, res.body);
        });
      };
  }

  searchMentors (query, cb) {
    return dispatch => {
      http
        .get('/api/search/mentors-with-completed-conf')
        .query(query)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });

          if (typeof cb === 'function') cb(error, res.body);
        });
    };
  };

  searchCombTags (query, cb) {
    return dispatch => {
      http
        .get('/api/search/comb-tags')
        .query(query)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ error, data: res.body });
          if(error) AlertActions.error({ message: error.message });
          if (typeof cb === 'function') cb(error, res.body);
        });
    };
  };

  changeLogoSizeOnNav (size) {
    return { size };
  };

}

export default alt.createActions(SearchActions);
