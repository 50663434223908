"use strict";

import alt from '../altInstance';

import ChatActions from '../actions/ChatActions';
import SessionStore from './SessionStore';

class ChatStore {
  constructor() {
    this._rooms = [];
    this._users = [];
//    this._candidate4Mentor = null;
//    this._candidate4Admin = null;
//    this._sdpOffer4Mentor = null;
//    this._sdpOffer4Admin = null;
//    this._sdpAnswerFromAdmin = null;
//    this._sdpAnswerFromMentor = null;
    this._p2pStatus = {
      _mentor:'offline', _host:'offline', _hostOffer: 'notSent'
    };

    this.bindListeners({
      getMessages: ChatActions.GET_MESSAGES,
      newMessage: ChatActions.NEW_MESSAGE,
//      receiveCandidate: ChatActions.RECEIVE_CANDIDATE,
//      receiveSdp: ChatActions.RECEIVE_SDP,
      p2pStatus: ChatActions.P2P_STATUS,
      updateStatus: ChatActions.UPDATE_STATUS,
      clearStore : ChatActions.clearStore
    });

    this.publicMethods = {
      getMessagesByRoomId: function (roomId) {
        const { _rooms } = this.state;
        const room = _rooms.find(r=>r.roomId === roomId );
        return room && room.messages;
      },

      getUserStatus(userId) {
        return !!this._users.indexOf(userId);
      },
      getSdpOffer(userId) {
        return this._sdpAnswerFromMentor;
      }
    }
  };
  clearStore(){
    this._rooms = [];
    this._users = [];
//    this._candidate4Mentor = null;
//    this._candidate4Admin = null;
//    this._sdpOffer4Mentor = null;
//    this._sdpOffer4Admin = null;
//    this._sdpAnswerFromAdmin = null;
//    this._sdpAnswerFromMentor = null;
    this._p2pStatus = {
      _mentor:'offline', _host:'offline', _hostOffer: 'notSent'
    };
  }
  getMessages (payload) {
    if (!payload.error) {
      const index = this._rooms.findIndex(r=>r.roomId === payload.data.roomId );
      if(index <= -1){
        this._rooms.push(payload.data);
      }
      else{
        this._rooms[index] = payload.data;
      }
      // console.log("this._rooms--------",this._rooms);
    }
  };

  newMessage (payload) {
    const self = this;
    if(payload.data) {
      self._rooms.map(room=>{
        if (room.roomId === payload.data.roomId) {
          room.messages.push(payload.data);
          return room;
        }
      })
    }
  };

//  receiveCandidate(payload){
//    const {candidate, sentFor, sentFrom} = payload;
//
//    if(candidate) {
//      if(sentFor === 'mentor'){
//        this._candidate4Mentor = candidate;
//      } else if(sentFor === 'admin') {
//        this._candidate4Admin = candidate;
//      } else if(sentFor === 'host'){
//        if(sentFrom === 'mentor') {
//          this._candidate4HostFromMentor = candidate;
//        } else if(sentFrom === 'admin'){
//          this._candidate4HostFromAdmin = candidate;
//        } else {
//          console.error("this case should not happen");
//        }
//      } else {
//        console.error("this case should not happen");
//      }
//    }
//  }

//  receiveSdp(payload){
//    const {sdp, sentFor, sentFrom} = payload;
//    if(sdp) {
//      if(sdp.type ==='offer'){
//        if(sentFor === 'mentor'){
//          this._sdpOffer4Mentor = sdp;
//        } else if(sentFor === 'admin'){
//          this._sdpOffer4Admin = sdp;
//        } else {
//          console.error("this case should not happen");
//        }
//      } else {
//        if(sentFrom === 'mentor'){
//          this._sdpAnswerFromMentor = sdp;
//        } else if(sentFrom === 'admin'){
//          this._sdpAnswerFromAdmin = sdp;
//        } else {
//          console.error("this case should not happen");
//        }
//      }
//    }
//  }

  p2pStatus({mentor, host, hostOffer}){
    mentor ? this._p2pStatus._mentor = mentor : "";
    hostOffer ? this._p2pStatus._hostOffer = hostOffer : "";

    if(host){
      this._p2pStatus._host = host;
      if(host === 'offline'){
        this._p2pStatus._hostOffer = 'notSent'
      }
    }
  }

  updateStatus (payload) {
    if(!payload.err) {
      const sessionUser = SessionStore.getSessionUser();
      this._rooms.map(room=> {
        if (room.roomId === payload.data.roomId) {
          room.messages.map(message => {
              if(payload.data.messages.indexOf(message._id) !== -1) message.seenBy.push(sessionUser._id);
              return message;
            });
          return room;
        }
      })
      // _.chain(this._rooms)
      //   .map(room=> {
      //     if (room.roomId === payload.data.roomId) {
      //       room
      //         .messages
      //         .map(message => {
      //           if(payload.data.messages.indexOf(message._id) !== -1) message.seenBy.push(sessionUser._id);
      //           return message;
      //         });
      //       return room;
      //     }
      //   })
      //   .value();
    }
  }
}

export default alt.createStore(ChatStore, 'ChatStore');
