"use strict";

import React from 'react';
import AuthorizedComponent from './AuthorizedComponent';
import SessionStore from '../../stores/SessionStore';

class RestrictedContainer extends AuthorizedComponent {
  constructor(props) {
    super(props);

    const sessionUser = SessionStore.getSessionUser();

    this.userRoles = sessionUser && sessionUser.roles || [1];
    this.notAuthorizedPath = '/';
  };

  render() {
    return (
      <div>
        { this.props.children }
      </div>
    );
  };
}

export default RestrictedContainer;
