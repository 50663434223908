'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';
import { copyFileSync } from 'fs';
import { dispatch } from 'alt/lib/utils/AltUtils';

class BootcampActions {
  getAssignments(cb) {
    return dispatch => {
      http
        .get(`/api/bootcamp/get-assignments`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        });
    }
  }
  createAssignment(payload, cb) {
    return () => {
      http
        .post(`/api/bootcamp/create-assignment`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error);
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Created successfully!" });

        });
    }
  }
  updateAssignment(id, payload, cb) {
    return () => {
      http
        .put(`/api/bootcamp/update-assignment/${id}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error);
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });
        });
    }
  }

  deleteAssignment(id, cb) {
    return dispatch => {
      http
        .delete(`/api/bootcamp/delete-assignment/${id}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Deleted successfully!" });
        });
    }
  }

  createInternalCourse(payload, cb) {
    return () => {
      http
        .post("/api/bootcamp/internal-course")
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Created successfully!" });
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }

  getAllCourses(cb) {
    return dispatch => {
      http
        .get("/api/bootcamp/get-all-courses")
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({ error: error, data: res.body });
          if(cb) cb(error, res);
          if (error) return AlertActions.error(error);
        });
    }
  }
  deleteInternalCourses(courseId, cb){
    return dispatch => {
      http
        .delete(`/api/bootcamp/delete-internal-course/${courseId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Deleted successfully!" });
        });
    }
  }

  createCohortMaterial(payload, cb) {
    return () => {
      http
        .post("/api/bootcamp/cohort-material")
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Created successfully!" });
          if (typeof cb === "function") cb(error, res);
        });
    }
  }
  getQuizs(cb) {
    return dispatch => {
      http
        .get(`/api/bootcamp/get-quizs`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        });
    }
  }
  createQuiz(payload, cb) {
    console.log("createQuiz payload===========",payload.options);
    return dispatch => {
      let httpObj = http
        .post(`/api/bootcamp/create-quiz`)
        .field('name', payload.name)
        .field('questions', JSON.stringify(payload.questions))

      if(payload.file){
        httpObj = httpObj.attach('file', payload.file);
      }
      httpObj.end((err, res) => {
        var error = err || res.error ? ServerError(res) : null;
        cb(error);
        dispatch({
          error: error,
          data: res.body
        });
        if (error) return AlertActions.error(error);
        else AlertActions.info({ message: "Created successfully!" });
      });
    }
  }
  uploadQuizFile(payload, cb) {
    console.log("createQuiz payload===========",payload.options);
    return dispatch => {
      http
        .post(`/api/bootcamp/upload-quiz-file/${payload.id}`)
        .field('quizName', payload.quizName)
        .attach('file', payload.file)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Uploaded successfully!" });
      });
    }
  }
  updateQuizQuestion(id, payload, cb) {
    return dispatch => {
      http
        .put(`/api/bootcamp/update-quiz-question/${id}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });
        });
    }
  }
  createQuizQuestion(id, payload, cb) {
    return dispatch => {
      http
        .put(`/api/bootcamp/create-quiz-question/${id}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Created successfully!" });
        });
    }
  }
  deleteQuiz(quizId, cb){
    return dispatch => {
      http
        .delete(`/api/bootcamp/delete-quiz/${quizId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Deleted successfully!" });
        });
    }
  }
  deleteQuizFile(quizId, payload, cb){
    return dispatch => {
      http
        .delete(`/api/bootcamp/delete-quiz-file/${quizId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Deleted successfully!" });
        });
    }
  }

  getCohortMaterials(cb) {
    return () => {
      http
        .get("/api/bootcamp/get-cohort-materials")
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }

  getCohortMaterialById(cohortId, cb) {
    return () => {
      http
        .get("/api/bootcamp/cohort-material/" + cohortId)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  addCohortAssignment(cohortId, payload, cb) {
    return () => {
      http
        .put(`/api/bootcamp/add-cohort-assignment/${cohortId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  updateCohortNameAndStudents = (cohortId, payload)=>{
    return () => {
      http
        .put(`/api/bootcamp/update-cohort-name-students/${cohortId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  updateCohortAssignment(cohortId, payload, cb) {
    return () => {
      http
        .put(`/api/bootcamp/update-cohort-assignment/${cohortId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  deleteCohortAssignment(cohortId, payload, cb) {
    return () => {
      http
        .delete(`/api/bootcamp/delete-cohort-assignment/${cohortId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Deleted successfully!" });
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }

  addNewCourseInCohortMaterial(payload, cb) {
    return () => {
      http
        .put("/api/bootcamp/cohort-material/add-course")
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }

  removeCourseFromCohortMaterial(query, cb) {
    return () => {
      http
        .delete("/api/bootcamp/cohort-material/delete-course")
        .query(query)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  getLectures(cb) {
    return dispatch => {
      http
        .get(`/api/bootcamp/get-lectures`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        });
    }
  }
  createArticleLecture(payload, cb) {
    return dispatch => {
      let httpObj = http.post(`/api/bootcamp/create-article-lecture`);

        if(payload.name){
          httpObj = httpObj.field('name', payload.name);
        }
        if(payload.description){
          httpObj = httpObj.field('description', payload.description);
        }
        if(payload.file){
          httpObj = httpObj.attach('file', payload.file);
        }

        httpObj.end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Created successfully!" });
        });
    }
  }
  updateArticleLecture(id, payload, cb) {
    return dispatch => {
      http
        .post(`/api/bootcamp/update-article-lecture/${id}`)
        .field('name', payload.name)
        .field('description', payload.description)
        .attach('file', payload.file)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Update successfully!" });
        });
    }
  }

  updateLectureWithoutFile(id, payload,cb){
    return () => {
      http
        .put(`/api/bootcamp/update-lecture-without-file/${id}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });

          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  deleteLecture(id, cb) {
    return dispatch => {
      http
        .delete(`/api/bootcamp/delete-lecture/${id}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Deleted successfully!" });
        });
    }
  }
  getCohortGroups(cohortId, cb){
    return () => {
      http
        .get(`/api/bootcamp/get-cohort-groups/${cohortId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          // else AlertActions.info({ message: "Updated successfully!" });

          if (typeof cb === "function") cb(error, res);
        });
    }
  }
  assignNewMemberToCohortGroup(cohortId, payload,cb){
    return () => {
      http
        .put(`/api/bootcamp/assign-new-member-to-cohort-group/${cohortId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });

          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  deleteCohortGroup(cohortId, payload, cb) {
    return dispatch => {
      http
        .delete(`/api/bootcamp/delete-cohort-group/${cohortId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Deleted successfully!" });
        });
    }
  }
  addStudentsInCohort(cohortId, payload, cb) {
    return dispatch => {
      http
        .put(`/api/bootcamp/add-students-in-cohort/${cohortId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Added successfully!" });
        });
    }
  }
  getCohortStudents(cohortId, cb) {
    return dispatch => {
      http
        .get(`/api/bootcamp/get-cohort-students/${cohortId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          // else AlertActions.info({ message: "Added successfully!" });
        });
    }
  }
  createExternalCourse(payload, cb) {
    return () => {
      http
        .post("/api/bootcamp/create-external-course")
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Added successfully!" });
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }
  updateExternalCourse(courseId, payload, cb) {
    return dispatch => {
      http
        .put(`/api/bootcamp/update-external-course/${courseId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });
        });
    }
  }
  getExternalCourse(courseId, cb) {
    return dispatch => {
      http
        .get(`/api/bootcamp/get-external-course/${courseId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          if (error) return AlertActions.error(error);
          // else AlertActions.info({ message: "Added successfully!" });
        });
    }
  }
  getUserCourses(cb) {
    return dispatch => {
      http
        .get(`/api/bootcamp/get-user-courses`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        });
    }
  }
  updateStudentsCommitUrl(payload, cb) {
    return dispatch => {
      http
        .put(`/api/bootcamp/student-update-commit-url`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res);
          if (error) return AlertActions.error(error);
          else AlertActions.info({ message: "Updated successfully!" });
        });
    }
  }

  getCohortForGroupMembersReview(cb) {
    return () => {
      http
        .get("/api/bootcamp/get-cohorts-for-group-memebers-review")
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(error, res.body);
        });
    }
  }

  getCurrentGroupMemebers(query, cb) {
    return () => {
      http
        .get("/api/bootcamp/get-current-group-memebers")
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(error, res.body);
        });
    }
  }

  submitGroupMembersReviews(payload, cb) {
    return () => {
      http
        .post("/api/bootcamp/submit-group-memeber-reviews")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(error, res.body);
        });
    }
  }
  
  getStudentsAssignmentResponse(assignmentId, cb) {
    return () => {
      http
        .get(`/api/bootcamp/get-student-assignment-response/${assignmentId}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(error, res);
        });
    }
  }

  getStudentDashboardData(cb) {
    return () => {
      http
        .get("/api/bootcamp/get-student-dashboard-data")
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(error, res.body);
        });
    }
  }

  getCohortAssignments(cohortId, cb) {
    return () => {
      http
        .get("/api/bootcamp/get-assignments-of-cohort/" + cohortId)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(res.body);
        });
    }
  }

  getAllDemosForAssignment(query, cb) {
    return () => {
      http
        .get("/api/bootcamp/get-all-demo-for-assignment")
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(res.body);
        })
    }
  }

  updateDemoFeedBackForUser(payload, cb) {
    return () => {
      http
        .put("/api/bootcamp/update-demo-feedback-for-user")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(res.body);
        });
    }
  }

  getDailyAudios(query, cb) {
    return () => {
      http
        .get("/api/bootcamp/get-daily-audios")
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(res.body);
        });
    }
  };

  getDailyAudiosForCohort(query, cb) {
    return () => {
      http
        .get("/api/bootcamp/get-daily-audios-for-cohort")
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(res.body);
        });
    }
  };
  updateFeedbackForAudio(payload, cb) {
    return () => {
      http
        .put("/api/bootcamp/update-feedback-for-users-audio")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          cb(err, res.body);
        });
    }
  }

  deleteDailyAudio (dailyAudioId, cb) {
    return () => {
      http
        .delete(`/api/bootcamp/daily-audio/${dailyAudioId}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb();
        });
    }
  }

  submitDailyAudioFeedback(payload, cb) {
    return () => {
      http
        .put(`/api/bootcamp/submit-daily-audio-feedback-by-user`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb();
        });
    }
  }

  createUpdateFeedbackQuestion(payload, cb) {
    return () => {
      http
        .put(`/api/bootcamp/feedback-question`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb();
          AlertActions.success({ message: "Question updated" });
        });
    }
  }

  getFeedbackQuestion(query, cb) {
    return () => {
      http
        .get(`/api/bootcamp/feedback-questions`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb(res.body);
        });
    }
  }

  deleteFeedbackQuestion(questionId, cb) {
    return () => {
      http
        .delete(`/api/bootcamp/feedback-question/${questionId}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb();
          AlertActions.success({ message: "Question deleted successfully" });
        });
    }
  }

  createOrUpdateFeedbackTemplate(payload, cb) {
    return () => {
      http
        .put(`/api/bootcamp/feedback-tempalte`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb();
          AlertActions.success({ message: "Successfully done" });
        });
    }
  }

  getFeedbackTemplates(query, cb) {
    return () => {
      http
        .get(`/api/bootcamp/feedback-templates`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb(res.body);
        });
    }
  }

  deleteFeedbackTemplates (templateId, cb) {
    return () => {
      http
        .delete(`/api/bootcamp/feedback-template/${templateId}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb) cb();
          AlertActions.success({ message: "Template deleted successfully" });
        });
    }
  }
}






export default alt.createActions(BootcampActions);
