"use strict";

import React from "react";
import moment from "moment-timezone";
import { Modal } from 'react-bootstrap';
import WidgetList from '../../../components/WidgetList';



export default class UploadQstnFileModal extends React.PureComponent {

    constructor(props) {
        super(props)
        this.input = null;
        const { questionFor = "", questionType = "", widget, text } = props.fileToBeEdited || {};
        this.state = {
            fileList: [],
            questionFor,
            questionType,
            file: null,
            widget,
            text
        }
    }


    onChangeQstnType = (e) => {
        this.setState({ questionType: e.target.value });
    }

    onChangeQstnFor = (e) => {
        this.setState({ questionFor: e.target.value });
    }
    onTextChange = (e)=>{
        console.log("enter in onTextChange======",e.target.value);
        this.setState({ text: e.target.value });
    }
    handleFileInput = () => {
        this.input.click();
    }

    // On selecting the file 
    onFileChange = event => {
        this.setState({ file: event.target.files[0] });
    };

    render() {
        let { onFileUpload, onClosePopup } = this.props;
        let { questionType, questionFor, file, widget, text } = this.state;
        console.log("widget in render========",widget, text);
        return (
            <Modal backdrop='static' bsSize="lg" show={true} onHide={onClosePopup}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>Upload ulaw question file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="qstn-file-upload-modal-body">
                        <WidgetList
                            defaultWidget ={widget}
                            onSelection={this._handleWidgetSelection}
                        />
                        <br />
                        <input ref={ref => this.input = ref} type="file" onChange={this.onFileChange} />
                        <br />
                        <div>
                            <p>Choose the type of question</p>
                            <div>
                                <input name="type" type="radio" value="Audio Recording" checked={questionType === "Audio Recording"} onChange={this.onChangeQstnType} />
                                <label className="ml5">Voice Recording</label>
                            </div>
                            <div>
                                <input name="type" type="radio" value="Text" checked={questionType === "Key Press"} onChange={this.onChangeQstnType} />
                                <label className="ml5">Text</label>
                            </div>

                        </div>
                        <br />
                        <div>
                            <p>Choose whom this question is for</p>
                            <div className="align-horizontal">
                                <input name="for" type="radio" value="Mentor" checked={questionFor === "Mentor"} onChange={this.onChangeQstnFor} />
                                <label className="ml5">Mentor</label>

                            </div>
                            <div className="align-horizontal">
                                <input name="for" type="radio" value="Host" checked={questionFor === "Host"} onChange={this.onChangeQstnFor} />
                                <label className="ml5">Host</label>
                            </div>
                            <div className="align-horizontal">
                                <input name="for" type="radio" value="Both" checked={questionFor === "Both"} onChange={this.onChangeQstnFor} />
                                <label className="ml5">Both</label>
                            </div>
                        </div>
                        <div>
                            <p>Question text</p>
                            <div className="align-horizontal">
                                <input className="form-control" value={text} onChange={this.onTextChange} />

                            </div>
                        </div>
                        <button 
                            className="btn btn-primary mt10"
                            onClick={() => onFileUpload(file, questionFor, questionType, widget, text)}>
                            Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    _handleWidgetSelection = (widget)=>{
        console.log("enter in _handleWidgetSelection",widget);
        this.setState({widget});
    }
    _handleSubmit = () => {
        const payload = {

        };

        this.props.onFileUpload; (paylaod)
    }

}

