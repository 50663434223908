"use strict";

import Alt from '../altInstance';
import { SearchActions } from '../actions';

const singleUserContentWidth = 300;
class SearchStore {
  constructor() {
    this._questions = [];
    this._organizations = [];
    this._tags = [];
    this._users = [];
    this._mentors = [];
    this._searchTerm = '';
    this._pagination = {
      users: { total: 0 },
      organizations: { total: 0 },
      mentors:{total:0}
    };

    this.conferences = [];
    this.combTags = [];
    this.untaggedVideos = [];
    this.totalUntaggedVideos = null;
    this.searchTimeStamp = null;
    this.sizeLogoOnNavForMobile = "large"; // "small", "large"
    this.taggedConferencesIds = "";

    this.bindListeners({
      searchQuestion: SearchActions.SEARCH_QUESTION,
      searchOrganizations: SearchActions.SEARCH_ORGANIZATIONS,
      searchWidgets: SearchActions.SEARCH_WIDGETS,
      searchTag: SearchActions.SEARCH_TAG,
      searchUsers: SearchActions.SEARCH_USERS,
      searchAll: SearchActions.SEARCH_ALL,
      contentSearchWidget:SearchActions.contentSearchWidget,
      searchProcessedVideos : SearchActions.searchProcessedVideos,
      searchEmailTemplateName : SearchActions.searchEmailTemplateName,
      getConferencesByVideoTags: SearchActions.getConferencesByVideoTags,
      getMoreConferencesByTagId: SearchActions.getMoreConferencesByTagId,
      changeNumberOfConferencesToSkip: SearchActions.changeNumberOfConferencesToSkip,
      getMoreUntaggedVideos:  SearchActions.getMoreUntaggedVideos,
      changeLogoSizeOnNav: SearchActions.changeLogoSizeOnNav
    });

    this.publicMethods = {
      cleanup: () => {
        this._questions = [];
        this._templates = [];
        this._organizations = [];
        this._tags = [];
        this._users = [];
      },

      getSearchTerm: () => {
        return this._searchTerm;
      }
    }
  };

  searchOrganizations(payload) {
    if(!payload.error) {
      this._organizations = payload.data.items;
      this._pagination.organizations.total = payload.data.total;
    }
  };
  searchWidgets(payload) {
    if(!payload.error) {
      this._widgets = payload.data.results;
    }
  };

  searchTag(payload) {
    if(!payload.error) {
      this._tags = payload.data.items;
      this._pagination.tags.total = payload.data.total;
    }
  };

  searchUsers(payload) {
    if(!payload.error) {
      this._users = payload.data.items;
      this._pagination.users.total = payload.data.total;
    }
  };
  searchEmailTemplateName(payload) {
    if(!payload.error) {
      this._emailTemplates = payload.data.emailTemplates;
    }
  };
  contentSearchWidget(payload) {
    if(!payload.error) {
      this._mentors = payload.data.items;
    }
  };
  searchProcessedVideos(payload){
    if(!payload.error){
      this._pagination.mentors.total = payload.data.totalUsers;
      this._mentors = payload.data.conferences;
    }
  }
  searchQuestion(payload) {
    if(!payload.error) {
      this._questions = payload.data.results;
    }
  };

  searchAll(payload) {
    if(!payload.error && payload.data) {
      const { searchTimeStamp, data, searchTerm } = payload;
      if (searchTimeStamp && searchTimeStamp <= this.searchTimeStamp) return;
      
      this.searchTimeStamp = searchTimeStamp;
      this._searchTerm = searchTerm;
      this._searchResult = formatUserData(data);
      this._pagination.users.total = payload.data.users.total;
      // this._organizations = payload.data.organizations.items;
      this._pagination.organizations.total = payload.data.organizations.total;
    }
  }

  getConferencesByVideoTags (payload) {
    if (!payload.error && payload.data && payload.data.combTagsWithConferences) {
      
      const {
        combTags = [], conferences = [], untaggedVideos = [], totalUntaggedVideos
      } = payload.data.combTagsWithConferences;
      
      this.combTags = combTags.map(combTag => ({ ...combTag, numberOfConferencesToSkip: 0 }));
      this.conferences = AddDistinctConferences(this.conferences, conferences);
      this.untaggedVideos = untaggedVideos;
      this.totalUntaggedVideos = totalUntaggedVideos;
      this.taggedConferencesIds = this.conferences.map(({ _id }) => _id).join("$$");
    }
  }
  
  getMoreConferencesByTagId (payload) {
    if (!payload.error && payload.data) {
      let { numberOfConferencesToSkip, oldNumberOfConferencesToSkip, matricsSize } = payload.extra;
      
      const { conferences: newConferences, combTagId } = payload.data;

      const newConfsIds = newConferences.map(({ _id }) => _id.toString());

      this.conferences = AddDistinctConferences(this.conferences, newConferences);
      this.taggedConferencesIds = this.conferences.map(({ _id }) => _id).join("$$");

      this.combTags = this.combTags.map(combTag => {
        if (combTag.combTagId !== combTagId) return combTag;

        const newConferenceIds = [...combTag.conferenceIds];
        newConfsIds.forEach(confId => {
          if (!newConferenceIds.includes(confId)) newConferenceIds.push(confId);
        });

        const conferenceWeNeed = numberOfConferencesToSkip - oldNumberOfConferencesToSkip;
        const conferenceWeGet = newConferenceIds.length - combTag.conferenceIds.length;

        if (conferenceWeGet < conferenceWeNeed) {
          numberOfConferencesToSkip = newConferenceIds.length - matricsSize;

          return {
            ...combTag,
            numberOfConferencesToSkip,
            conferenceIds: newConferenceIds,
            totalConferencesForTag: newConferenceIds.length
          };
        }
        
        return { ...combTag, numberOfConferencesToSkip, conferenceIds: newConferenceIds };
      });
    }
  }

  changeNumberOfConferencesToSkip (payload) {
    const { combTagId, numberOfConferencesToSkip } = payload;
    this.combTags = this.combTags.map(combTag => {
      if (combTag.combTagId !== combTagId) return combTag;
      combTag.numberOfConferencesToSkip = numberOfConferencesToSkip;
      return combTag;
    });
  }

  getMoreUntaggedVideos (payload) {
    if (!payload.error && payload.data && payload.data.untaggedVideos) {
      const { untaggedVideos = [] } = payload.data;
      this.untaggedVideos = [...this.untaggedVideos, ...untaggedVideos];
    }
  }

  changeLogoSizeOnNav (payload) {
    // console.log("payload", payload);
    const { size } = payload;
    this.sizeLogoOnNavForMobile = size;
  }
}

export default Alt.createStore(SearchStore, 'SearchStore');


function formatUserData (data) {

  const { users: { items, total } } = data;

  const formatedItems = items.map(item => {
    const { _source } = item;

    const jobs = [], schools = [];

    for (let i=1; i<=20; i++) {
      const jobKey = `job_${i}`, schoolKey = `school_${i}`;
      if (_source[jobKey]) jobs.push(_source[jobKey]);
      if (_source[schoolKey]) schools.push(_source[schoolKey]);
    }

    const { firstName, lastName, userName, location, gender, liUrl, profilePicUrl } = _source;

    return {
      ...item,
      _source: {
        firstName, lastName, userName, location, gender, liUrl, profilePicUrl, jobs, schools
      }
    };
  });

  return { users: { items: formatedItems, total } };
}

function AddDistinctConferences (conferences = [], newConferences = []) {
  if (conferences.length === 0) return newConferences;

  newConferences.forEach(newConf => {
    const found = conferences.find(({ _id }) => _id === newConf._id);
    if (!found) conferences.push(newConf);
  });

  return conferences;
}

function GetNumberofVideoAllowed () {
  const width = screen.width;
  let maxCompleteUserInOneRow = 0;
  
  if (width <= 567) maxCompleteUserInOneRow = 1;
  else if (width <= 767) maxCompleteUserInOneRow = 2;
  else if (width <= 991) maxCompleteUserInOneRow = 3;
  else if (width <= 1400) maxCompleteUserInOneRow = 4;
  else maxCompleteUserInOneRow = 5; // width > 1400px

  const singleUserContentWidth = width / maxCompleteUserInOneRow;

  return { singleUserContentWidth, maxCompleteUserInOneRow };
}
