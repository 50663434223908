export default [
  {
    label: 'Super Admin Dashboard',
    url: '/admin/dashboard',
    isLink: true 
  },
  {
    label: 'Super Admin Profile',
    url: '/admin/p',
    isLink: true
  },
  {
    label: 'Super Admin Conferences',
    url: '/admin/conferences',
    isLink: true
  },
  {
    label: 'Super Admin Create Admin Account',
    url: '/admin/create-admin',
    isLink: true
  },
  {
    label: 'Super Admin Create Host Account',
    url: '/admin/create-host',
    isLink: true
  },
  {
    label: 'Super Admin Create Mentor Account',
    url: '/admin/create-mentor',
    isLink: true
  },
  {
    label: 'Super Admin Manage Organization',
    url: '/admin/organizations',
    isLink: true
  },
  {
    label: 'Super Admin Question Template',
    url: '/admin/question-templates',
    isLink: true
  },
  {
    label: 'Super Admin Create Question Template',
    url: '/admin/question-templates/create',
    isLink: true
  },
  {
    label: 'Super Admin Search Questions',
    url: '/admin/copied-from-question',
    isLink: true
  },
  {
    label: 'Super Admin Create Copied From',
    url: '/admin/copied-from-question/create',
    isLink: true
  },
  // {
  //   label: 'Super Admin Availability',
  //   url: '/admin/availability',
  //   isLink: true
  // },
  {
    label: 'Super Admin Add User Widget',
    url: '/admin/add-user-widget',
    isLink: true
  },  
  {
    label: 'Super Admin Search User Widgets',
    url: '/admin/user-widgets',
    isLink: true
  },  
  // {
  //   label: 'Super Admin Add pending user',
  //   url: '/admin/add-li-pending-user',
  //   isLink: true
  // }, 
  {
    label: 'Super Admin Manage Widget Questions',
    url: '/admin/manage-widget-questions',
    isLink: true
  }, 
  {
    label: 'Super Admin User Roles',
    url: '/admin/user-roles-management',
    isLink: true
  },  
  {
    label: 'Super Admin Upload Template Users',
    url: '/admin/upload-template-users',
    isLink: true
  },
  {
    label: 'Super Admin Manage Widget Managers',
    url: '/admin/manage-widget-managers',
    isLink: true
  },
  {
    label: 'Super Admin Widget Questions Management',
    url: '/admin/manage-widget-questions',
    isLink: true 
  },
   // Admin
  {
  label: 'Admin Dashboard',
  url: '/admin/dashboard',
  isLink: true 
  },
  {
  label: 'Admin Profile',
  url: '/admin/p',
  isLink: true 
  },
  {
  label: 'Admin  Conferences',
  url: '/admin/conferences',
  isLink: true 
  },
  {
  label: 'Admin Create Host',
  url: '/admin/create-host',
  isLink: true 
  },
  {
  label: 'Admin Create Mentor',
  url: '/admin/create-mentor',
  isLink: true 
  },
  {
  label: 'Admin Organizations',
  url: '/admin/organizations',
  isLink: true 
  },
  {
  label: 'Admin Question Templates',
  url: '/admin/question-templates',
  isLink: true 
  },
  {
  label: 'Admin Create Question Templates',
  url: '/admin/question-templates/create',
  isLink: true 
  },
  {
  label: 'Admin Create Conference',
  url: '/admin/create-conference',
  isLink: true 
  },
  {
  label: 'Admin Copied From Question',
  url: '/admin/copied-from-question',
  isLink: true 
  },
  {
  label: 'Admin Create Copied From Question',
  url: '/admin/copied-from-question/create',
  isLink: true 
  },
  // {
  // label: 'Admin Availability',
  // url: '/admin/availability',
  // isLink: true 
  // },
  {
  label: 'Admin Add Use Widget',
  url: '/admin/add-user-widget',
  isLink: true 
  },
  {
  label: 'Admin User Widgets',
  url: '/admin/user-widgets',
  isLink: true 
  },
];
