"use strict";

import alt from '../altInstance';
import { RecordingActions } from '../actions';

class RecordingStore {
  constructor() {
    this._recordings = [];
    this._recording = null;
    this._speakingSlotFrom = 0;

    this.bindListeners({
      fetch: RecordingActions.FETCH,
      create: RecordingActions.CREATE,
      // checkMkvExists : RecordingActions.CHECK_MKV_EXISTS,
      stopRecording: RecordingActions.STOP_RECORDING,
      updateSpeakingSlot: RecordingActions.UPDATE_SPEAKING_SLOT,
      discard: RecordingActions.DISCARD
    });

    this.publicMethods = {
      getRecordingsByConference: function (conference) {
        return find(this.state._recordings, { conference: conference });
      }
    };
  };

  fetch(payload) {
    if(!payload.error) {
      this._recordings = payload.data.recordings;
    }
  };

  create(payload) {
    if(!payload.error) {
      this._recording = payload.data.recording;
      this._recording.speakingSlots = [];
      this.isStart = true;
      this._errorInStartRecording = false;
    }
    else{
        this._errorInStartRecording = true;
    }
  };
  // checkMkvExists(payload) {
  //   if(payload.error) {
  //     this._errorInStartRecording = true;
  //   }
  //   else{
  //     this._errorInStartRecording = false;
  //   }
  // };

  stopRecording(payload) {
    console.log("Called stop recording func in store");
    const { fSpeakingSlotExists } = payload.data;
    this._recording = null;
    this.isStart = false;
    this._fSpeakingSlotExists = fSpeakingSlotExists;
    this._speakingSlotFrom=0;
  };

  updateSpeakingSlot(payload) {
    const self = this;
    this.isStart = true;
    if(!payload.error && self._recording) {
      const speakingSlot = payload.data;
      this._fSpeakingSlotExists = true;
      if(speakingSlot.from && !speakingSlot.to) {
        self._recording.speakingSlots.push(speakingSlot);
        this._speakingSlotFrom = speakingSlot.from;
      }

      if(speakingSlot.from && speakingSlot.to) {
        self._recording.speakingSlots.map(slot => {
          if(slot.from === speakingSlot.from && slot.question === speakingSlot.question) {
            slot = speakingSlot;
          }
          return slot;
        });
        this._speakingSlotFrom = 0;
      }
    }
  };

  discard(payload) {
    if(!payload.error) {
      this._speakingSlotFrom = 0;
    }
  }
}

export default alt.createStore(RecordingStore, 'RecordingStore');
