'use strict';

import Alt from '../altInstance';
import http from 'superagent';

import { ServerError } from '../helpers';
import AlertActions from './AlertActions';
import ConferenceActions from './ConferenceActions';

import { browserHistory } from 'react-router';

class RecordingActions {

  fetch(payload, cb) {
    return(dispatch)=>{
      http
        .get(`/api/recording`)
        .query(payload)
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });

          if(error) {
            if(cb && typeof cb == 'function') cb(error);
            return AlertActions.error(error);
          }
          else{
            if(cb && typeof cb == 'function') cb(null, res.body);
          }
        })
    }
  };

  create(payload,cb){
    return(dispatch)=>{
      http
        .post(`/api/start-recording`)
        .send(payload)
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });
          if(cb && typeof cb == 'function'){
            cb(error);
          }
          if(error) {
            let payload = {
              name : "PAGE_REFRESHED",
              conferenceId : payload.conference,
              message : "Page refreshed of mentor because of error in start recording api",
              data : {timeStamp : new Date()}
            };
            ConferenceActions.addLogs(payload);
            AlertActions.error(error);
              setTimeout(()=>{
                window.location.reload();
              },2000);
          }
          else AlertActions.info({ message: "Recording started!!!" });
        })
    }
  };

  stopRecording(payload, recordingId, conferenceId, cb){
    return(dispatch)=>{
      http
        .put(`/api/stop-recording/${recordingId}`)
        .send(payload)
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          console.log("error=========",error);
          if(error) {
            let payload = {
              name : "PAGE_REFRESHED",
              conferenceId,
              message : "Page refreshed of mentor because of error in stop recording api",
              data : {timeStamp : new Date()}
            };
            ConferenceActions.addLogs(payload);
            setTimeout(()=>{
              window.location.reload();
            }, 1000);
          }
          dispatch({
            error:error,
            data:res.body
          });
          if(cb) cb();
          if(error) {
            console.log("ready to show error msg");
            return AlertActions.error(error);
          }
          else{
            console.log("ready to show notification");
            AlertActions.info({ message: "Recording stopped!!!" });
          }
          // AlertActions.success({ message: "Updated Successfully" })
        })
    }
  };

  updateSpeakingSlot(payload, recordingId,callback){
    return(dispatch)=>{
      http
        .put(`/api/recording/speaking-slot/${recordingId}`)
        .send(payload)
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: payload
          });
          // console.log("Ready to callback from action----",callback);
          if(callback) callback();
          // if(error) return AlertActions.error(error);

          // AlertActions.success({ message: "Updated Successfully" })
        })
    }
  };

  discard(payload){
    return(dispatch)=>{
      http
        .delete('/api/recording/discard')
        .send(payload)
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });

          if(error) return AlertActions.error(error);
          // AlertActions.success({ message: "Deleted Successfully" })
        })
    }
  };
  checkSpeakingSlotExists(conferenceId,callback){
    return(dispatch)=>{
      http
        .get(`/api/recording/check-speaking-slot/${conferenceId}`)
        .query()
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });
          callback(error,res.body);
          if(error) return AlertActions.error(error);
          // AlertActions.success({ message: "Deleted Successfully" })
        })
    }
  };
  
  checkFileSizeIncrease(recordingId,callback){
    return(dispatch)=>{
      http
        .get(`/api/recording/check-file-increase/${recordingId}`)
        .query()
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });
          callback(error,res.body);
          if(error) return AlertActions.error(error);
          // AlertActions.success({ message: "Deleted Successfully" })
        })
    }
  };

  // checkMkvExists(payload) {
  //   return(dispatch)=>{
  //     http
  //       .get(`/api/check-mkv-exists`)
  //       .query(payload)
  //       .end((err, res)=>{
  //         const error = err || res.error ? ServerError(res) : null;
  //         dispatch({
  //           error:error,
  //           data:res.body
  //         });
  //
  //         if(error) return AlertActions.error(error);
  //       })
  //   }
  // };

  takePicturesFromRecordingsRemotely(query, callback) {
    return (dispatch) => {
      http
        .get(`/api/recordings/take-pictures-and-dimensions-from-recordings-remotely`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }

  deleteManuallyUploadRecording(query, callback) {
    return () => {
      http
        .delete(`/api/recording/delete-manually-uploaded-recording`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "Deleted Successfully" })
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }
  getAllRecordings(conferenceId, callback) {
    return () => {
      http
        .get(`/api/get-all-recordings/${conferenceId}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }
};

export default Alt.createActions(RecordingActions);
