'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class PhoneAppActions {
    createUpdatePhoneQuestion(payload, cb) {
        return () => {
          http
            .put(`/api/phoneApp/phone-question`)
            .send(payload)
            .end((err, res) => {
              const error = err || res.error ? ServerError(res) : null;
              if (error) return AlertActions.error(error);
              if (cb) cb();
              AlertActions.success({ message: "Question updated" });
            });
        }
      }
    
      getPhoneQuestion(query, cb) {
        return () => {
          http
            .get(`/api/phoneApp/phone-questions`)
            .query(query)
            .end((err, res) => {
              const error = err || res.error ? ServerError(res) : null;
              if (error) return AlertActions.error(error);
              if (cb) cb(res.body);
            });
        }
      }
    
      deletePhoneQuestion(questionId, cb) {
        return () => {
          http
            .delete(`/api/phoneApp/phone-question/${questionId}`)
            .end((err, res) => {
              const error = err || res.error ? ServerError(res) : null;
              if (error) return AlertActions.error(error);
              if (cb) cb();
              AlertActions.success({ message: "Question deleted successfully" });
            });
        }
      }
}

export default alt.createActions(PhoneAppActions);