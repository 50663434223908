export default (user) => {
    let latestEdu = user && Array.isArray(user.schools) && user.schools.find(s => s && s.attended && s.attended.isCurrent)
                                || user && Array.isArray(user.schools) && user.schools[0] || "";
    let latestJob = user && Array.isArray(user.jobs) && user.jobs.find(j => j && j.timePeriod && j.timePeriod.isCurrent )
                                || user && Array.isArray(user.jobs) && user.jobs[0] || "";
    let eduBrief = '';
    let jobBrief = '';
    let eduOrg = '';
    let jobOrg = '';

    let eduLabel = '';
    let jobLabel = '';
    let headLines = '';
    if (latestJob && latestJob.position) {
        jobLabel = latestJob.position;
        jobBrief = latestJob.position;
        if (latestJob && latestJob.org && latestJob.org.name) {
            jobLabel += ' at ' + latestJob.org.name;
            jobOrg = latestJob.org.name;
        }
        else if (latestJob && latestJob.orgWithoutUrl) {
            jobLabel += ' at ' + latestJob.orgWithoutUrl;
            jobOrg = latestJob.orgWithoutUrl;
        }
    }
    if (latestEdu) {
        if (latestEdu.attended && latestEdu.attended.end) {
            eduLabel += (new Date(latestEdu.attended.end)).getFullYear();
        }
        if (latestEdu.degree) {
            if (eduLabel.length > 0) eduLabel += ', ' + latestEdu.degree;
            else eduLabel += latestEdu.degree;
        }
        if (latestEdu.major) {
            headLines = latestEdu.major;
        }
        if (latestEdu.org && latestEdu.org.name) {
            if (eduLabel.length > 0) eduLabel += ', ' + latestEdu.org.name;
            else eduLabel += latestEdu.org.name;

            if (headLines.length > 0) headLines += ', ' + latestEdu.org.name;
            else headLines += latestEdu.org.name;
        }
    }
    if (latestEdu) {
        // if (latestEdu.attended && latestEdu.attended.end) {
        //     eduLabel += (new Date(latestEdu.attended.end)).getFullYear();
        // }
        
        if (latestEdu.major) {
            eduBrief = latestEdu.major;
        }
        if (latestEdu.degree) {
            if (eduBrief.length > 0) eduBrief += ', ' + latestEdu.degree;
            else eduBrief += latestEdu.degree;
        }
        if (latestEdu.org && latestEdu.org.name) {
            eduOrg = latestEdu.org.name;
        }
        else if (latestEdu.orgWithoutUrl) {
            eduOrg = latestEdu.orgWithoutUrl
        }
    }
    return {
        
        eduLabel: eduLabel,
        jobLabel: jobLabel,
        headLines: headLines,
        fullName : `${user.firstName} ${user.lastName}`,
        profilePicUrl : user.profilePicUrl || 'https://mentorstudents.org/images/default-user.png',
        userName : user.userName,
        _id:user._id,
        eduBrief: eduBrief,
        jobOrg : jobOrg,
        eduOrg:eduOrg,
        jobBrief:jobBrief
    }
}
