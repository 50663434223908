"use strict";

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import {ServerError} from '../helpers';
import {AuthInterceptor} from '../utils';

// import SessionStore from '../stores/SessionStore'
// import {Roles} from '../constants/index';

// import {browserHistory} from 'react-router';


class ConferenceInviteActions {
  // reportingMessage(payload, cb) {
  //   return (dispatch) => {
  //     http
  //       .post('/api/conference-invites/add-response')
  //       .send(payload)
  //       .end((err, res) => {
  //         var error = err || res.error ? ServerError(res) : null;
  //         dispatch({
  //           data: res.body,
  //           error: error
  //         });
  //         if (typeof cb === 'function') cb(error);
  //         if (error) return AlertActions.error(error);
  //         AlertActions.success({message: 'Response recorded!'});
  //       })
  //
  //   }
  // }

  // updateEmail(payload, cb) {
  //   return (dispatch) => {
  //     http
  //       .put('/api/conference-invites/update')
  //       .send(payload)
  //       .end((err, res) => {
  //         var error = err || res.error ? ServerError(res) : null;
  //         dispatch({
  //           error: error,
  //           data: res.body
  //         });
  //         if (typeof cb === 'function') cb(error);
  //         if (error) return AlertActions.error(error);
  //         AlertActions.success({message: 'email added successfully'});
  //       });
  //   }
  // };

  //ConferenceInviteActions.getAllVersionsForACommType({commType})
  getAllVersionsForACommType(payload, cb){
    return () => {
      http
       .get('/api/conference-invites/get-all-versions-for-a-comm-type')
       .query(payload)
       .use(AuthInterceptor)
       .end((err, res) => {
         var error = err || res.error ? ServerError(res) : null;
         if (error) return AlertActions.error(error);
         if(typeof cb === 'function' && cb) cb(res.body);
       })
    }
  }

  deleteEmailFormat(payload, cb){
    return () => {
      http
        .delete('/api/conference-invites/delete-email-format')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if(typeof cb === 'function' && cb) cb(res.body);
        })
    }
  }

  getConfPastPendingCommunication(payload, cb) {
    return () => {
      http
        .get(`/api/conference-invites/get-conf-past-pending-communication`)
        .query(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if(typeof cb === 'function' && cb) {
            if (error) {
              return cb(error);
            }
            cb(null, res.body);
          }
        })
    }
  }

  getMergedCommunication4Pending(payload, cb) {
    return () => {
      http
        .get(`/api/conference-invites/get-merged-communication-4-pending`)
        .query(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if(typeof cb === 'function' && cb) {
            if (error) return cb(error);
            cb(null, res.body);
          }
        })
    }
  }

  getParentTemplateList() {
    return (dispatch) => {
      http
        .get(`/api/conference-invites/parent-template-list`)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        })
    }
  }

  getVersionList(cb) {
    return (dispatch) => {
      http
        .get(`/api/conference-invites/version-list`)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if(typeof cb === 'function' && cb) {
            if (error) return cb(error);
            let {versionList = []} = res.body || {};
            versionList = versionList.map(v => {
              v.label = v.name;
              v.value = v._id;
              return v;
            });
            cb(null, {versionList});
          }
        })
    }
  }

  getEventList(cb) {
    return () => {
      http
        .get('/api/conference-invites/event-list')
        .query()
        .use(AuthInterceptor)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if(typeof cb === 'function' && cb) {
            if (error) return cb(error);
            const eventList = res.body.events.map(e => ({ label: e, value: e }));
            cb(null, {eventList});
          }
        })
    }
  };

  getHostAvailabilityForMentor(payload, cb){
    return dispatch=>{
      http
        .post(`/api/conference-invites/get-host-availability-for-mentor`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body || {});
          }
        });
    }
  }

  updateLevel(payload, conferenceId, cb) {
    return (dispatch) => {
      http
        .put(`/api/conference/update-level/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);

          if (typeof cb === 'function') cb(error);
        });
    }
  };
  getHostAvailabilityForConfInvitation(payload, cb){
    return dispatch=>{
      http
        .post(`/api/conference-invites/get-host-availability-for-conf-invite`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body || {});
          }
        });
    }
  }
  saveConferenceInviteResponse(payload, cb){
    return dispatch=>{
      http
        .post(`/api/conference-invites/conference-invite-response`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (cb && typeof cb == 'function') {
            cb(error, res);
          }
        });
    }
  }

  sendMessage(payload, cb){
    return (dispatch) => {
      http
        .post(`/api/conference-invites/send-message`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  }

  getEmailFormats(payload) {
    return (dispatch) => {
      http
        .get(`/api/conference-invites/get-email-formats`)
        .query(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        })
    }
  };

  createVersion(newVersion, cb) {
    return (dispatch) => {
      http
        .post(`/api/conference-invites/create-version`)
        .send({newVersion})
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  };

  updateEmailFormat(payload, cb) {
    return (dispatch) => {
      http
        .post(`/api/conference-invites/update-email-format`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          cb();
          if (error) return AlertActions.error(error);
          AlertActions.success({message: 'Updated successfully'});
        });
    }
  };

  resetEmailFormatComponent() {return dispatch => dispatch() }

  // rescheduleConference(conferenceId, payload, cb) {
  //   return (dispatch) => {
  //     http
  //       .put(`/api/conference-invites/reschedule-conference/${conferenceId}`)
  //       .send(payload)
  //       .use(AuthInterceptor)
  //       .end((err, res) => {
  //         const error = err || res.error ? ServerError(res) : null;
  //         dispatch({
  //           error: error,
  //           data: res.body
  //         });
  //
  //         if (error) AlertActions.error(error);
  //
  //         if (typeof cb === 'function') cb(error);
  //       });
  //   }
  // };

  mentorAdminEvents(confCode, payload, cb){
    return dispatch=>{
      http
        .post(`/api/conference-invite/mentor-admin-event/${confCode}`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);
          else AlertActions.success({message: 'Updated successfully'});
          if (typeof cb === 'function') cb(error);
        });
    }
  }
  updateSuggestedConfTimes(payload, confCode,cb){
    return dispatch=>{
      http
      .put(`/api/conference-invite/suggested-conf-times/${confCode}`)
      .send(payload)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        dispatch({
          error: error,
          data: res.body
        });

        if (error) AlertActions.error(error);
        else AlertActions.success({message: 'Updated successfully'});
        if (typeof cb === 'function') cb();
      });
    }
  }

  changeConferenceType(payload) {
    return (dispatch) => {
      http
        .put(`/api/conference-invites/changeConferenceType`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          if (error) return AlertActions.error(error);
          window.location.reload();
        });
    }
  }
  getPendingConfSendMsgList(query, cb){
    return () => {
      http
        .get('/api/conference-invites/pending-conf-send-msg')
        .query(query)
        .use(AuthInterceptor)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if(typeof cb === 'function') cb(error, res.body);
        })
    }    
  }
  deletePendingConfSendMsg(id, cb){
    return () => {
      http
        .delete(`/api/conference-invites/delete-pending-conf-send-msg/${id}`)
        .use(AuthInterceptor)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if(typeof cb === 'function' && cb) cb(err, res.body);
        })
    }
  }
  unsetMessageReceivedFlag(userId, cb){
    return () => {
      http
        .put(`/api/conference-invites/unset-message-received-flag/${userId}`)
        .use(AuthInterceptor)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else {
            AlertActions.success({message: "Updated successfully!"});
          }
          if(typeof cb === 'function' && cb) cb(err, res.body);
        })
    }
  }

  getConferenceInviteStatus(payload) {
    return (dispatch) => {
      http
        .get(`/api/conference-invites/get-conference-invites-status`)
        .query()
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          // console.log("res===========",res.body);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        })
    }
  };

  updateMentorTimezone (payload, cb) {
    return () => {
      http
        .put('/api/conference-invites/update-mentor-timezone')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof cb === 'function') cb(null, res);
        });
    }
  }

  updateMentorEmail (payload, cb) {
    return () => {
      http
        .put('/api/conference-invites/update-mentor-email')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof cb === 'function') cb();
        });
    }
  }
  getSalutations(cb){
    return (dispatch) => {
      http
        .get(`/api/get-salutations`)
        .query()
        .use(AuthInterceptor)
        .end((err, res) => {

          const error = err || res.error ? ServerError(res) : null;
          // cosnsole.log("res===========",res.body);
          if (typeof cb === 'function') cb(res.body);
          if (error) return AlertActions.error(error);
        })
    }
  }
  insertUpdateCommFormatTemplateVars(payload, cb){
    return(dispatch)=>{
      http
      .put('/api/conference-invites/insert-update-comm-format-template-var')
      .send(payload)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error(error);
        dispatch({
          error: error,
          data: res.body
        });

        if (typeof cb === 'function') cb(error);
      });

    }
  }
  deleteCommFormatTemplateVars(payload, cb){
    return(dispatch)=>{
      http
      .delete('/api/conference-invites/delete-comm-format-template-var')
      .send(payload)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        dispatch({
          error: error,
          data: res.body
        });
        if (error) return AlertActions.error(error);
        if (typeof cb === 'function') cb(error);
      });

    }
  }
  getAllValueForTemplateVar(payload, cb){
    return () => {
      http
       .get('/api/conference-invites/get-comm-format-template-var-values')
       .query(payload)
       .use(AuthInterceptor)
       .end((err, res) => {
         var error = err || res.error ? ServerError(res) : null;
         if (error) return AlertActions.error(error);
         if(typeof cb === 'function' && cb) cb(error, res.body);
       })
    }
  }
  
  updatePendingConfSendMesgRecord (payload, cb){
    return(dispatch)=>{
      http
      .put('/api/conference-invites/update-pend-conf-send-msg')
      .send(payload)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        AlertActions.success({ message: "Successfully updated!!" })
        if (typeof cb === 'function') cb(error);
      });

    }
  }

  fetchQuestionLinkReport (query, callback) {
    return () => {
      http
      .get('/api/conference-invite/question-link-tracking')
      .query(query)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        if (typeof callback === 'function') callback(error, res.body);
      });
    }
  }

  fetchMoreDetails (query, callback) {
    return () => {
      http
      .get('/api/conference-invite/question-link-tracking-details')
      .query(query)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        if (typeof callback === 'function') callback(error, res.body);
      });
    }
  }

  fetchQLTrackingAnalytics (query, callback) {
    return () => {
      http
      .get('/api/conference-invite/question-link-tracking-analytics')
      .query(query)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        if (typeof callback === 'function') callback(error, res.body);
      });
    }
  }

  getCountsOfInviteSent(query, callback) {
    return () => {
      http
      .get('/api/conference-invite/count-day-wise')
      .query(query)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        if (typeof callback === 'function') callback(error, res.body);
      });
    }
  }

  getConferencesForADate(query, callback) {
    return () => {
      http
      .get('/api/conference-invite/invites-sent-to-mentors-on-a-day')
      .query(query)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        if (typeof callback === 'function') callback(error, res.body);
      });
    }
  }

  fetchInvitesSentAnalytics (callback) {
    return () => {
      http
      .get('/api/conference-invite/invites-sent-analytics')
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        if (typeof callback === 'function') callback(error, res.body);
      });
    }
  }

  makeEmailPrimary (payload, cb){
    return(dispatch)=>{
      http
      .put('/api/conference-invites/make-email-primary')
      .send(payload)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        AlertActions.success({ message: "Successfully updated!!" })
        if (typeof cb === 'function') cb(error, res.body);
      });
    }
  }

  deleteEmail (payload, cb){
    return(dispatch)=>{
      http
      .put('/api/conference-invites/delete-email')
      .send(payload)
      .end((err, res) => {
        const error = err || res.error ? ServerError(res) : null;
        if (error) return AlertActions.error({ message: "An internal error has occurred" });
        AlertActions.success({ message: "Successfully updated!!" })
        if (typeof cb === 'function') cb(error, res.body);
      });
    }
  }
}

export default alt.createActions(ConferenceInviteActions);

// deleteLiPendingConnection(id,cb){
//   return dispatch=>{
//     http
//     .delete(`/api/conference-invites/li-pending-connection`)
//     .send({id})
//     .end((err, res) => {
//       const error = err || res.error ? ServerError(res) : null;
//       dispatch({
//         error: error,
//         data: res.body
//       });
//
//       if (error) AlertActions.error(error);
//       else AlertActions.success({message: 'Deleted successfully'});
//       if (typeof cb === 'function') cb();
//     });
//   }
// }

// getMergedLiMessage(payload) {
//   return (dispatch) => {
//     http
//       .get(`/api/conference-invites/merged-li-message`)
//       .query(payload)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (error) return AlertActions.error(error);
//       })
//   }
// }

// sendLiRequestWithMessage(payload, cb){
//   return (dispatch) => {
//     http
//       .post(`/api/conference-invites/send-li-request-with-message`)
//       .send(payload)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (error) return AlertActions.error(error);
//         else AlertActions.success({message : 'Request sent successfully!'});
//         if (cb && typeof cb == 'function') {
//           cb(error, res.body);
//         }
//       })
//   }
// }

// getLiFormat(payload) {
//   return (dispatch) => {
//     http
//       .get(`/api/conference-invites/li-formats`)
//       .query(payload)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (error) return AlertActions.error(error);
//       })
//   }
// };

// updateLiFormat(payload) {
//   return (dispatch) => {
//     http
//       .post(`/api/conference-invites/update-li-format`)
//       .send(payload)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (error) return AlertActions.error(error);
//         AlertActions.success({message: 'Updated successfully'});
//       });
//   }
// };
