
import React from "react";
// import moment from "moment-timezone";


const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']

export default class UploadedFileList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fileList: [1, 2, 3, 4, 5, 6],
        }
    }

    getFinalDateFormat = (arg) => {
        let dateFormat = new Date(arg);
        let dayIndex = dateFormat.getDay();
        let date = dateFormat.getDate();
        let monthIndex = dateFormat.getMonth() + 1;
        let fullYear = dateFormat.getFullYear();
        let finalDateFormat = `${DAYS[dayIndex]}, ${date} ${MONTHS[monthIndex]}, ${fullYear}`;
        return finalDateFormat;
    }

    render() {
        // let { fileList } = this.state
        let { onEditFile, fileList, onDeleteFile } = this.props
        return (
            <table className="table table-bordered">
                <tbody>
                    <tr>
                        <th>File Name</th>
                        <th>Question Type</th>
                        <th>Question For</th>
                        <th>Uploaded At</th>
                        <th>Actions</th>
                    </tr>
                    {fileList.map(item => {
                        return (<tr key={item._id}>
                            <td>{item.name}</td>
                            <td>{item.questionType}</td>
                            <td>{item.questionFor}</td>
                            <td>{this.getFinalDateFormat(item.uploadedAt)}</td>
                            <td>
                                <button className="btn btn-sm btn-info" onClick={() => onEditFile(item._id, item.questionFor, item.questionType)}>
                                    Edit
                                </button>
                                <button className="btn btn-sm btn-danger ml10" onClick={() => onDeleteFile(item._id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>)
                    })}
                </tbody>
            </table>
        )
    }
}


