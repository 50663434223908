'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';
import {browserHistory} from 'react-router';
class QuestionLinkActions {
  getQuestionLinkInfo(confCode, callback) {
    return (dispatch) => {
      http
        .get(`/api/question-link-info/${confCode}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          const { found } = res.body;

          if (found) {
            const {
              conference: { isCompleted, s3VideoUrl, mentor: { userName, timezone: mentorTimezone } = {} } = {} 
            } = res.body;

            if(isCompleted && s3VideoUrl && userName) {
              AlertActions.success({ message: "Conference is already completed" });
              browserHistory.push(`/q/${userName}`);
            }
            else {
              if (typeof callback === 'function') callback(error, res);

              dispatch({ error: error, data: res.body });
            }
          }

          if (error) return AlertActions.error(error);
        });
    }
  }

  trackPage(payload) {
    return (dispatch) => {
      http
        .post('/api/trackPage')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          // if (error) return AlertActions.error(error);
          if (error) console.error("/api/trackPage", error);
        })
    };
  }
  // saveUserQuery(confCode, payload){
  //   return (dispatch) => {
  //     http
  //       .post(`/api/conference-invites/record-mentor-query/${confCode}`)
  //       .send(payload)
  //       .end((err, res) => {
  //         var error = err || res.error ? ServerError(res) : null;

  //         dispatch({
  //           error: error,
  //           data: res.body
  //         });

  //         if (error) return AlertActions.error(error);
  //         AlertActions.success({ message: "Response Recorded" });
  //       })      
  //   }    
  // } 

  _updateMentorEmailAddress(payload, cb) {
    console.log("enter inaction=========",payload);
    return (dispatch) => {
      http
        .put('/api/conference-invites/verify-email')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          // if (error) return AlertActions.error(error);
          if (typeof cb === 'function') return cb(error);
          AlertActions.success({
            message: "Email Updated Successfully"
          });
          callback(payload.email);
      })
    };
  }

  getConferenceIdFromConfCode(confCode, cb) {
    return (dispatch) => {
      http
        .get('/api/conference/get-conference-id')
        .query({ confCode })
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (typeof cb === 'function') return cb(error, res);

          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
      })
    }
  }

  // acceptTermsAndCondition (payload, callback) {
  //   return (dispatch) => {
  //     http
  //       .put('/api/accounts/updateUser')
  //       .send(payload)
  //       .end((err, res) => {
  //         const error = err || res.error ? ServerError(res) : null;

  //         dispatch({ error: error, data: res.body });

  //         if(error) return AlertActions.error(error);
  //         else if(typeof callback == 'function') callback();

  //       });
  //   }
  // }

  togglefBlockFlag (payload, callback) {
    return (dispatch) => {
      http
        .put('/api/question-link/toggle-fBlock')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({ error: error, data: res.body });

          if(error) return AlertActions.error(error);
          else if(typeof callback == 'function') callback();

        });
    }
  }

  updateMentorDuration (payload) {
    return () => {
      http
        .put('/api/track-page/question-link-mentor-duration')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          // if(error) return AlertActions.error(error);
          if (error) console.error("/api/track-page/question-link-mentor-duration", error);
        });
    }
  }

  updateTimeSlot (payload) {
    return () => {
      http
        .put('/api/track-page/question-link-update-timeslot')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          // if(error) return AlertActions.error(error);
          if (error) console.error("/api/track-page/question-link-update-timeslot", error);
        });
    }
  }

}


export default alt.createActions(QuestionLinkActions);
