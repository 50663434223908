"use strict";

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

exports.monthNames = monthNames;

exports.shortMonthNames = shortMonthNames;

exports.getMonth = date => {
  return monthNames[new Date(date).getMonth()];
};

exports.getMonthShort = date => {
  return shortMonthNames[new Date(date).getMonth()];
};

exports.calcTimeDiff = (start, end) => {
  start = new Date(start);
  end = new Date(end);
  let monthAbb = "month";
  let yearAbb = "year";
  if (!start instanceof Date || isNaN(start.valueOf()) || !end instanceof Date || isNaN(end.valueOf())) {
    return null;
  }

  const MilliSecsPerDay = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  const diff = Math.floor(Math.abs(utc2 - utc1) / MilliSecsPerDay);

  const Years = Math.floor(diff / 365);
  const Months = Math.floor((diff % 365) / 30 + 1);
  if(Months > 1) monthAbb = "Months";
  if(Years > 1) yearAbb = "Years";
  return Years ? `${Years} ${yearAbb} and ${Months} ${monthAbb}` : `${Months} ${monthAbb}`;
};
