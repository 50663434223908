const _filterJobsToShow = ({ flagFullOptions = 'quick', mentor }) => {
  try {
    // if, flagFullOptions is true, its a full conference, else its a quick conference
    if (!mentor) return null;
    // Check if we have jobs
    const { jobs } = mentor;
    if (!jobs || jobs.length == 0) return null;
    const currentYear = new Date().getFullYear();
    let jobsIncluded = 0;
    const result = [];
    // Loop over jobs and check if we should include this jobs
    for (let j = 0; j < jobs.length; j++) {

      const { timePeriod, org, orgWithoutUrl, questType } = jobs[j];
      // const name = (org && org.name) || orgWithoutUrl;
      // If questType is not Job, don't include it
      if (questType && ["school", "general"].indexOf(questType) > -1) continue;

      if (flagFullOptions === 'quick') {
        if (jobsIncluded >= 2) break; //dont ask for more than 2 jobs
        if (!org || !org.url || !org.url.length) continue; //dont ask for this old job if org is without url

        const startingYear = timePeriod.start ? new Date(timePeriod.start).getFullYear() : currentYear;
        if (currentYear - startingYear > 10) continue; //dont ask if the job is older than 10 years

        if (j > 0 && org.name && jobs[j - 1].org && jobs[j - 1].org.name && org.name === jobs[j - 1].org.name) { //current job is in the same company as the job after this (j-1)
          if (j < jobs.length - 1) { //if there is a job older than this current job
            const olderJob = jobs[j + 1];
            const startingYear = olderJob.timePeriod.start
              ? new Date(olderJob.timePeriod.start).getFullYear()
              : currentYear;
            if (currentYear - startingYear <= 10 && olderJob.url && olderJob.url.length) {
              continue; //older job will be added so skip this current job
            }
          }
        }
      }
      jobsIncluded++;
      result.push(jobs[j]);
    }
    return result;
  }
  catch (e) {
    throw e;
  }
}

const buildQuestionsFromTemplate = ({ template, mentor }) => {
  try {
    if (!template) throw new Error('Template not found!!!');
    if (!mentor) throw new Error('Mentor not found!!!');
    // console.log(mentor);
    // console.log("-----------------------------------------")
    // console.log(template);
    const flagFullOptions = 'quick';

    const finalQuestions = [];

    let { jobs, schools } = mentor;
    jobs = _filterJobsToShow({ flagFullOptions, mentor });

    const { questVideos } = template;

    const currentYear = new Date().getFullYear();

    for (let qIdx = 0; qIdx < questVideos.length; qIdx++) {
      //Assumption: Job in user's profile has questType to distinguish between types of job-activity/profession
      let questVideo = questVideos[qIdx];
      let { type: questVideoType } = questVideo;

      if (questVideoType === 'school') {
        //<editor-fold desc=">> Get all following questions with type school till the questType changes & Populate the block with different schools">
        if (schools && schools.length) {
          //<editor-fold desc=">> Get all following questions with type school in schoolBlockQuestions till the questType changes">
          let schoolBlockQuestions = [];
          let qIdxBlock = qIdx;
          while (qIdxBlock < questVideos.length) {
            const qv = questVideos[qIdxBlock];
            const qvType = qv.type;
            if (qvType === questVideoType) {
              schoolBlockQuestions.push(qv);
              qIdx++;
              qIdxBlock++;
            } else {
              qIdx--;
              break;
            }
          }
          //</editor-fold>

          //<editor-fold desc=">> Populate schoolBlockQuestions with different schools">
          let schoolsIncluded = 0;
          for (let j = 0; j < schools.length; j++) {
            const { _id: schoolId, degree, major, attended, org, orgWithoutUrl } = schools[j];
            const name = (org && org.name) || orgWithoutUrl;

            //<editor-fold desc=">> Set variable, isCurrent, to decide whether to pick current or past version of question">
            let isCurrent = !!attended.isCurrent
            if (attended.end && new Date(attended.end).getTime() >= new Date().getTime()) {
              isCurrent = true;
            }
            //</editor-fold>

            //<editor-fold desc=">> Loop over schoolBlockQuestions and push question to finalQuestions in context of the given school">
            schoolBlockQuestions.forEach(sq => {
              if (j === 0 || (j > 0 && (flagFullOptions === 'full' || !sq.dontShowInOld))) {

                //<editor-fold desc=">> Replace variables in question with school info">
                const question = JSON.parse(JSON.stringify(sq.question));
                let title = "";
                if (question.copiedFromProps.hasOwnProperty('titleCurrent') && isCurrent) {
                  title = question.copiedFromProps.titleCurrent.all;
                }
                if (question.copiedFromProps.hasOwnProperty('titlePast') && !isCurrent) {
                  title = question.copiedFromProps.titlePast.all;
                }
                if (title.indexOf('{name}') > -1) {
                  title = title.replace('{name}', name);
                }
                if (title.indexOf('{degree}') > -1) {
                  title = title.replace('{degree}', degree);
                }
                if (title.indexOf('{major}') > -1) {
                  title = title.replace('{major}', major);
                }
                //</editor-fold>

                finalQuestions.push({
                  title, copiedFrom: question._id, tags: question.tags, type: "school",
                  schoolInUser: schoolId
                });
              }
            });

            schoolsIncluded++;
            //</editor-fold>

            //<editor-fold desc=">> Stop looping over schools, move to the next question in template under conditions">
            if (flagFullOptions === 'quick' && schoolsIncluded >= 3) break;
            //</editor-fold>
          }
          //</editor-fold>
        }
        //</editor-fold>
      } else if (questVideoType === 'general') {
        //<editor-fold desc=">> Get all following questions with type general till the questType changes">
        const { question } = questVideo;
        // console.log({question});
        finalQuestions.push({
          title: question.title, copiedFrom: question._id, tags: question.tags, type: "general"
        });
        //</editor-fold>
      } else {
        //<editor-fold desc=">> Get all following questions with the type, questVideoType (job/otherTypes), till the questType changes & Populate the block">
        if (jobs && jobs.length) {
          //<editor-fold desc=">> Get all following questions with the given questVideoType till the questType changes">
          let jobBlockQuestions = [];
          let qIdxBlock = qIdx;
          while (qIdxBlock < questVideos.length) {
            const qv = questVideos[qIdxBlock];
            const qvType = qv.type;
            if (qvType === questVideoType) {
              jobBlockQuestions.push(qv);
              qIdx++;
              qIdxBlock++;
            } else {
              qIdx--;
              break;
            }
          }
          //</editor-fold>

          //<editor-fold desc=">> Populate jobBlockQuestions with different jobs of same questVideoType">
          let jobsIncluded = 0;
          for (let j = 0; j < jobs.length; j++) {
            const { _id: jobId, position, timePeriod, org, orgWithoutUrl, questType } = jobs[j];
            const name = (org && org.name) || orgWithoutUrl;

            //<editor-fold desc=">> Skip work position under conditions"
            //jobs can be different eg startup, nonprofit, so we need to match questVideoType
            /*NOTE: DO NOT REMOVE IT
            Detailed logic for skipping a given job :
            if(qType == "job") {
              if(questType){
                if(questType != "job"){
                  continue;
                }else{
                  //let rest of the code work for this job
                }
              }else {
                //let rest of the code work for this job
              }
            } else {//qType could be openSourceFounder, Investor etc,
              if(questType){
                if(questType != qType) {
                  continue
                }else {
                  //let rest of the code work for this job
                }
              }else {
                continue;
              }
            }
            */
            if (questType && questType !== questVideoType) continue;
            else if (!questType && questVideoType !== "job") continue;

            //dont ask for this old job if org is without url
            //if (j >0 && (!org || !org.url || !org.url.length)) continue;

            //<editor-fold desc=">> Skip if it is quick create and the job is older than 10 years"
            const startingYear = timePeriod.start ? new Date(timePeriod.start).getFullYear() : currentYear;
            if (flagFullOptions === 'quick' && currentYear - startingYear > 10) continue;
            //</editor-fold>

            //<editor-fold desc=">> Skip if it is quick create and the job is in same company as current one">
            if (flagFullOptions === 'quick' && j > 0 && (
              (org && jobs[j - 1].org && org.name && jobs[j - 1].org.name && org.name === jobs[j - 1].org.name)
              || (orgWithoutUrl && jobs[j - 1].orgWithoutUrl && orgWithoutUrl === jobs[j - 1].orgWithoutUrl)
            )) { //current job is in the same company as the job after this (j-1)
              if (j < jobs.length - 1) { //if there is a job older than this current job
                const olderJob = jobs[j + 1];
                const startingYear = olderJob.timePeriod.start
                  ? new Date(olderJob.timePeriod.start).getFullYear()
                  : currentYear;
                if (currentYear - startingYear <= 10 && olderJob.url && olderJob.url.length) {
                  continue; //older job will be added so skip this current job
                }
              }
            }
            //</editor-fold>
            //</editor-fold>

            //<editor-fold desc=">> Set variable, isCurrent, to decide whether to pick current or past version of question">
            let isCurrent = !!timePeriod.isCurrent;
            if (timePeriod.end && new Date(timePeriod.end).getTime() >= new Date().getTime()) {
              isCurrent = true;
            }
            //</editor-fold>

            //<editor-fold desc=">> Loop over jobBlockQuestions and push question to finalQuestions in context of the given work position">
            jobBlockQuestions.forEach(jq => {
              const { dontShowInOld, showInOldOnly } = jq;
              if ((j === 0 && !showInOldOnly) || (j > 0 && (!dontShowInOld || flagFullOptions === 'full' || showInOldOnly))) {
                //<editor-fold desc=">> Replace variables in question with job info">
                const question = JSON.parse(JSON.stringify(jq.question));
                let title = "";
                if (question.copiedFromProps.hasOwnProperty('titleCurrent') && isCurrent) {
                  title = question.copiedFromProps.titleCurrent.all;
                }
                if (question.copiedFromProps.hasOwnProperty('titlePast') && !isCurrent) {
                  title = question.copiedFromProps.titlePast.all;
                }
                if (question.title.indexOf('{name}') > -1) {
                  title = question.title.replace('{name}', name);
                }
                if (question.title.indexOf('{position}') > -1) {
                  title = question.title.replace('{position}', position);
                }
                //</editor-fold>

                finalQuestions.push({
                  title, copiedFrom: question._id, tags: question.tags, type: questVideoType,
                  jobInUser: jobId
                });
              }
            });

            //if it reaches here then questions got added for this job
            jobsIncluded++;
            if (flagFullOptions === 'quick' && jobsIncluded >= 2) break;
          }
        }
        //</editor-fold>
      }
    }
    return finalQuestions;
  }
  catch (e) {
    console.log("_buildQuestionsFrom", e);
    throw e;
  }
}

export default buildQuestionsFromTemplate;