"use strict";

import alt from '../altInstance';

import { CronActions } from '../actions';

class CronStore {

  constructor() {
    // this._templates = [];
    // this._hostPicCdn='';
    // this._hostVideoCdn='';
    // this._isBusy = false;
    // this._newTemplate = null;

    this.bindListeners({
      // getStatus: CronActions.getStatus,
      // start: CronActions.START,
      // stop: CronActions.STOP,
    });

    // this.publicMethods = {
    //   getTemplate: (templateId) => {
    //     return this._templates.find(t => t._id === templateId);
    //   },
    //   getNewTemplate: () => this._newTemplate || null
    // }
  };

  // getStatus(payload) {
  //   // console.log("payload===========",payload);
  //   if(!payload.error) {
  //     this.cronInfo = payload.data;
  //   }
  // };

  // start(payload) {
  //   if(!payload.error) {
      
  //   }
  // };

  // stop(payload) {
  //   if(!payload.error) {

  //   }
  // };
}

export default alt.createStore(CronStore, 'CronStore');
