export default (url,size='140x100')=>{
        if(!url) return null;

        // The url of the logo is created here --- options are:
        // large - 320x320 px
        // medium - 256x256 px
        // small - 128x128 px
        // change the substring "-medium" to "-small" or "-large" to change size
        const idx = url.lastIndexOf('.');
        if(url.indexOf('mentors-')>-1){
            return url.substr(0, idx) + `-${size}.` + url.substr(idx+1);
            // const [, name] = url.split("mentors-profile-pic/");
            // const [nameWithoutExtenstion, extension] = name.split(".");
            // return `/images/${nameWithoutExtenstion}large2X1.jpg`;
        }else{
          return url;
        }
    }
