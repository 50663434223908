"use strict";

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';
import SessionStore from '../stores/SessionStore'
import { Roles } from '../constants/index';
import { browserHistory } from 'react-router';
class TemplateActions {
  getTemplates() {
    return dispatch => {
      http
        .get(`/api/template`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(error) return AlertActions.error(error);
        })
    }
  };  
  getTemplateById(payload, cb) {
    return dispatch => {
      http
        .get(`/api/template/${payload.templateId}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(error) return AlertActions.error(error);
          if (typeof cb === 'function') cb(res.body);
        })
    }
  };

  create(payload, cb){
    return(dispatch) => {
      http
        .post('/api/template')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(typeof cb === 'function') cb(error);
          if(error) return AlertActions.error(error);
          AlertActions.success({ message: 'Question template name created successfully' });
        });
    }
  };

  update(payload, templateId, cb) {
    return(dispatch) => {
      http
        .put(`/api/template/${templateId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(typeof cb === 'function') cb(error);
          if(error) return AlertActions.error(error);
          AlertActions.success({ message: 'Updated successfully' });
        });
    };
  };
  updateStatus(payload, templateId, cb) {
    return(dispatch) => {
      http
        .put(`/api/template/status/${templateId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(typeof cb === 'function') cb(error);
          if(error) return AlertActions.error(error);
          AlertActions.success({ message: 'Updated successfully' });
        });
    };
  };

  search(payload) {
    return dispatch => {
      http
        .get('/api/template/search')
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          console.log("res.body=================",res.body);
          dispatch({
            error: error,
            data: res.body
          });

          if(error) return AlertActions.error(error);
        })
    }
  };

  getActiveTemplates(data, cb) {
    const {fDontUpdateStore, keyword} = data;
    const payload = {keyword};
    return dispatch => {
      http
        .get('/api/template/get-only-active')
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if(!fDontUpdateStore){
            dispatch({
              error: error,
              data: res.body
            });
          }
          if(error) return AlertActions.error(error);
          if (typeof cb === 'function') cb(res.body);
        })
    }
  };

  uploadHostVideoPic(payload,cb) {
    return dispatch => {
      http
        .post('/api/template/host-video-pic')
        .attach('image', payload.image, (payload.image && payload.image.name))
        .attach('video', payload.video, (payload.video && payload.video.name))
        .field('templateId', payload.templateId)
        .on('progress', progress => {
          dispatch({
            err: null,
            progress: Math.round(progress.percent)
          });
        })
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            err: error,
            data: res.body
          });

          if(error){
             AlertActions.error({ message: error.message });
          }
          else{
            cb();
          }
        })
    }
  };

  updateHostVideoPic(payload, templateId, cb) {
    return dispatch => {
      http
        .put(`/api/template/host-video-pic/${templateId}`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if(error) AlertActions.error(error);
          cb(error);
        })
    }
  };


  uploadOutlookCredential(payload){
    return dispatch =>{
      http
      .post(`/api/template/upload-outlook-credential/`)
      .send(payload)
      .end((err,res)=>{
        var error = err || res.error ? ServerError(res) : null;
        dispatch({
          error: error,
          data: res.body
        });
        if(error) return AlertActions.error(error);
        
        AlertActions.success({ message: 'Updated successfully' });
        // console.log("err,res==============",err,res);
        // const sessionUser = SessionStore.getSessionUser();
        // console.log("sessionUser======================",sessionUser);
        // console.log("Roles======================",Roles);
        // let userRole = null;
        // if(!!sessionUser){
        //   const userRoleKey = Object.keys(Roles).find(r=>Roles[r].role === Math.max(...sessionUser.roles) );
        //   userRole = Roles[userRoleKey];
        // }
        // console.log("userRole================",userRole);
        // let route = `/${userRole.type}/send-template-email/${payload.templateId}`;
        // browserHistory.push(route);
      })
    }
  }

  createNoHostVideoTemplate (payload){
    return dispatch=>{
      http
      .post(`/api/template/no-host-video`)
      .send(payload)
      .end((err,res)=>{
        const error = err || res.error ? ServerError(res) : null;
        dispatch({
          error: error,
          data: res.body
        });
        if(error) return AlertActions.error(error);
        else {
          if (res.body.status === 'started') {
            AlertActions.info({message : 'Started Successfully!'});
          }
          else if (res.body.status === 'Successfully created') {
            AlertActions.info({ message: 'Successfully created' });
            setTimeout(() => {
              window.location.href = `/admin/question-templates`;
            }, 1000);
          }
          else { 
            AlertActions.info({message : 'Updated Successfully!'});
          }
        }
      })
    }
  }
  deleteTemplate (templateId,callback){
    return (dispatch)=>{
      http
      .delete(`/api/template/${templateId}`)
      .send()
      .end((err,res)=>{
        if(callback) callback(err);
        const error = err || res.error ? ServerError(res) : null;
        if(error) return AlertActions.error(error);
        else AlertActions.info({message : 'Deleted Successfully!'});
        
        
      })
    }
  }
  getTemplateByName (name,callback){
    return (dispatch)=>{
      http
      .get(`/api/get-default-template/${name}`)
      .query()
      .end((err,res)=>{
        if(callback) callback(err,res);
        const error = err || res.error ? ServerError(res) : null;
        if(error) return AlertActions.error(error);
      })
    }
  }
}

export default alt.createActions(TemplateActions);
