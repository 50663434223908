"use strict";

import React from 'react';

import MenuItem from './MenuItem';

const Menu = props => {
  return (
    <ul className="dropdown-menu typeahead-dropdown-menu col-xs-12 li-input" style={{ display: props.showMenu ? 'block' : 'none'}}>
      {
        props.children
      }
    </ul>
  )
};

export default Menu;
