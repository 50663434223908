'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class ChatActions {
  getMessages(payload) {
    return (dispatch) => {
      http
        .get('/api/chat/message')
        .query(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error({ message: error.message });
        });
    }
  };

  newMessage(payload) {
    return (dispatch) => {
      dispatch({
        data: payload
      })
    }
  };

//  receiveCandidate(data){
//    // console.log("ChatActions send-candidate", data);
//    return dispatch => dispatch(data)
//  }

//  receiveSdp(data){
//    // console.log("ChatActions send-sdp", data);
//
//    return dispatch => dispatch(data)
//  }

  clearStore(){
    return dispatch => dispatch();
  }
  p2pStatus(data){
    // console.log("ChatActions: ", data);
    return dispatch => dispatch(data)
  }

  updateStatus(payload) {
    // console.log("Ready to call update status");
    return (dispatch) => {
      http
        .put('/api/chat/message/status')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: payload
          });

          if (error) AlertActions.error({ message: error.message });
        });
    }
  };
}

export default alt.createActions(ChatActions);
