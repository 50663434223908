"use strict";

import Alt from '../altInstance';
import Http from 'superagent';
import { ServerError } from '../helpers/';
import AlertActions from './AlertActions';

class FileActions {
  uploadFile(file, data) {
    return dispatch => {
      Http
        .post('/api/upload')
        .field('content-type', file.type)
        .field('content-length', file.size)
        .field('data', JSON.stringify(data))
        .attach('file', file, file.fileName)
        .on('progress', progress => {
          dispatch({
            err: null,
            progress: Math.round(progress.percent)
          });
        })
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            err: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        })
    }
  }
  adminEditImage(payload) {
    return dispatch => {
      Http
        .post('/api/admin-edit-image')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            err: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
          else AlertActions.info({message : "Processing started!"})
        })
    }
  }
}

export default Alt.createActions(FileActions);
