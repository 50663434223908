"use strict";

import './App-Container.less';

import React from 'react';

import Header from '../Nav';
import Footer from '../Footer';
import AlertWrapper from '../AlertWrapper';

class AppContainer extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  };

  render() {
    return (
      <div>
        
        <Header {...this.props}/>
        
        <div className="container main" style={{ minHeight: window.innerHeight - 46 }}>
          {this.props.children} 
        </div>
        <Footer />
        <div className="notifications top-right">
          <AlertWrapper />
        </div>
      </div>
    )
  }

}

export default AppContainer;
