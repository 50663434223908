"use strict";
import flat from 'core-js/features/array/flat';

// gets all roles assigned to the current route (passed to the 'authorize' attribute in routing config)
export function getFlatterRoles(routeObjects) {
  let arrayContainsAuthorizeProp = routeObjects.filter(item => item.authorize)
  let authorizePropsArray = arrayContainsAuthorizeProp.map(item => item.authorize);
  let flattenedRolesArray = flat(authorizePropsArray);

  // let flattenedRolesArray = authorizePropsArray.flat();
  let uniqueRoles = []; 
  flattenedRolesArray.map(value=> uniqueRoles.indexOf(value) == -1 ? uniqueRoles.push(value) : '');
  return uniqueRoles;
  // return _.chain(routeObjects)
  //   .filter(item => item.authorize)
  //   .map(item => item.authorize)
  //   .flattenDeep()
  //   .union()
  //   .value();
}

// check if any user role matches any allowed role
export function rolesMatched(allowedRoles, userRoles) {
  //intersection of arrays
  return [allowedRoles, userRoles].reduce((a, b) => a.filter(c => b.includes(c))).length > 0;
}

// checks if allowed roles are exactly the same as user roles
// export function rolesMatchedExact(allowedRoles, userRoles) {
//   return _.isEqual(allowedRoles, userRoles);
// }
