"use strict";

import alt from '../altInstance';
import moment from 'moment-timezone';
import ContentActions from '../actions/ContentActions';
import SessionStore from './SessionStore'
import AnswerStore from './AnswerStore'
import scrollToElement from 'scroll-to-element';
const answerWaitingTime = 30 // Minutes
class ContentStore {
  constructor() {
    this._userContent = null;
    this._userProfile = null;
    this._isConferenceProcessed= false;
    this.bindListeners({
      getUserContent: ContentActions.GET_USER_CONTENT,
      getUserProfile: ContentActions.GET_USER_PROFILE,
      answerCreated: ContentActions.ANSWER_CREATED,
      conferenceProcessed: ContentActions.CONFERENCE_PROCESSED,
      answerEditPopup : ContentActions.answerEditPopup,
      hideShowConf    : ContentActions.hideShowConf
    });
    this.publicMethods = {
      getConferenceParticipant : function(conferenceId){
         let conference = this.state._userContent.find(c =>c._id === conferenceId);
         if (conference && conference.participants) return conference.participants;
         return null;
      },
      checkUserAuthorized : function(){
        if(this.state.userAuthorized) return true;
        else return false;
      } 
    }  
  };
  getUserContent(payload) {
    const SessionUser = SessionStore.getSessionUser();
    // console.log("payload.isAvailable========",payload.data.userAuthorized);
    if(!payload.error) {
      // this.nonInterviewQuestions = payload.data.nonInterviewQuestions;
      // this.nonInterviewAnswers = payload.data.nonInterviewAnswers;
      this.fNonInterviewQuest = payload.data.fNonInterviewQuest;
      if(!payload.data.isAvailable) {
        this.isAvailable = false;
        this.userAuthorized = payload.data.userAuthorized;
      }
      else{
        this.isAvailable = true;
        this.userAuthorized = payload.data.userAuthorized;
        
        this._userContent = payload.data && payload.data.content;
      //  this._recommendedVideos = payload.data && payload.data.recommendedVideos || [];
        let isAnswerCreated = payload.data.isAnswerCreated;

        if(this._userContent && this._userContent.completedAt && SessionUser && SessionUser._id) {
          const participant = this._userContent.participants.find( participant => {
            return participant.user._id ? (participant.user._id === SessionUser._id) : (participant.user === SessionUser._id);
          });

          let currentTime = moment()._d;
  
          let completedAt = this._userContent.completedAt; // temporary comment
          // let completedAt = new Date();
          let offset = currentTime.getTimezoneOffset();
          let completedAtLocalTime = moment(completedAt).add(-offset,'minutes')._d;
          var duration = moment.duration(moment(currentTime).diff(completedAtLocalTime)).asMinutes();
          if(!isAnswerCreated && duration <= answerWaitingTime && participant.role === 'mentor' ){
            this._showNotification = true;
          }
        }  
      }
    }
  };

  getUserProfile(payload) {
    this._userProfile = payload.data && payload.data.profile;
  }
  answerCreated(payload){
    this._showNotification = false;
    this._isConferenceProcessed = false;
    if(payload.questionId){
      let elem = document.getElementById(payload.questionId);
      if(elem){
        scrollToElement(elem,{'align':'bottom',offset:230});
      }
      
    }
  }
  conferenceProcessed(payload){
    const index = this._userContent.findIndex(c => c._id === payload.conferenceId);
    if(index>-1){
      // TODO Improve me with better solution may with react update addons
      this._isConferenceProcessed = true;
      let contents = JSON.parse(JSON.stringify(this._userContent)) ;
      contents[index].tags = payload.tags;
      contents[index].video = payload.video;
      this._userContent = contents;
    }else{
      this._isConferenceProcessed = false;
    }
  }
  answerEditPopup(payload){
    this._answerEditMode = payload.answerEditMode;
  }
  hideShowConf(payload){
    console.log("this._userContent==========",this._userContent);
    this._isConferenceProcessed = true;
    let contents = JSON.parse(JSON.stringify(this._userContent)) ;
    contents.isContentHidden = payload.data.conference.isContentHidden;
    this._userContent = contents;
  }
}

export default alt.createStore(ContentStore, 'ContentStore');
