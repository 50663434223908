"use strict";
import alt from '../altInstance';

import { QuestionActions } from '../actions';

class QuestionStore {
  constructor() {
    this.questionPopup = false;
    this.editQuestionPopup = null;
    this.questCopiedFrom = null;
    this.questions = [];
    // this.ques = [];
    this.qPgNo = {};
    this.questionIds = {};
    this.qType4qId = null;
    this.questionsByOrg = [];

    this.bindListeners({
      openQuestionPopup: QuestionActions.OPEN_QUESTION_POPUP,
      closeQuestionPopup: QuestionActions.CLOSE_QUESTION_POPUP,
      create: QuestionActions.CREATE,
      // _createCopiedFrom: QuestionActions.CREATE_COPIED_FROM,
      // updateCopiedFrom: QuestionActions.UPDATE_COPIED_FROM,
      update: QuestionActions.UPDATE,
      getQuestions: QuestionActions.GET_QUESTIONS,
      getQuestionById: QuestionActions.GET_QUESTION_BY_ID,
      getQuestionCopiedFromById: QuestionActions.GET_QUESTION_COPIED_FROM_BY_ID,
      toggleUpVote: QuestionActions.TOGGLE_UP_VOTE,
      createQA: QuestionActions.CREATE_QA,
      searchQuestions: QuestionActions.SEARCH_QUESTIONS,
      deleteQuestion: QuestionActions.DELETE_QUESTION,
      rejectQuestion: QuestionActions.REJECT_QUESTION,
      approveQuestion: QuestionActions.APPROVE_QUESTION,
      getGeneralQuestionsOfUser:QuestionActions.GET_GENERAL_QUESTIONS_OF_USER,
      getConferenceQuestions : QuestionActions.getConferenceQuestions
    });

    this.publicMethods = {
      getPageNumberPublic: function(qType){
        if(!this.state.qPgNo[qType] ){
          return null
        }else{
          return this.state.qPgNo[qType]
        }
      },
      getQuestionByIdPublic: function (qType) {
        qType = JSON.parse(qType);
        let qArr = this.state.questions.filter(quest => quest._id ===qType.qId);
        if(qArr.length) return qArr[0];
        return null;
      },
      getQuestionsPublic: function(qType){
        let questIds = this.state.questionIds;
        if (questIds && questIds[qType] && questIds[qType].length){
          let questions = [];
          let quest = this.state.questions;
          let q = {};
          questIds[qType].forEach(questId => {
            q = quest.find(qt=>qt._id === questId);
            q ? questions.push(q) : ""
          });
          return (!!questions ? questions :  null);
        }else{
          return null;
        }
      },
      getQTypePublic: function(){
        return this.state.qType4qId;
      },
      setQuestion:function(question){
        this.editQuestion = question;
      },
      getQuestion:function(){
       return  this.editQuestion;
      },
    };
  };

  openQuestionPopup(payload) {
    this.questionPopup = payload.questionPopup || false;
    this.editQuestionPopup = payload.editQuestionPopup || null;
  };

  closeQuestionPopup(payload) {
    this.questionPopup = payload.questionPopup || false;
    this.editQuestionPopup = null;
  };

  create(payload) {
    if(!payload.error){
      this.questionPopup = false;
      this.questions.unshift(payload.data.question);
    }
  };

  // _createCopiedFrom = (payload) => {
  //   if(!payload.error){
  //
  //   }
  // };
  //
  // updateCopiedFrom = (payload) => {
  //   if(!payload.error){
  //
  //   }
  // };

  update(payload) {
    if (!payload.error) {
      this.questionPopup = false;
      let idx = this.questions.findIndex(q=>q._id=== payload.data.question._id);
      if(idx>-1){
        this.questions[idx].text = payload.data.question.text;
        this.questions[idx].copiedFrom = payload.data.question.copiedFrom;
        this.questions[idx].title = payload.data.question.title;
        this.questions[idx].tags = payload.data.question.tags;
        this.questions[idx].isAnonymous = payload.data.question.isAnonymous;
      }
      let questionListidx = this.questionList.findIndex(q=>q._id === payload.data.question._id);
      if(questionListidx>-1){
        this.questionList[questionListidx].text = payload.data.question.text;
        this.questionList[questionListidx].copiedFrom = payload.data.question.copiedFrom;
        this.questionList[questionListidx].title = payload.data.question.title;
        this.questionList[questionListidx].tags = payload.data.question.tags;
        this.questionList[questionListidx].isAnonymous = payload.data.question.isAnonymous;
      }
    }
  };

  createQA(payload){
    if(!payload.error && payload.data && payload.data.question){
      this.questions.unshift(payload.data.question);
    }
  };

  toggleUpVote(payload) {
    if(!payload.error) {
      const questionId = payload.data && payload.data.questionId;
      const upVoteStatus = payload.data && payload.data.upVoteStatus;
      const userId = payload.data && payload.data.userId;
      const index = this.questions.findIndex(q=>q._id=== questionId);
      if(index > -1){
        this.questions[index].upVoteStatus = upVoteStatus;
        ! Array.isArray(this.questions[index].upVotes)
          ? this.questions[index].upVotes = [] : "";

        const upvoteIdx = this.questions[index].upVotes.indexOf(userId);
        upVoteStatus && upvoteIdx ===-1
          ? this.questions[index].upVotes.push(userId)
          : null;
        !upVoteStatus && upvoteIdx >-1
          ? this.questions[index].upVotes.splice(upvoteIdx, 1)
          : null;
      }

    }
  };

  getQuestions(payload) {
    
    if (!payload.error) {
      let qType = payload.data.qType;
      if(payload.data.nonInterviewQuestionsCount){
        this.nonInterviewQuestionsCount = payload.data.nonInterviewQuestionsCount;
      }
      if(payload.data.interviewQuestionCount){
        this.interviewQuestionCount = payload.data.interviewQuestionCount;
      }
      if (payload.data && payload.data.questions) {
        let {questions} = payload.data;
        !this.questionIds[qType] ? this.questionIds[qType] =[]:""
        questions.forEach(quest => {
          let idx = this.questions.findIndex(q=>q._id === quest._id);
          (idx === -1) ? this.questions.push(quest) : ""
          idx = this.questionIds[qType].indexOf(quest._id);
          if(idx === -1){
            this.questionIds[qType].push(quest._id);
          }
        })
      }
      !this.qPgNo[qType] ? this.qPgNo[qType] =1 : this.qPgNo[qType]++
      this.qType4qId= qType;
    }
  };

  getQuestionById(payload) {
    // console.log("payload in store=============",payload);
    // if(!payload.error && payload.data && payload.data.question){
    //   let { question } = payload.data;
    //   let idx = findIndex(this.questions, {_id: question._id });
    //   (idx ===-1) ? this.questions.push(question) : this.questions[idx] = question
    // }
    if(payload.fEmptyQuestions){
      if(!payload.error && payload.data && payload.data.question){
        this.questions = [payload.data.question];
        this.qPgNo = [];
        this.questionIds = undefined;
        this.questionIds = {};
      }  
    }
    else{
    if(!payload.error && payload.data && payload.data.question){
      let { question } = payload.data;
      let idx = this.questions.findIndex(q=>q._id === question._id );
      (idx ===-1) ? this.questions.push(question) : this.questions[idx] = question;
      idx = this.questionIds[JSON.stringify(payload.qType)].indexOf(question._id);
      if(idx === -1){
        this.questionIds[JSON.stringify(payload.qType)].push(question._id);
      }
    }      
    }
  };

  getQuestionCopiedFromById(payload) {
    if(!payload.error && payload.data && payload.data.question){
      const { question } = payload.data;
      this.questCopiedFrom = question;
    }
  };
  searchQuestions(payload){
    
    if(!payload.error && payload.data){
      const { questions,total } = payload.data;
      this.questionList = questions;
      this.questionListTotal = total; 
    }
  }
 
  deleteQuestion(payload){
    if(!payload.error && payload.data){
      let idx = this.questionList.findIndex(q=>q._id === payload.data.question);
      if(idx>-1){
        this.questionList.splice(idx,1);
      }
      
    }
  }
  rejectQuestion(payload){
    if(!payload.error && payload.data){
      let idx = this.questionList.findIndex(q=>q._id === payload.data.question._id);
      if(idx>-1){
        this.questionList.splice(idx,1,payload.data.question);
      }
    }
  }
  approveQuestion(payload){
    if(!payload.error && payload.data){
      let idx = this.questionList.findIndex(q=>q._id === payload.data.question._id);
      if(idx>-1){
        this.questionList.splice(idx,1,payload.data.question);
      }
    }
  }
  getGeneralQuestionsOfUser(payload){
    if(!payload.error && payload.data){
      this.generalQuestions = payload.data.questions || [];
      this.generalAnswers = payload.data.answers || [];
    }
  }
  getConferenceQuestions(payload){
    if(!payload.error && payload.data){
      this.questionsByOrg = payload.data.orgQuestions || [];
    }
  }
}

export default alt.createStore(QuestionStore, 'QuestionStore');
