module.exports = {
  PUBLIC: {
    role: 1,
    type: 'public',
  },
  USER: {
    role: 2,
    type: 'user',
    conferencePage: '/user/conferences',
  },
  STUDENT: {
    role: 3,
    type: 'student',
    conferencePage: '/user/conferences',
  },
  HOST: {
    role: 4,
    type: 'host',
    conferencePage: '/user/conferences',
  },
  MENTOR: {
    role: 8,
    type: 'mentor',
    conferencePage: '/user/conferences',
  },
  MANAGER: {
    role: 12,
    type: 'manager',
    conferencePage: '/user/conferences',
  },
  ADMIN: {
    role: 16,
    type: 'admin',
    conferencePage: '/admin/conferences',
  },
  SUPER_ADMIN: {
    role: 32,
    type: 'super-admin',
    // homePage: '/admin/conferences',
    conferencePage: '/admin/conferences',
  }
};
