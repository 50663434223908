"use strict";

import Alt from '../altInstance';
import CommHistoryActions from '../actions/CommHistoryActions';

class CommHistoryStore {
  constructor() {
    this.emails = [];
    this.total = 0;
    this.commType = null;

    this.bindListeners({
      getEmailHistory: CommHistoryActions.getEmailHistory
    });
  }
  
  getEmailHistory(payload) {
    if(!payload.error) {
      this.emails = payload.data.emails || [];
      this.total = payload.data.total;
      this.commType = payload.data.commType;
    }
  }

}


export default Alt.createStore(CommHistoryStore, 'CommHistoryStore');
