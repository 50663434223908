"use strict";

import alt from '../altInstance';

import { ConferenceInvitesAnalyticsActions } from '../actions';

class ConferenceInviteAnalyticsStore {

  constructor() {

    this._analytics = [];

    this.bindListeners({
      getConferenceInviteAnalytics: ConferenceInvitesAnalyticsActions.getConferenceInviteAnalytics
    });

  };

  getConferenceInviteAnalytics (payload) {
    if (!payload.error && payload.data) {
      this._analytics = payload.data.analytics;
    }
  }

  
}

export default alt.createStore(ConferenceInviteAnalyticsStore, 'ConferenceInviteAnalyticsStore');
