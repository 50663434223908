"use strict";

import "./Footer.less";

import React from "react";

class Footer extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <footer className="col-xs-12 p-a-0">
        <div className="custom-footer col-xs-12 p-a-0">
          <div className="d-flex justify-content-space-between flex-wrap">
            <div className="col-xs-12 col-sm-4 col-md-3">
              <div className="h4">
                <img src="/images/LogoBlue.png" />
              </div>
              <p className="text-justify">
                We help students and junior professionals get an insider's view
                on jobs and college-programs of interest.
              </p>
            </div>
            {/*<div className="col-md-2">*/}
            {/*  <h4>Programs</h4>*/}
            {/*  <ul className="list-unstyled">*/}
            {/*    <li><i className="icon icon-chevron-thin-right"></i>Technology</li>*/}
            {/*    <li><i className="icon icon-chevron-thin-right"></i>Business</li>*/}
            {/*    <li><i className="icon icon-chevron-thin-right"></i>Design</li>*/}
            {/*    <li><i className="icon icon-chevron-thin-right"></i>Science</li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="col-md-2">*/}
            {/*  <h4>Useful Links</h4>*/}
            {/*  <ul className="list-unstyled">*/}
            {/*    <li><i className="icon icon-chevron-thin-right"></i>Home</li>*/}
            {/*    <li><i className="icon icon-chevron-thin-right"></i>About Us</li>*/}
            {/*    <li><i className="icon icon-chevron-thin-right"></i>Sample Videos</li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<div className="col-md-2">*/}
            {/*  <h4>Follow Us On</h4>*/}
            {/*  <ul className="list-unstyled">*/}
            {/*    <li><i className="icons fb"></i>Facebook</li>*/}
            {/*    <li><i className="icons twitter"></i>Twitter</li>*/}
            {/*    <li><i className="icons linkedin"></i>Linkedin</li>*/}
            {/*    <li><i className="icons pin"></i>Pinterest</li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            <div className="col-xs-12 col-sm-4 col-md-3">
              <div className="h4">Get Newsletter</div>
              <input
                type="text"
                placeholder="Enter Email here"
                className="form-control round-100"
              />
              <button
                type="button"
                className="btn btn-orange mt10 btn-animation round-100 col-xs-12"
              >
                Subscribe Here
              </button>
            </div>
          </div>
          <div className="col-xs-12 text-center py10">
            <p>
              <span
                onClick={() => (window.location.href = "/privacy-policy")}
                style={{ cursor: "pointer", marginRight: 20 }}
              >
                Privacy Policy
              </span>
              <span>MentorStudents © 2019. All rights reserved.</span>
              <span
                onClick={() => (window.location.href = "/terms-and-conditions")}
                style={{ cursor: "pointer", marginLeft: 20 }}
              >
                Terms & Conditions
              </span>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
