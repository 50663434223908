'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';
import { AuthInterceptor } from '../utils';
import { Roles } from '../constants';
import { browserHistory } from 'react-router';

class ConferenceActions {
  create(payload, cb) {
    return (dispatch) => {
      http
        .post('/api/create-conference')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if(!error) AlertActions.info({ message: "Conference created successfully!" });
          dispatch({
            error: error,
            data: res.body
          });

          if (typeof cb === 'function') cb(error, res.body);
        });
    }
  };
  createOpenMultiPartyConferenceByUser(payload, cb) {
    return (dispatch) => {
      http
        .post('/api/conference/open-multi-party')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (typeof cb === 'function') cb(error);
        });
    }
  };
  getUserConferences() {
    return (dispatch) => {
      http
        .get('/api/conference')
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);
        });
    }
  };

  getAdminConferences(payload) {
    // console.log("payload.....", payload);
    return (dispatch) => {
      http
        .get('/api/conference/get-all-confs-for-admin')
        .use(AuthInterceptor)
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) AlertActions.error(error)
          else{
            // console.log("res.body===",res.body);
          }
        });
    }
  };
  getUpcomingHostedConfs (query, cb){
    return (dispatch) => {
      http
        .get('/api/conference/get-upcoming-hosted-confs')
        .query(query)
        .use(AuthInterceptor)
        .query()
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ });
          if (error) AlertActions.error(error);
          if(typeof cb === 'function') cb(res.body);

        });
    }
  }

  getTemplateQuestions (query, cb){
    return (dispatch) => {
      http
        .get('/api/conference/get-template-questions')
        .query(query)
        .use(AuthInterceptor)
        .query()
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ });
          if (error) AlertActions.error(error);
          if(typeof cb === 'function') cb(res.body);

        });
    }
  }
  updateHostInConf(payload, cb){
    return (dispatch) => {
      http
        .put('/api/conference/update-host-in-conf')
        .use(AuthInterceptor)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ });
          if (error) return AlertActions.error(error);
          if(typeof cb === 'function') cb(res.body);
          AlertActions.success({ message: "Host updated successfully" });
        });
    }
  }
  getConferenceById(conferenceId, fAssignServer, cb = null) {
    if (fAssignServer && typeof fAssignServer == 'function') {
      // fallback handle
      cb = fAssignServer;
      fAssignServer = false;
    } else {
      fAssignServer = !!fAssignServer;
    }
    return (dispatch) => {
      http
        .get(`/api/conference/${conferenceId}?fAssignServer=${fAssignServer}`)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) {
            console.log("ready to redirect");
            AlertActions.error({ message: error.message });
            if (fAssignServer) {
              browserHistory.push(`/`);
            }
          }
          // console.log("callback called",dispatch);
          dispatch({
            error: error,
            data: res.body
          });
          if (cb) {
            if (err) cb(err);
            else cb(null, res.body.conference);
          }
        });
    }
  };
  getConfByConfCode(confCode, cb) {
    return () => {
      http
        .get(`/api/conference/get-conf-by-conf-code`)
        .query({confCode})
        .use(AuthInterceptor)
        .end((err, res) => {
          // console.log(JSON.stringify(res,null,2))
          // const error = err || res.error ? ServerError(res) : null;
          if(err) cb(err);
          else cb(null, res.body.conference);
        })
    }
  };
  getConfCodeByMentorId(mentor, cb) {
    return () => {
      http
        .get(`/api/conference/get-conf-code-by-mentor-id`)
        .query({mentor})
        .use(AuthInterceptor)
        .end((err, res) => {
          // console.log(JSON.stringify(res,null,2))
          // const error = err || res.error ? ServerError(res) : null;
          if(err) cb(err);
          else cb(null, res.body.confCode);
        })
    }
  };
  getSelfConference(conferenceId, cb) {
    return (dispatch) => {
      http
        .get(`/api/self-video/${conferenceId}`)
        .query()
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (cb) {
            if (err) cb(err);
            else cb(null, res.body.conference);
          }
          // if (error) {
          //   console.log("ready to redirect");
          //   AlertActions.error(error);
          //   setTimeout(() => {
          //     browserHistory.push(`/`);
          //   }, 2000);
          // }
        });
    }
  };

  getAdminRawInput(conferenceId) {
    return dispatch => {
      http
        .get(`/api/conference/${conferenceId}/admin-raw-input`)
        .query({ ts: Date.now() })
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) {
            AlertActions.error(error);
            browserHistory.push(`/`);
          }
          
        })
    }
  }

  updateConference(payload, conferenceId, cb) {
    return (dispatch) => {
      http
        .put(`/api/conference/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);

          if (typeof cb === 'function') cb(error);
        });
    }
  };

  removeFollowupEmail(conferenceId, cb){
    return (dispatch) => {
      http
        .put(`/api/conference/removeFollowupEmail/${conferenceId}`)
        .send()
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);

          if (typeof cb === 'function') cb(error);
        });
    }
  }

  // cancelConference(conferenceId, cb) {
  //   return (dispatch) => {
  //     http
  //       .put(`/api/conference/cancel/${conferenceId}`)
  //       .use(AuthInterceptor)
  //       .end((err, res) => {
  //         const error = err || res.error ? ServerError(res) : null;
  //
  //         dispatch({
  //           error: error,
  //           data: res.body
  //         });
  //
  //         if (error) AlertActions.error(error);
  //
  //         if (typeof cb === 'function') cb(error);
  //       });
  //   }
  // };

  // rescheduleConference(payload, conferenceId, cb) {
  //   return (dispatch) => {
  //     http
  //       .put(`/api/conference/reschedule/${conferenceId}`)
  //       .send(payload)
  //       .use(AuthInterceptor)
  //       .end((err, res) => {
  //         const error = err || res.error ? ServerError(res) : null;
  //         dispatch({
  //           error: error,
  //           data: res.body
  //         });
  //         if (error) AlertActions.error(error);
  //         if (typeof cb === 'function') cb(error);
  //       });
  //   }
  // };

  completeConference(conferenceId, cb) {
    return (dispatch) => {
      http
        .put(`/api/conference/complete-conference/${conferenceId}`)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);

          if (typeof cb === 'function') cb(error);
        });
    }
  };

  updateTaskServer(payload, conferenceId, cb) {
    console.log("conferenceId==========",conferenceId);
    return (dispatch) => {
      http
        .put(`/api/conference/update-task-server/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) console.log("socket connection change reporting:"+error);

          if (typeof cb === 'function') cb(error);
        });
    }
  };

  createToken(payload) {
    return (dispatch) => {
      http
        .post('/api/conference/create-token')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          if (error) AlertActions.error(error);
        });
    }
  };

  processConference(payload, cb) {
    let displayMessage = payload.message;
    delete payload.message;
    return dispatch => {
      http
        .post('/api/conference/process-conference')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (cb && typeof cb === 'function') {
            cb(error, res.body);
          }
          dispatch({
            error: error,
            data: res.body
          });
          if (error) AlertActions.error(error);
          else {
            if (displayMessage) {
              AlertActions.info({ message: displayMessage });
            }
            else if (payload.completedBy == Roles.MENTOR.type) {
              AlertActions.info({ message: "Conference Completed By Mentor!!!" });
            }
            else if (payload.completedBy == Roles.HOST.type) {
              AlertActions.info({ message: "Conference Completed By Host!!!" });
            }
            else if (payload.completedBy == Roles.SUPER_ADMIN.type) {
              AlertActions.info({ message: "Conference Completed By Super Admin!!!" });
            }
            else {
              AlertActions.info({ message: "Conference Completed By Admin!!!" });
            }
            // this.props.router.push('/');
          }
        });
    };
  };

  changeQuestion(payload) {
    return dispatch => {
      dispatch({
        data: payload
      });
    }
  };

  deleteConference(conferenceId) {
    return (dispatch) => {
      http
        .delete(`/api/conference/${conferenceId}`)
        .send()
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          AlertActions.success({ message: "Conference deleted successfully" })
        })
    }
  };

  editConfQuestions(payload, conferenceId) {
    return (dispatch) => {
      http
        .put(`/api/conference/edit-conf-questions/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          AlertActions.success({ message: "Conference questions edited successfully" })
        })
    }
  }

  deleteConfQuestion(questionId, conferenceId, callback) {
    return dispatch => {
      http
        .delete(`/api/conference/delete-conf-question/${questionId}/${conferenceId}`)
        .send()
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (typeof callback === "function") callback(error);
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: 'Conference question removed' });
        })
    }
  }

  processHarkTagging(payload) {
    return (dispatch) => {
      http
        .get(`/api/conference/process-hark-tagging/${payload.conference._id}`)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          AlertActions.success({ message: "Hark Tagging updated successfully" })
        })
    }
  }

  submitTalkToMentorRequest(payload, cb) {
    payload = payload;
    return (dispatch) => {
      http
        .post(`/api/widget/screen`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          AlertActions.success({ message: "Request accepted successfully " })
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  }
  isUserContentAvailable(userId, cb) {
    return (dispatch) => {
      http
        .get(`/api/user/conference/completed/${userId}`)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  }
  oneToOneConferenceDetailsOfUser(payload, cb) {
    payload = payload;
    return (dispatch) => {
      http
        .post(`/api/widget/screen`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  }
  createOpenMultiPartyConf(payload) {
    return (dispatch) => {
      http
        .post('/api/conference/create-open-conference')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          else if(!res.body.isError) {
            AlertActions.success({message: "Conference created successfully!"})
            window.location.reload();
          }

        })
    }
  }

  getQuestionSlots(conferenceId,questionId,cb){
    return (dispatch)=>{
      http
      .get(`/api/conference/question-slots/${conferenceId}`)
      .query({questionId : questionId})
      .use(AuthInterceptor)
      .end((err,res)=>{
        const error = err || res.error ? ServerError(res) : null;
        dispatch({
          error:error,
          data:res.body,
          conferenceId
        });

        if(error) return AlertActions.error(error);
        if (cb && typeof cb == 'function') {
          cb(error, res.body);
        }
      })
    }
  }

  updateAdminRawInputSlots(params, conferenceId, callback) {
    return (dispatch) => {
      http
        .put(`/api/conference/question-slots/${conferenceId}`)
        .send(params)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          callback(error);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        })
    }
  }
  deleteAdminRawInputSlots(params, conferenceId, callback){
    return (dispatch)=>{
      http
      .delete(`/api/conference/question-slots/${conferenceId}`)
      .send(params)
      .use(AuthInterceptor)
      .end((err,res)=>{
        const error = err || res.error ? ServerError(res) : null;
        // console.log("ready to call callback------");
        callback(error);
        dispatch({
          error:error,
          data:res.body
        });
        if(error) return AlertActions.error(error);
      })
    }
  }
  kickoutHost(conferenceId, hostId, callback) {
    return (dispatch) => {
      http
        .put(`/api/conference/${conferenceId}/kickuser`)
        .send({ hostId: hostId })
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (callback && typeof callback === 'function') {
            return callback(error, res.body);
          }
        })
    }
  }
  uploadOnYouTube(payload, callback) {
    return (dispatch) => {
      http
        .post(`/api/conference/youtube-upload`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          if (res.body.success) {
            return AlertActions.success({ message: "This video is being uploaded to youTube" });
          }
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
        });
    }
  }
  deleteFromYouTube(payload, callback) {
    return (dispatch) => {
      http
        .delete('/api/conference/youtube-delete')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error){
            callback(error);
            return AlertActions.error(error);
          } else {
            if (typeof callback === "function") callback(null, res.body);
            AlertActions.success({ message: "Successfully deleted from youtube" });
          }

        })
    }
  }
  saveErrorLogForMicOff(conferenceId){
    return(dispatch)=>{
      http
        .post(`/api/conference/micoff-errorlogs/${conferenceId}`)
        .send()
        .use(AuthInterceptor)
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });
          if(error) return AlertActions.error(error);
        })
    }
  };

  verifyEmailNoOTP(payload,cb){
    return (dispatch) => {
      http
        .put(`/api/conference/verify-email-no-otp`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  }

  verifyEmailWithOTP(payload,cb){
    return (dispatch) => {
      http
        .put(`/api/conference/verify-email-with-otp`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  }

  assignIp (payload){
    return dispatch=>{
      http
        .post(`/api/conference/assign-ip`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          AlertActions.success({message: "Updated successfully!"});

        })
    }
  }
  getFreeIP(payload,callback){
    return dispatch=>{
      http
      .get('/api/conference/free-ip')
      .query(payload)
      .end((err,res)=>{
        if(err) callback(err);
        else callback(err,res.body);
      })
    }
  }
  showHideConference (conferenceId, payload, cb){
    return dispatch=>{
      http
        .post(`/api/conference/show-hide/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (cb && typeof cb == 'function') {
            if(error) cb(error);
            else cb(error, res.body);
          }
          if (error) return AlertActions.error(error);
          AlertActions.success({message: "Updated successfully!"});
        })
    }
  }
  showConferenceInTheMenu(cb) {
    return dispatch => {
      http
        .get(`/api/conference/is-scheduled`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        })
    }
  }
  logUnMountEvent(conferenceId, payload) {
    return (dispatch) => {
      http
        .post(`/api/conference/log-mentor-rec-pg-unmount/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
        })
    }
  }
  toggleHostVideo(conferenceId, payload) {
    return (dispatch) => {
      http
        .put(`/api/conference/toggle-host-video/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          dispatch({
            error: err,
            data: res.body
          });
        })
    }
  }
  toggleMentorVideo(conferenceId, payload) {
    return (dispatch) => {
      http
        .put(`/api/conference/toggle-mentor-video/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          dispatch({
            error: err,
            data: res.body
          });
        })
    }
  }
  updateJobForUser(payload){
    return (dispatch) => {
      http
        .put('/api/update-qType-in-job')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if(!error){
            AlertActions.success({message: "Updated successfully!"});
          }
          // dispatch({
          //   error: error,
          //   user: res.body
          // });
          if(error) AlertActions.error(error);
        });
    }
  }

  getAnswersHistory(conferenceId, cb){
    return (dispatch) => {
      http
        .get(`/api/get-answers-history/${conferenceId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          cb(error, res.body);
        });
    }
  }
  restoreAnswers(date, conferenceId){
    return (dispatch) => {
      http
        .put(`/api/restore-answers/${conferenceId}`)
        .send({date})
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if(!error){
            AlertActions.success({message: "Answers restored successfully!"});
          }
          // dispatch({
          //   error: error,
          //   user: res.body
          // });
          if(error) AlertActions.error(error);
        });
    }
  }

  getMentorLatestConfRecordings(mentorId){
    return dispatch=>{
      http
        .get(`/api/get-mentor-latest-conf-recordings/${mentorId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          dispatch({
            error: error,
            data: res.body
          });
        });
    }
  }
  startOMPCAudioConf(url, payload){
    return (dispatch) => {
      http
        .post(url)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if(!error){
            AlertActions.success({message: "conference started successfully!"});
          }
          // dispatch({
          //   error: error,
          //   user: res.body
          // });
          if(error) AlertActions.error(error);
        });
    }
  }
  makeOMPCServerReady(payload, cb){
    return (dispatch) => {
      http
        .post('/api/make-ompc-server-ready')
        .send(payload)
        .end((err, res) => {
          if (cb && typeof cb == 'function') cb(err, res);
          if(err) AlertActions.error(err);
        });
    }
  }
  mentorKeyPressed (payload, cb) {
    return () => {
      http
        .post('/api/conference/ompc-key-pressed')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (err) AlertActions.error({ message: "Something went wrong" });
          if (cb && typeof cb === 'function') cb(error, res.body);
        })
    }
  }

  shareMentorDeviceInformationWithHost (payload) {
    return (dispatch) => {
      http
        .post('/api/conference/share-mentor-device-information-with-host')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
        })
    }
  }
  startOMPCAudioConf (payload, callback) {
    return (dispatch) => {
      http
        .post('/api/conference/start-ompc-audio-conf')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({message: "Conferene started successfully!"});
          callback(error, res);
        })
    }
  }
  joinOMPCAudioConf (payload, callback) {
    return (dispatch) => {
      http
        .post('/api/conference/join-ompc-audio-conf')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else if(res.body.conference.fConfStarted) AlertActions.success({message: "Call joined successfully!"});
          else AlertActions.error({message : 'Conference has been ended!'});
          callback(error, res);
        })
    }
  }
  endOMPCAudioConf (payload,cb) {
    return (dispatch) => {
      http
        .post('/api/conference/end-ompc-audio-conf')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({message: "Conference ended successfully!"});
          if (typeof cb === 'function') cb();

        })
    }
  }
  completeOMPCAudioConf (payload) {
    return (dispatch) => {
      http
        .post('/api/conference/complete-ompc-audio-conf')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({message: "Conference completed successfully!"});
        })
    }
  }
  receiveOmpcAudioPhoneCall(conferenceId, payload){
    return (dispatch) => {
      http
        .post(`/api/conference/receive-ompc-audio-phone-call/${conferenceId}`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({message: res.body.message});
        })
    }
  }
  cancelOMPCParticipation (payload, callback) {
    return (dispatch) => {
      http
        .put(`/api/conference/cancel-ompc-participation`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "Your participation has been canceled." });

          if (typeof callback === 'function') callback();
        })
    }
  }
  videoUploadedNotification (conferenceId, callback) {
    return (dispatch) => {
      http
        .put(`/api/conference/video-uploaded-notification/${conferenceId}`)
        .send()
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "Request Sent!" });

          if (typeof callback === 'function') callback();
        })
    }
  }
  restartCoturn (conferenceId, callback) {
    return (dispatch) => {
      http
        .put(`/api/conference/restart-coturn/${conferenceId}`)
        .send()
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "Restarted successfully" });

          if (typeof callback === 'function') callback(error);
        })
    }
  }
  assignNewIpToConf (conferenceId, callback) {
    return (dispatch) => {
      http
        .put(`/api/conference/assign-conf-to-new-ip/${conferenceId}`)
        .send()
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "assigned successfully" });

          if (typeof callback === 'function') callback(error);
        })
    }
  }
  receivedCommunication (payload) {
    return (dispatch) => {
      http
        .post(`/api/comm-server/new-comm-received`)
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else {
            AlertActions.success({ message: "Added successfully" });
            setTimeout(()=>{
              window.location.reload();
            },1000);
          }
        })
    }
  }
  addVideoTag (payload, cb) {
    return dispatch => {
      http
        .put("/api/conference/add-video-tag")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({});
          if (error) AlertActions.error(error);
          if (typeof cb === "function") cb(error);
        });
    }
  }

  removeVideoTag (payload, cb) {
    return dispatch => {
      http
        .put("/api/conference/remove-video-tag")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({});
          if (error) AlertActions.error(error);
          if (typeof cb === "function") cb(error);
        });
    }
  }
  addLogs (payload) {
    return dispatch => {
      http
        .post("/api/conference/add-log")
        .send(payload)
        .end((err, res) => {
          // const error = err || res.error ? ServerError(res) : null;
          // dispatch({});
          // if (error) AlertActions.error(error);
          // if (typeof cb === "function") cb();
        });
    }
  }
  addHostNote (payload, cb) {
    return () => {
      http
        .post("/api/conference/save-host-note")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof cb === "function") cb(error);
        });
    }
  }

  saveUserRequestFromHomePage (payload, cb) {
    return () => {
      http
        .post("/api/save-user-req-from-home-page")
        .send(payload)
        .end((err, res) => {
          AlertActions.success({ message: "Your request submitted successfully" });
          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === "function") cb(error);
        });
    }
  }

  updateStartingSlideWithoutUploading (payload, cb) {
    return () => {
      http
        .put("/api/conference/update-starting-slide-obj")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          if(!error) AlertActions.success({ message: "updated successfully" });
          else AlertActions.error(error);

          if (typeof cb === "function") cb(error);
        });
    }
  }
  
  getHostNote (query, cb) {
    return () => {
      http
        .get("/api/conference/get-host-note")
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          // if (error) AlertActions.error(error);
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }

  getInfo4VideoEditing(query, cb) {
    return () => {
      http
        .get("/api/conference/get-info-for-video-editing")
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof cb === "function") cb(error, res.body);
        });
    }
  }

  processEditImageForVideo(payload, callback) {
    return (dispatch) => {
      http
        .post(`/api/conference/process-edit-image-for-video`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: 'processing started' });
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }

  saveAdminRawInput(payload, callback) {
    return (dispatch) => {
      http
        .post(`/api/conference/save-admin-raw-input`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: 'Questions slots saved' });
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }
  addSlotsFromHostSide(payload, callback) {
    return (dispatch) => {
      http
        .post(`/api/conference/add-slots-from-host-side`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: 'Updated successfully!' });
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }

  addNewQuestions(payload, callback) {
    return (dispatch) => {
      http
        .put(`/api/conference/add-new-question`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: 'New questions added successfully' });
          dispatch({ error: error, data: res.body });
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }
  checkKurentoStatus (conferenceId, cb){
    return (dispatch) => {
      http
        .get(`/api/conference/check-kurento-status/${conferenceId}`)
        .query()
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({ });
          if (error) AlertActions.error(error);
          if(typeof cb === 'function') cb(res.body);

        });
    }
  }

  isVPSIPValid (query, cb){
    return () => {
      http
        .get(`/api/conference/validate-vps-ip`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if(typeof cb === 'function') cb(error, res.body);
        });
    }
  }
  updateCurrentQuestion(payload, callback) {
    return () => {
      http
        .put('/api/conference/change-question')
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof callback === "function") callback(error, res.body);
          if (error) return AlertActions.error(error);
        })
    }
  }
  EndWidgetQuestionConference(conferenceId, payload, cb) {
    return (dispatch) => {
      http
        .put(`/api/conference/end-widget-question-conference/${conferenceId}`)
        .send(payload)
        .use(AuthInterceptor)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);
          if (typeof cb === 'function') cb(error);
        });
    }
  };
  getFeedbackQuestions(confCode, cb) {
    return (dispatch) => {
      http
        .get(`/api/conference/get-feedback-questions/${confCode}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          console.log("error==========",error);
          if(cb) cb(error, res);
          dispatch({
            error: error,
            data: res.body
          });

          if (error) AlertActions.error(error);
        });
    }
  };
  saveFeedbackAnswer(payload, callback) {
    return (dispatch) => {
      http
        .post(`/api/conference/save-user-answer-from-feedback-form`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          console.log("error==========",error);
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: 'Response saved!' });
          if (typeof callback === 'function') callback(error, res.body);
        });
    }
  }
}

export default alt.createActions(ConferenceActions);

// setFCallBroswerForConference (payload) {
//   return (dispatch) => {
//     http
//       .post('/api/conference/set-call-browser')
//       .send(payload)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         if (error) AlertActions.error(error);
//         // else AlertActions.success({message: "Conference completed successfully!"});
//       })
//   }
// }
// sendWSMessage(payload, cb) {
//   return (dispatch) => {
//     http
//       .post(`/api/conference/ws/send-message`)
//       .send(payload)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (error) return AlertActions.error(error);
//         if (cb && typeof cb == 'function') {
//           cb(error, res.body);
//         }
//       })
//   }
// }

// updateLastActivityAtSelfVideo(conferenceId, query) {
//   return dispatch => {
//     http
//       .put(`/api/conference/last-activity-at/${conferenceId}`)
//       .send(query)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         // console.log("err,res---------", err, res);
//       })
//   }
// }

// completeConferenceByOther(payload, callback) {
//   return dispatch => {
//     http
//       .post('/api/conference/complete-conference-admin')
//       .send(payload)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (callback) {
//           callback(error, res.body);
//         }
//         if (error) AlertActions.error(error);
//         else {
//           // console.log("payload.completedBy----",payload.completedBy);
//           if (payload.completedBy == Roles.ADMIN.type) {
//             AlertActions.info({ message: "Conference Completed By Admin!!!" });
//           }
//           else if (payload.completedBy == Roles.SUPER_ADMIN.type) {
//             AlertActions.info({ message: "Conference Completed By Super Admin!!!" });
//           }
//           else if (payload.completedBy == Roles.HOST.type) {
//             AlertActions.info({ message: "Conference Completed By Host!!!" });
//           }
//         }
//       });
//   };
// };

// startStopStreamMentorVideo(payload) {
//   return (dispatch) => {
//     http
//       .put('/api/conference/startStopVideo')
//       .send(payload)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (error) AlertActions.error(error);
//       })
//   }
// }

// createConferenceInvited(payload) {
//   return (dispatch) => {
//     http
//       .post('/api/conference/invite')
//       .send(payload)
//       .use(AuthInterceptor)
//       .end((err, res) => {
//         const error = err || res.error ? ServerError(res) : null;
//         dispatch({
//           error: error,
//           data: res.body
//         });
//         if (error) return AlertActions.error(error);
//       })
//   }
// }
