"use strict";

import "./Nav.less";

import React from "react";
import { Navbar, NavDropdown, MenuItem } from "react-bootstrap";

import SearchBar from "../Search-Bar";

import {
  SessionActions,
  QuestionActions,
  ConferenceActions,
} from "../../actions";
import {
  SessionStore,
  AccountStore,
  QuestionStore,
  SearchStore,
} from "../../stores";

import { Roles } from "../../constants";
import lazy from "../../utils/lazy";

const isLocalHost = window.location.origin.includes("localhost");

let LoginOrSignUp =
  isLocalHost && lazy(() => import("../../views/Public/LoginOrSignUp"));
let ForgotPassword =
  isLocalHost &&
  lazy(() => import("../Modals").then((m) => ({ default: m.ForgotPassword })));

let Question = lazy(() =>
  import("../Modals").then((m) => ({ default: m.Question }))
);

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      signUpPopup: false,
      signInPopup: false,
      forgotPasswordPopup: false,
      questionPopup: false,
      user: null,
      loginModal: false,
      signUpModal: false,
      isFocused: false,
      showConferenceTabInTheMenu: false,
      logoSize: "large",
    };

    this._handleSessionStore = this._handleSessionStore.bind(this);
    this._handleAccountStore = this._handleAccountStore.bind(this);
    this._handleQuestionStore = this._handleQuestionStore.bind(this);
    this._handleQuestionPopup = this._handleQuestionPopup.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this._handleModalHandler = this._handleModalHandler.bind(this);
  }

  static propTypes = {
    router: React.PropTypes.object,
  };

  toggleModal() {
    this.setState({
      loginModal: !this.state.loginModal,
      signUpModal: !this.state.signUpModal,
    });
  }

  _handleModalHandler(key, value) {
    this.setState({
      [key]: value,
    });
  }

  componentWillMount() {
    SessionStore.listen(this._handleSessionStore);
    AccountStore.listen(this._handleAccountStore);
    QuestionStore.listen(this._handleQuestionStore);
    SearchStore.listen(this._handleSearchStore);
    this._fetchUserDetails();
  }

  componentWillUnmount() {
    SessionStore.unlisten(this._handleSessionStore);
    AccountStore.unlisten(this._handleAccountStore);
    QuestionStore.unlisten(this._handleQuestionStore);
    SearchStore.unlisten(this._handleSearchStore);
  }

  _fetchUserDetails = () => {
    const user = SessionStore.getSessionUser();
    if (!user) {
      SessionActions.loadSession((err, result) => {
        if (!err && result.user && result.user.credentials) {
          this.checkIfShowConferenceInTheMenu();
          this.setState({ user: result.user.credentials });
        }
      });
    } else {
      this.checkIfShowConferenceInTheMenu();
      this.setState({ user });
    }
  };

  render() {
    const url = window.location.pathname;
    const sessionUser = SessionStore.getSessionUser();
    const {
      user,
      forgotPasswordPopup,
      questionPopup,
      showConferenceTabInTheMenu,
      logoSize,
    } = this.state;

    let dashboardURL = "";
    let fAdmin = false;
    let sessionUserObj = sessionUser || user;
    console.log("sessionUserObj========", sessionUserObj);
    if (sessionUserObj) {
      if (sessionUserObj.fStudent) {
        dashboardURL = "/bootcamp/student-dashboard";
      } else if (
        sessionUserObj.adminRole === "super-admin" ||
        sessionUserObj.adminRole === "admin"
      ) {
        dashboardURL = "/admin/dashboard";
        fAdmin = true;
      }
    }

    let fUserOnlyHost = false;

    if (
      !fAdmin &&
      user &&
      user.roles &&
      user.roles.indexOf(Roles.HOST.role) > -1
    ) {
      fUserOnlyHost = true;
    }

    const msLogo = logoSize === "small" ? "change-logo" : "";
    return (
      <div>
        {isLocalHost && (
          <LoginOrSignUp
            loginModal={this.state.loginModal}
            signUpModal={this.state.signUpModal}
            _handleModalHandler={this._handleModalHandler}
            toggleModal={this.toggleModal}
            isFocused={this.state.isFocused}
          />
        )}
        <Navbar fixedTop>
          <div className="header-flex">
            <div className="navbar-header">
              <a
                className={"main-logo py0 navbar-brand p-a-0 " + msLogo}
                href="/"
              >
                <img className="logo-site" src="/images/LogoBlue.png" />
                <img className="logo-mobile" src="/images/ms-logo.png" />
              </a>
              <div className="header-searchbar">
                <SearchBar {...this.props} />
              </div>
              <Navbar.Toggle />
            </div>

            {url.substring(0, 3) !== "/l/" && url.substring(0, 3) !== "/i/" && (
              <div className="ml-auto">
                <Navbar.Collapse>
                  {user ? (
                    <ul className="nav navbar-nav navbar-right align-items-center">
                      {/*<NavItem eventKey={2} onClick={this._handleQuestionPopup}>Ask a Question</NavItem>*/}
                      <li role="presentation" className="">
                        <a
                          role="button"
                          href="#"
                          onClick={this._handleQuestionPopup}
                        >
                          Ask a Question
                        </a>
                      </li>
                      {/* {
                            Intersection([4, 8, 16, 32], user.roles).length ?
                              <NavItem eventKey={1} onClick={ this._handleMenuItem.bind(this, 'conferences') }> Conferences </NavItem>
                              : null
                          } */}
                      {((user.adminRole && user.adminRole) ||
                        (user.roles &&
                          user.roles.indexOf(Roles.MANAGER.role) > -1)) && (
                        <NavDropdown
                          eventKey={3}
                          title="Manage Tabs"
                          id="manager-dropdown"
                        >
                          <MenuItem
                            eventKey={3.1}
                            onClick={() =>
                              this.props.router.push(
                                "/manager/manage-widget-mentors"
                              )
                            }
                          >
                            Choose Mentors in Plugin
                          </MenuItem>
                          <MenuItem
                            eventKey={3.2}
                            onClick={() =>
                              this.props.router.push(
                                "/manager/add-widget-managers"
                              )
                            }
                          >
                            Plugin Managers
                          </MenuItem>
                          <MenuItem
                            eventKey={3.3}
                            onClick={() =>
                              this.props.router.push(
                                "/manager/manage-widget-questions"
                              )
                            }
                          >
                            Plugin Questions
                          </MenuItem>
                          <MenuItem
                            eventKey={3.4}
                            onClick={() =>
                              this.props.router.push(
                                "/manager/widget-track-leads"
                              )
                            }
                          >
                            Leads
                          </MenuItem>
                          <MenuItem
                            eventKey={3.5}
                            onClick={() =>
                              this.props.router.push(
                                "/manager/mentor-analytics"
                              )
                            }
                          >
                            Mentor analytics
                          </MenuItem>
                          <MenuItem
                            eventKey={3.6}
                            onClick={() =>
                              this.props.router.push("/manager/widget-popout")
                            }
                          >
                            Widget popouts
                          </MenuItem>
                          <MenuItem
                            eventKey={3.7}
                            onClick={() =>
                              this.props.router.push("/manager/conferences")
                            }
                          >
                            ompc confernces
                          </MenuItem>
                        </NavDropdown>
                      )}
                      {showConferenceTabInTheMenu && (
                        <li role="presentation">
                          <a
                            role="button"
                            onClick={() => this._redirectUserToConferencePage()}
                          >
                            Conferences
                          </a>
                        </li>
                      )}
                      <NavDropdown
                        eventKey={2}
                        title={`${user.firstName} ${user.lastName}`}
                        id="user-dropdown"
                      >
                        {dashboardURL && (
                          <MenuItem
                            eventKey={2.1}
                            onClick={() => this.props.router.push(dashboardURL)}
                          >
                            Dashboard
                          </MenuItem>
                        )}
                        <MenuItem
                          eventKey={2.2}
                          onClick={() => this.props.router.push("/user/p")}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          eventKey={2.3}
                          onClick={() =>
                            this.props.router.push("/user/settings")
                          }
                        >
                          Account Settings
                        </MenuItem>
                        {fUserOnlyHost && (
                          <MenuItem
                            eventKey={2.4}
                            onClick={() =>
                              this.props.router.push("/host-availability")
                            }
                          >
                            Set Availability
                          </MenuItem>
                        )}
                        <MenuItem divider />
                        <MenuItem
                          eventKey={2.5}
                          onClick={() => SessionActions.signOut()}
                        >
                          Sign Out
                        </MenuItem>
                      </NavDropdown>
                    </ul>
                  ) : (
                    <ul className="nav navbar-nav navbar-right align-items-center">
                      <li role="presentation" className="">
                        <a href="/user-videos?page=1">Sample Videos</a>
                      </li>
                      <li role="presentation" className="">
                        <a href="/about-us">About us</a>
                      </li>
                      <li role="presentation" className="loginlink">
                        {isLocalHost ? (
                          <a
                            href="JavaScript:Void(0);"
                            className=""
                            role="button"
                            onClick={() =>
                              this._handleModalHandler("loginModal", true)
                            }
                          >
                            LOGIN/SIGNUP
                          </a>
                        ) : (
                          <a href="/sign-in" role="button">
                            LOGIN/SIGNUP
                          </a>
                        )}
                      </li>
                    </ul>
                  )}
                </Navbar.Collapse>
              </div>
            )}
          </div>
        </Navbar>
        {isLocalHost && <ForgotPassword open={forgotPasswordPopup} />}
        <Question open={questionPopup} quesId="" />
      </div>
    );
  }

  checkIfShowConferenceInTheMenu = () => {
    ConferenceActions.showConferenceInTheMenu((err, response) => {
      if (err || !response.result) {
        this.setState({ showConferenceTabInTheMenu: false });
      } else {
        this.setState({ showConferenceTabInTheMenu: true });
      }
    });
  };

  static _handleSignUpPopup() {
    SessionActions.openSignInPopup();
  }

  _handleQuestionPopup() {
    QuestionActions.openQuestionPopup();
  }

  _handleAccountStore(state) {
    this.setState({
      signUpPopup: state.signUpPopup,
      forgotPasswordPopup: state.forgotPasswordPopup,
    });
  }

  _handleQuestionStore(state) {
    this.setState({ questionPopup: state.questionPopup });
  }

  _handleSessionStore(state) {
    const user = SessionStore.getSessionUser();
    const newState = {};

    if (state.isHomeViewLoaded && !this.state.isFocused) {
      newState["loginModal"] = false;
      newState["signUpModal"] = false;
    }
    // newState["loginModal"] = window.location.pathname === '/' ? (user ? false : true) : false;

    this.setState(newState, () => {
      if (state._user && !this.state.user) this._fetchUserDetails();
    });
  }

  _handleSearchStore = (state) => {
    let newState = {};
    if (state.sizeLogoOnNavForMobile) {
      newState["logoSize"] = state.sizeLogoOnNavForMobile;
    }

    this.setState(newState);
  };

  _redirectUserToConferencePage = () => {
    const { adminRole } = SessionStore.getSessionUser() || {};

    let url = "/";

    if (adminRole === "admin" || adminRole === "super-admin") {
      url = "/admin/conferences";
    } else {
      url = "/user/conferences";
    }

    this.props.router.push(url);
  };
}

export default Header;
