"use strict";

const internals = {
  randomPattern: (pattern) => {
    return pattern.replace(/[xy]/g, function (c) {
      var r = Math.random()*16|0,
          v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }
};

module.exports = function (chars) {
  return internals.randomPattern(new Array(chars ? chars + 1 : 16).join('x'));
};
