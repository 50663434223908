"use strict";

import alt from '../altInstance';
import {
    QuestionLinkActions
} from '../actions';

class QuestionLinkStore {
    constructor() {
        this.data = {};
        this.trackingResult = {};
        this.saveQueryResponse = {};
        this.saveScheduledForLaterResponse = {};
        this.mentorEmail = null;
        this.conferenceId = null;
        this.questions = [];
        this.found = false;
        this.loading = true;
        this.scheduledFor = null;
        this.timezone = null;
        this.loading = true;
        this.showSalutation = false;
        this.mentorTimezone = null;

        this.bindListeners({
            trackPage: QuestionLinkActions.trackPage,
            _updateMentorEmailAddress: QuestionLinkActions._updateMentorEmailAddress,
            getQuestionLinkInfo: QuestionLinkActions.getQuestionLinkInfo,
            getConferenceIdFromConfCode: QuestionLinkActions.getConferenceIdFromConfCode,
            // acceptTermsAndCondition: QuestionLinkActions.acceptTermsAndCondition
        });
    };

    getQuestionLinkInfo(body) {
        if(!body.error && body.data.found) {
            this.conferenceId = body.data.conference._id;
            this.mentorEmail = body.data.conference.mentor.email;
            this.mentorId = body.data.conference.mentor._id;
            this.questions = body.data.conference.template.questVideos;
            this.conference = body.data.conference;
            this.answers = body.data.answers;
            this.suggestedConfTimes = body.data.conference.suggestedConfTimes;
            this.found = true;
            this.loading = false;
            this._notInterestedReasons = body.data.notInterestedReasons;
            this.scheduledFor = body.data.conference.scheduledFor;
            this.timezone = body.data.conference.timezone;
            this.isCanceled = body.data.conference.isCanceled;
            this.mentorTimezone = body.data.conference.mentor.timezone;
        }
    }

    _updateMentorEmailAddress(body) {
        if (!body.error) {
            this.mentorEmail = body.data.mentorEmail;
        }
    }
    saveResponse(payload){

    }
    trackPage(body) {
        if (!body.error) {
            this.trackingResult = body.data;
        }
    }

    getConferenceIdFromConfCode(body) {
        if(!body.error && body.data.result) {
            this.conferenceId = body.data.result.conferenceId;
            // this.showSalutation = !body.data.result.acceptedTermsAndCondition;
        }
        this.loading = false;
    }

    // acceptTermsAndCondition(body) {
    //     // console.log("body----------",body);
    //     if(!body.error && body.data.user) {
    //         // this.showSalutation = !body.data.user.acceptedTermsAndCondition;
    //     }
    // }

}

export default alt.createStore(QuestionLinkStore, 'QuestionLinkStore');
