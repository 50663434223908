"use strict";

import Alt from '../altInstance';
import { WidgetActions } from '../actions';

class WidgetStore {
  constructor() {
    this._widgets = [];
    this._setting = {};
    this._users = [];
    this._widgetTrackLeadsReport = [];
    this.widgetAggregatedTrackLeadsReport = null;
    this.approvedQuestions = [];
    this.approvedQuestionsCount = 0;
    this.questionList = [];
    this.questionListTotal = 0;
    this.userListTotal = 0;
    this._widgetReportDates = null;
    this.widgetsOfTypeSpecific = [];
    this.widgetMentors = [];
    this.widgetAmbassadors = [];
    this.ompcConferences = [];
    this.pendingRequests = [];
    
    this.bindListeners({
      getWidgetById                         :   WidgetActions.getWidgetById,
      getWidgets                            :   WidgetActions.getWidgets,
      createWidget                          :   WidgetActions.createWidget,
      updateWidget                          :   WidgetActions.updateWidget,
      getManagers                           :   WidgetActions.GET_MANAGERS,
      deleteManager                         :   WidgetActions.DELETE_MANAGER,
      getManagerWidgets                     :   WidgetActions.GET_MANAGER_WIDGETS,
      getWidgetApprovedQuestions            :   WidgetActions.GET_WIDGET_APPROVED_QUESTIONS,
      answerToggleUpVote                    :   WidgetActions.ANSWER_TOGGLE_UP_VOTE,
      questionToggleUpVote                  :   WidgetActions.QUESTION_TOGGLE_UP_VOTE,
      searchWidgetQuestions                 :   WidgetActions.SEARCH_WIDGET_QUESTIONS,
      updateAnswer                          :   WidgetActions.UPDATE_ANSWER,
      getSettings                           :   WidgetActions.GET_SETTINGS,
      setSettings                           :   WidgetActions.SET_SETTINGS,
      approveWidgetQuestion                 :   WidgetActions.APPROVE_WIDGET_QUESTION,
      deleteQuestion                        :   WidgetActions.DELETE_MANAGER_QUESTION,
      getMentors                            :   WidgetActions.GET_MENTORS,
      getManagerTrackLeads                  :   WidgetActions.getManagerTrackLeads,
      getManagerAggregatedTrackLeadsReport  :   WidgetActions.getManagerAggregatedTrackLeadsReport,
      getManagerWidgetsOfTypeSpecific       :   WidgetActions.getManagerWidgetsOfTypeSpecific,
      getMentorsByWidget                    :   WidgetActions.getMentorsByWidget,
      resetWidgetMentors                    :   WidgetActions.resetWidgetMentors,
      saveSchoolInUserAndJobInUser          :   WidgetActions.saveSchoolInUserAndJobInUser,
      getWidgetAmbassadors                  :   WidgetActions.getWidgetAmbassadors,
      getOmpcConferencesOfWidget            :   WidgetActions.getOmpcConferencesOfWidget
    });

    this.publicMethods = {
      getWidget: (widgetId) => {
        return this._widgets.find(t => t._id === widgetId);
      },
    };

  };
  answerToggleUpVote(payload){
    if(!payload.error) {
      const answerId = payload.data && payload.data.answerId;
      const upVoteStatus = payload.data && payload.data.upVoteStatus;
      const userId = payload.data && payload.data.userId;
      const questionId = payload.data && payload.data.questionId;
      const index = this.approvedQuestions.findIndex(q=>q._id === questionId);
      if(index>-1){
        const ansIndex = this.approvedQuestions[index].answers.findIndex(a=>a._id === answerId);
        if (ansIndex > -1){
          ! Array.isArray(this.approvedQuestions[index].answers[ansIndex].upVotes)
            ? this.approvedQuestions[index].answers[ansIndex].upVotes = []: "";

          const upVoteIdx = this.approvedQuestions[index].answers[ansIndex].upVotes.indexOf(userId);
          upVoteStatus && upVoteIdx ===-1
            ? this.approvedQuestions[index].answers[ansIndex].upVotes.push(userId)
            : null;
          !upVoteStatus && upVoteIdx >-1
            ? this.approvedQuestions[index].answers[ansIndex].upVotes.splice(upVoteIdx,1)
            : null;
        }
      }
    }
  }
  questionToggleUpVote(payload) {
    if (!payload.error) {
      let questionId = payload.data && payload.data.questionId;
      let upVoteStatus = payload.data && payload.data.upVoteStatus;
      let userId = payload.data && payload.data.userId;
      const index = this.approvedQuestions.findIndex(q => q._id === questionId);
      if (index > -1) {
        this.approvedQuestions[index].upVoteStatus = upVoteStatus;
        !Array.isArray(this.approvedQuestions[index].upVotes)
          ? this.approvedQuestions[index].upVotes = [] : ""

        const upVoteIdx = this.approvedQuestions[index].upVotes.indexOf(userId);
        upVoteStatus && upVoteIdx === -1
          ? this.approvedQuestions[index].upVotes.push(userId)
          : null;
        !upVoteStatus && upVoteIdx > -1
          ? this.approvedQuestions[index].upVotes.splice(upVoteIdx, 1)
          : null;
      }
    }
  }
  getManagers(payload){
    if(!payload.error) {
        // save owners into the store if required
    }
  }
  deleteManager(payload){
    if(!payload.error) {
        // save owners into the store if required
    }
  }
  addManager(payload){
    if(!payload.error) {
      // save owners into the store if required
  }
  }
  getManagerWidgets(payload){
    if(!payload.error) {
      // save owners into the store if required
      this._widgets = payload.data;
    }
  }
  getWidgetApprovedQuestions(payload){
    if(!payload.error) {
      this.approvedQuestions = payload.data.questions;
      this.approvedQuestionsCount = payload.data.total;
      this.widget = payload.data.widget;
    } 
  }
  updateAnswer(payload){
    const answerId = payload.data.ans._id;
    const questionId = payload.data.ans.question;
    const index = this.approvedQuestions.findIndex(q=>q._id === questionId);
    if(index>-1) {
      const ansIndex = this.approvedQuestions[index].answers.findIndex(a => a._id === answerId);
      if (ansIndex > -1) {
        this.approvedQuestions[index].answers[ansIndex] = payload.data.ans;
      }
    }
  }
  searchWidgetQuestions(payload){
    if(!payload.error && payload.data){
      const { questions, total} = payload.data;
      this.questionList = questions;
      this.questionListTotal = total;
    }
  }
  
  getSettings(payload){
    if(!payload.error) {
      this._setting = payload.data;
    }
  }
  setSettings(payload){
    if(!payload.error) {
      this._setting = payload.data;
      
    }
  }
  deleteQuestion(payload){
    if(!payload.error && payload.data){
      let idx = this.questionList.findIndex(q=>q._id === payload.data.question);
      if(idx>-1){
        this.questionList.splice(idx,1);
      }
      
    }
  }
  approveWidgetQuestion(payload){
    if(!payload.error && payload.data){
      let idx = this.questionList.findIndex(q=>q._id === payload.data.question);
      if(idx>-1){
        this.questionList.splice(idx,1);
      }
    }
  }
  getWidgets(payload){
    if(!payload.error){
      this._widgets = payload.data.widgets || [];
    }
    
  }
  getWidgetById(payload){
    if(!payload.error) {
      const widget = payload.data.widget;
        if(widget && widget._id){
          if(this._widgets.findIndex(t=>t._id===widget._id) >-1){
            this._widgets = this._widgets
              .map(t => {
                if(t._id === widget._id) t = widget;
                return t;
              })
          } else {
            this._widgets = this._widgets.concat(widget);
          }
      }
    }
  }
  createWidget(payload){
    this._widgets.push(payload.data.widget);
  }
  updateWidget(payload){
    if(!payload.error) {
      const widget = payload.data.widget;
        if(widget && widget._id){
          if(this._widgets.findIndex(t=>t._id===widget._id) >-1){
            this._widgets = this._widgets
              .map(t => {
                if(t._id === widget._id) t = widget;
                return t;
              })
          } else {
            this._widgets = this._widgets.concat(widget);
          }
      }
    }    
  }
  getMentors(payload){
    if(!payload.error && payload.data){
      this._users = payload.data.users,
      this.userListTotal = payload.data.count
    }
  }
  getManagerTrackLeads(payload) {
    if(!payload.error && payload.data) {
      console.log('here', payload.data);
      this._widgetTrackLeadsReport = payload.data.report;
      this._widgetReportDates = payload.data.dates;
      this.widgetAggregatedTrackLeadsReport = null;
    }
  }

  getManagerAggregatedTrackLeadsReport(payload) {
    if(!payload.error && payload.data) {
      this.widgetAggregatedTrackLeadsReport = payload.data.report;
      this._widgetTrackLeadsReport = null;
      this._widgetReportDates = null;
    }
  }

  getManagerWidgetsOfTypeSpecific (payload) {
    if(!payload.error && payload.data) {
      this.widgetsOfTypeSpecific = payload.data.widgets;
    }
  }

  getMentorsByWidget (payload) {
    if(!payload.error && payload.data && payload.data.records) {
      const mentors = payload.data.records.map(record => ({
        ...record.mentor,
        schoolInUser: record['schoolInUser'],
        jobInUser: record['jobInUser']
      }));
      this.widgetMentors = mentors;
    }
  }

  resetWidgetMentors () {
    this.widgetMentors = [];
  }

  saveSchoolInUserAndJobInUser(payload) {
    if(!payload.error) {
      this.widgetMentors = [];
    }
  }

  getWidgetAmbassadors(payload) {
    if (!payload.error && payload.data) {
      this.widgetAmbassadors = payload.data.map(record => {
        const { 
          _id, numberOfConfMissed, numberOfParticipants, numberOfParticipantsAttended, numberOfConf, mentor 
        } = record;
        return {
          _id, 
          numberOfConf, 
          numberOfConfMissed, 
          numberOfParticipants, 
          numberOfParticipantsAttended,
          mentor: mentor && mentor[0] 
        }
      });
    }
  }

  getOmpcConferencesOfWidget (payload) {
    if (!payload.error && payload.data) {
      this.ompcConferences = payload.data.ompcConferences;
      this.pendingRequests = payload.data.pendingRequests;
    }
  }
};

export default Alt.createStore(WidgetStore, 'WidgetStore');
