"use strict";

import alt from '../altInstance';

import { TagActions, QuestionActions, AnswerActions } from '../actions';

class TagStore {
  constructor() {
    this._popupTags = {};
    this._newTag = null;
    // this._newITag = null;
    this._suggestions = [];
    // this._itags = [];
    // this._searchResults = [];
    // this._tag = null;

    this.bindListeners({
      addChildTag: TagActions.addChildTag,
      getSuggestions: TagActions.GET_SUGGESTIONS,
      getSuggestionsForCopiedFrom: TagActions.GET_SUGGESTIONS_FOR_COPIED_FROM,
      openQuestionPopup: QuestionActions.OPEN_QUESTION_POPUP,
      closeQuestionPopup: QuestionActions.CLOSE_QUESTION_POPUP,
      openAnswerPopup: AnswerActions.OPEN_ANSWER_POPUP,
      closeAnswerPopup: AnswerActions.CLOSE_ANSWER_POPUP,
      create: QuestionActions.CREATE,
      createAns: AnswerActions.CREATE,                        
      createQA: QuestionActions.CREATE_QA,
      // addInterpretedTag: TagActions.ADD_INTERPRETED_TAG,
      updateParentTagsInChild: TagActions.updateParentTagsInChild,
      deleteNewtag      : TagActions.deleteNewtag,
      searchConfereceTags : TagActions.searchConfereceTags
      // addItagForTag: TagActions.ADD_ITAG_FOR_TAG
      // search: TagActions.SEARCH,
      // createTag: TagActions.CREATE
    });

    this.publicMethods = {
      resetSearch: () => {
        this._searchResults = [];
      },

      getTags: (tagFor) => {
        let tags= this._popupTags;
        return tags;
      }
    };
  };

  addChildTag(payload) {
    if(!payload.error){
      if(payload.data
        && payload.data.hasOwnProperty('tagFor')
        && payload.data.hasOwnProperty('text')
        && payload.data.hasOwnProperty('_id')) {
    
        this._newTag= {
            tagFor: payload.data.tagFor,
            text: payload.data.text,
            type: payload.data.type,
            _id: payload.data._id,
            parentTags: payload.data.parentTags
        };
      }
    }
  };

  // addItagForTag(payload){
  //   console.log(">>>>>>>>>>>>>>>>>>>>>>",payload);
  // }

  updateParentTagsInChild(payload){
    // console.log("enter in updateParentTagsInChild in store------",payload);
    if(!payload.error){
      if(payload.data
        // && payload.data.hasOwnProperty('tagFor')
        && payload.data.hasOwnProperty('text')
        && payload.data.hasOwnProperty('_id')) {
    // console.log("enter in store----------",payload.data.parentTags);
        this._newTag = {
            tagFor: payload.data.tagFor || '',
            text: payload.data.text,
            _id: payload.data._id,
            parentTags: payload.data.parentTags
        };
      }
    }
  }

  // addInterpretedTag(payload) {
  //   if(!payload.error){
  //     if(payload.data
  //       && payload.data.hasOwnProperty('tagFor')
  //       && payload.data.hasOwnProperty('text')
  //       && payload.data.hasOwnProperty('_id')) {

  //       this._newITag= {
  //           tagFor: payload.data.tagFor,
  //           text: payload.data.text,
  //           _id: payload.data._id
  //       };
  //     }
  //   }
  // };

  getSuggestions(payload) {
    if(!payload.error){
      if(payload.data ){
        this._suggestions=payload.data.suggestions || [];
      }
    }
  }

  getSuggestionsForCopiedFrom = (payload) => {
    // console.log("enter in getSuggestionsForCopiedFrom in store--------- ",payload.data);
    if(!payload.error){
      if(payload.data){
        this._suggestions = payload.data.suggestions || [];
      }
    }
  };
  deleteNewtag = ()=>{
    this._newTag = null;
    // this._newITag = null;
  }
  openQuestionPopup(payload) {
    let tags = [], tagFor='newQuestion';
    if (payload.hasOwnProperty('editQuestionPopup') && payload.editQuestionPopup){
      let quest = payload.editQuestionPopup;
      tagFor = quest.hasOwnProperty('_id') ? quest._id : tagFor;
      tags = quest.hasOwnProperty('tags') ? quest.tags : tags;
    }
    !this._popupTags.hasOwnProperty(tagFor) ? this._popupTags[tagFor]=tags : "";
  }

  // openCreateCopiedFrom = (payload) => {
  //   let tags = [], tagFor='newQuestion';
  //   if (payload.hasOwnProperty('editCreateCopiedFrom') && payload.editCreateCopiedFrom){
  //     let quest = payload.editCreateCopiedFrom;
  //     tagFor = quest.hasOwnProperty('_id') ? quest._id : tagFor;
  //     tags = quest.hasOwnProperty('tags') ? quest.tags : tags;
  //   }
  //   console.log('openCreateCopiedFrom in TagStore',tags,tagFor, this._popupTags);
  //   !this._popupTags.hasOwnProperty(tagFor) ? this._popupTags[tagFor]=tags : "";
  // };

  openAnswerPopup(payload) {
    let tags = [], tagFor='newAnswer';
    if (payload.hasOwnProperty('editAnswerPopup') && payload.editAnswerPopup){
      let ans = payload.editAnswerPopup;
      tagFor = ans.hasOwnProperty('_id') ? ans._id : tagFor;
      tags = ans.hasOwnProperty('tags') ? ans.tags : tags;
    }
    !this._popupTags.hasOwnProperty(tagFor) ? this._popupTags[tagFor]=tags : "";
  };

  closeQuestionPopup(payload){
    this._popupTags.newQuestion=[];
    this._newTag = null;
    this._suggestions = [];
  }

  // closeCreateCopiedFrom = (payload) => {
  //   this._popupTags.newQuestion=[];
  //   this._newTag = null;
  //   this._suggestions = [];
  // }

  closeAnswerPopup(payload){
    this._popupTags.newAnswer=[];
    this._newTag = null;
    this._suggestions = [];
  }


  create(){
    this._popupTags.newQuestion=[];
    this._newTag = null;
    this._suggestions = [];
  }

  createAns(){
    this._popupTags.newAnswer=[];
    this._newTag = null;
    this._suggestions = [];
  }

  createQA(){
    this._popupTags.newQuestion=[];
    this._popupTags.newAnswer=[];
    this._newTag = null;
    this._suggestions = [];
  }

  // search(payload) {
  //   if(!payload.error) {
  //     this._searchResults = payload.data.results;
  //   }
  // };
  searchConfereceTags(payload) {
    console.log("payload========",payload);
    if(!payload.error) {
      this._searchResults = payload.data.results;
    }
  };
  // createTag(payload) {
  //   if(!payload.error) {
  //     this._tag = payload.data;
  //   }
  // }
}

export default alt.createStore(TagStore, 'TagStore');