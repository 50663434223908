"use strict";

import React from 'react';

export default (label, text) => {
  if(!text || text.length <= 2) return label;

  return (<span>
    {
      label
        .toLowerCase()
        .split(text.toLowerCase())
        .reduce((prev, current, idx) => {
          if (!idx) {
            return [current];
          }
          return prev.concat(<span key={idx} className="highlight">{ text }</span>, current);
        }, [])
    }
  </span>);
};
