'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';
import { AuthInterceptor } from '../utils';

class SessionActions {

  openSignInPopup() {
    return (dispatch) => {
      dispatch({ signInPopup: true });
    };
  };

  closeSignInPopup() {
    return (dispatch) => {
      dispatch({ signInPopup: false });
    };
  };

  signIn(payload) {
    return (dispatch) => {
      http
        .post('/api/sign-in')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            user: res.body
          });
          if(error) AlertActions.error(error);
        });
    }
  };


  userAutoLogin(payload, cb=null){
    return (dispatch) => {
      http
        .post("/api/user-auto-login")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) {
            AlertActions.error({ message: error.message });
          }
          dispatch({
            error: error,
            data: res.body
          });
          if (cb && typeof cb === 'function') {
            if (err) cb(err);
            else cb(null, res.body);
          }
        });
    }
  };

  userContentAutoLogin(payload, cb=null){
    return (dispatch) => {
      http
        .post("/api/user-content-auto-login")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) {
            AlertActions.error({ message: error.message });
          }
          dispatch({
            error: error,
            data: res.body
          });
          if (cb && typeof cb === 'function') {
            if (err) cb(err);
            else cb(null, res.body);
          }
        });
    }
  };

  signOut() {
    return (dispatch) => {
      http
        .delete('/api/sign-out')
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error(error);
        });
    }
  };
  loadSession(cb) {
    return (dispatch) => {
      http
        .get('/api/get-session')
        //  todo: AuthInterceptor throwing error
        .use(AuthInterceptor)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error || res.error,
            user: res.body
          });
          if(cb && typeof cb =='function'){
            cb(error,{user:res.body})
          }
          // if(error) AlertActions.error(error);
        });
    }
  };
}

export default alt.createActions(SessionActions);
