"use strict";

import Alt from '../altInstance';
import FileActions from '../actions/FileActions';
import AlertActions from '../actions/AlertActions';

class FileStore {
  constructor() {
    this._fileProgress = null;
    this._fileCdn = null;

    this.bindListeners({
      uploadFile: FileActions.UPLOAD_FILE
    });

    this.publicMethods = {
      cleanup: () => {
        this._fileProgress = null;
        this._fileCdn = null;
      }
    }
  };

  uploadFile(payload) {
    if(payload.error) {
      return;
    }

    if(payload.progress) {
      this._fileProgress = payload.progress;
    }

    if(payload.data) {
      this._fileCdn = payload.data.cdn;
    }
  };
}

export default Alt.createStore(FileStore, 'FileStore');
