"use strict";

import alt from '../altInstance';

import AccountActions from '../actions/AccountActions';
import SessionActions from '../actions/SessionActions';

class AccountStore {
  constructor() {
    this.signUpPopup = false;
    this.forgotPasswordPopup = false;
    this.isResetPassword = false;
    this.adminAccounts = [];
    this.hostAccounts = [];
    this.mentorAccounts = [];
    this.searchResult = [];
    this.searchResultForBoth = [];
    this.mergingAccounts = null;
    this.backUpOfAccToBeKeptProfile = null;
    this.showReplaceOptions = null;
    this.replacedEntities = [];
    this.activeButton = null;
    this.pendingRequests = [];
    this.availabilitySlots = [];
    this.bindListeners({
      openSignUpPopup: AccountActions.OPEN_SIGN_UP_POPUP,
      closeSignUpPopup: AccountActions.CLOSE_SIGN_UP_POPUP,
      openForgotPasswordPopup: AccountActions.OPEN_FORGOT_PASSWORD_POPUP,
      closeForgotPasswordPopup: AccountActions.CLOSE_FORGOT_PASSWORD_POPUP,
      handleRegister: AccountActions.REGISTER,
      forgotPassword: AccountActions.FORGOT_PASSWORD,
      resetPassword: AccountActions.RESET_PASSWORD,
      createAdminAccount: AccountActions.CREATE_ADMIN_ACCOUNT,
      createHostAccount: AccountActions.CREATE_HOST_ACCOUNT,
      createMentorAccount: AccountActions.CREATE_MENTOR_ACCOUNT,
      searchCountry : AccountActions.SEARCH_COUNTRY,
      editHostAvailability : AccountActions.editHostAvailability,
      // updateAvailability : AccountActions.UPDATE_AVAILABILITY,
      // deleteAvailability  : AccountActions.DELETE_AVAILABILITY,
      // getAvailability    : AccountActions.GET_AVAILABILITY,
      // getAvailabilityByAdmin : AccountActions.GET_AVAILABILITY_BY_ADMIN,
      // deleteAvailabilityByAdmin : AccountActions.DELETE_AVAILABILITY_BY_ADMIN,
      addUserInWidget : AccountActions.ADD_USER_IN_WIDGET,
      setPassword  :AccountActions.SET_PASSWORD,
      checkTokenExpire : AccountActions.CHECK_TOKEN_EXPIRE,
      getUsers    : AccountActions.GET_USERS,
      searchInUsers: AccountActions.searchInUsers,
      searchInUsersAndPening: AccountActions.searchInUsersAndPening,
      getUserByIds: AccountActions.getUserByIds,
      discardAccountToBeDeleted: AccountActions.discardAccountToBeDeleted,
      mergeRightProfileInLeft: AccountActions.mergeRightProfileInLeft,
      deleteJobOrSchool: AccountActions.deleteJobOrSchool,
      resetAccountToKeptsState: AccountActions.resetAccountToKeptsState,
      replaceOrAdd: AccountActions.replaceOrAdd,
      swap: AccountActions.swap,
      cancelSwaping: AccountActions.cancelSwaping,
      changePosition: AccountActions.changePosition,
      toggleActiveButton: AccountActions.toggleActiveButton,
      getUnverifiedHostsOfOMPC: AccountActions.getUnverifiedHostsOfOMPC,
      getHostAvailability: AccountActions.getHostAvailability
    });
  };

  openSignUpPopup(payload) {
    this.signUpPopup = payload.signUpPopup || false;
  };

  closeSignUpPopup() {
    this.signUpPopup = false;
  };

  openForgotPasswordPopup(payload) {
    this.forgotPasswordPopup = payload.forgotPasswordPopup || false;
  };

  closeForgotPasswordPopup() {
    this.forgotPasswordPopup = false;
  };

  handleRegister(payload) {
    if (!payload.error) {
      // SessionActions.loadSession();
      // window.location.href = '/'
    }
  };

  forgotPassword(payload){
    if(!payload.error) {
      this.forgotPasswordPopup =false;
    }
  };

  resetPassword(payload) {
    if(!payload.error) {
      this.isResetPassword = true;
    }
  };

  createAdminAccount(payload) {
    if(!payload.error) {
      this.adminAccounts.push(payload.data.credentials);
    }
  };

  createHostAccount(payload) {
    if(!payload.error) {
      this.hostAccounts.push(payload.data);
    }
  };

  createMentorAccount(payload) {
    if(!payload.error) {
      this.mentorAccounts.push(payload.data);
    }
  };
  searchCountry(payload) {
    if(!payload.error) {
      this._countriesOption = payload.data;
    }
  };
  editHostAvailability(payload){

  }
  // updateAvailability(payload){

  // }
  // deleteAvailability (payload){
  //   // console.log("payload=========",payload);
  //   let newAvailability = [];
  //   newAvailability = this._availability.filter(o => o._id !== payload.data.availId);
  //   if(!payload.err) this._availability = newAvailability;   
  // }
  // deleteAvailabilityByAdmin (payload){
  //   // console.log("payload=========",payload);
  //   let newAvailability = [];
  //   newAvailability = this._availability.filter(obj=> obj._id !== payload.data.availId);
  //   if(!payload.err) this._availability = newAvailability;   
  // }

  // getAvailability(payload){
  //   if(!payload.err) this._availability = payload.data;
  // }
  // getAvailabilityByAdmin(payload){
  //   if(!payload.err) this._availability = payload.data;
  // }
  addUserInWidget(payload){
    if(!payload.err) this._isInserted = true;
  }
  addPendingUser(payload){
    //if(!payload.err) this._isInserted = true;
  }
  setPassword(payload){
    
  }
  checkTokenExpire(){
    this.checkTokenExpireResponse = true;
  }
  getUsers(payload){
    if(!payload.err){
      this.users = payload.data.users;
      this.usersCount = payload.data.count;
    }
  }

  searchInUsers(payload) {
    if(!payload.error && payload.data.result) {
      this.searchResult = payload.data.result
    }
  }

  searchInUsersAndPening(payload) {
    if(!payload.error && payload.data.result) {
      this.searchResultForBoth = payload.data.result
    }
  }

  getUserAccountsByIds(paylod) {
    if(!payload.error && payload.data.result) {
      this.userAccounts = payload.data.result;
    }
  }
  getUserByIds(payload) {
    if(!payload.error && payload.data) {
      this.mergingAccounts = payload.data;
      this.backUpOfAccToBeKeptProfile = JSON.parse(JSON.stringify(payload.data.accToBeKept.profile || {}));
    }
  }

  resetAccountToKeptsState() {
    this.mergingAccounts.accToBeKept.profile = JSON.parse(JSON.stringify(this.backUpOfAccToBeKeptProfile));
  }

  discardAccountToBeDeleted() {
    this.mergingAccounts.accToDeleted = {};
  }

  deleteJobOrSchool (params) {
    const { type, userType, index } = params;
    this.mergingAccounts[userType].profile[type].splice(index, 1);
  }

  replaceOrAdd(params) {
    const { type } = params;
    if(this.showReplaceOptions && type == this.showReplaceOptions.type) {
      this.showReplaceOptions = null;  
    }
    else {
      this.showReplaceOptions = params;
    }
  }

  swap(params) {
    const { index } = params;
    const { accToBeKept, accToDeleted } = this.mergingAccounts;    
    const replaceType = this.showReplaceOptions.type;
    const replaceIndex = this.showReplaceOptions.index;
    const type2 = this.showReplaceOptions.type2;

    if(type2 == "add") {
      accToBeKept.profile[replaceType].splice((replaceIndex+1), 0, accToDeleted.profile[replaceType][index]); 
    }
    else {
      this.replacedEntities.push({
        type: replaceType,
        entity: JSON.parse(JSON.stringify(accToBeKept.profile[replaceType][replaceIndex])),
        replacedBy: JSON.parse(JSON.stringify(accToDeleted.profile[replaceType][index])),
        accToBeKeptIndex: replaceIndex,
        accToDeletedIndex: index
      });

      accToBeKept.profile[replaceType][replaceIndex] = accToDeleted.profile[replaceType][index];
    }

    accToDeleted.profile[replaceType].splice(index, 1);
    this.showReplaceOptions = null;
    this.activeButton = null;
  }

  cancelSwaping(params) {
    const { type, _id } = params;
    let _index = -1;
    for(let i=0; i<this.replacedEntities.length; i++) {
      const { replacedBy } = this.replacedEntities[i];
      if(replacedBy._id == _id) {
        _index = i;
        break;
      }
    }

    if(_index > -1) {
      const { accToBeKept, accToDeleted } = this.mergingAccounts;    
      const { entity, replacedBy, accToDeletedIndex } = this.replacedEntities[_index];
      
      for(let i=0; i<accToBeKept.profile[type].length; i++) {
        const { _id } = accToBeKept.profile[type][i];
        console.log("here", _id, replacedBy._id, entity._id);
        if(replacedBy._id == _id) {
          accToBeKept.profile[type][i] = entity;
          break;
        }
      }

      this.replacedEntities.splice(_index, 1);
      console.log(this.replacedEntities);
      accToDeleted.profile[type].splice(accToDeletedIndex, 0, replacedBy);
    }
  }

  changePosition(params) {
    const { array, type } = params;
    const { accToBeKept } = this.mergingAccounts;
    accToBeKept.profile[type] = array;
  }

  mergeRightProfileInLeft() {
    const { accToBeKept, accToDeleted } = this.mergingAccounts;
    if(!accToBeKept.profile || !accToDeleted.profile) {
      return null;
    }
    accToBeKept.profile.jobs = accToBeKept.profile.jobs.concat(accToDeleted.profile.jobs);
    accToBeKept.profile.schools = accToBeKept.profile.schools.concat(accToDeleted.profile.schools);
    
    // Here, we are adding only the widgets which are not already in the widgets of the left profile
    const widgetsOfLeftProfile = accToBeKept.profile.widgets || [];
    const widgetsOfRightProfile = accToDeleted.profile.widgets || [];
    
    for(let i=0; i<widgetsOfRightProfile.length; i++) {
      const { _id } = widgetsOfRightProfile[i];
      const _found = widgetsOfLeftProfile.find(widget => widget._id == _id);
      if(!_found) {
        widgetsOfLeftProfile.push(widgetsOfRightProfile[i]);
      }
    }

    // Here, we are adding only the email which are not already in the emails of the left profile
    const emailsOfLeftProfile = accToBeKept.profile.emails || [];
    const emailsOfRightProfile = accToDeleted.profile.emails || [];
    
    for(let i=0; i<emailsOfRightProfile.length; i++) {
      const _email = emailsOfRightProfile[i];
      const _found = emailsOfLeftProfile.find(email => email == _email);
      if(!_found) {
        emailsOfLeftProfile.push(_email);
      }
    }
  }

  toggleActiveButton(params) {
    const { _id } = params;
    this.activeButton = this.activeButton == _id ? null : _id;
  }

  getUnverifiedHostsOfOMPC (payload) {
    if (!payload.error && payload.data) {
      this.pendingRequests = payload.data.pendingRequests;
    }
  }

  getHostAvailability (payload) {
    if (!payload.error && payload.data) {
      let {availabilitySlots, hosts} = payload.data;
      this.availabilitySlots = availabilitySlots;
      this.hosts = hosts;
    }
  }
}

export default alt.createStore(AccountStore, 'AccountStore');
