"use strict";

import alt from '../altInstance';

import { OpenMultipartyActions } from '../actions';

class OpenMultipartyStore {
  constructor() {

    this.bindListeners({
        muteUnmute: OpenMultipartyActions.MUTE_UNMUTE,
    });
  };

  muteUnmute(payload) {
    console.log("enter in mute unmute store------",payload);
    this.muteUnmuteData = payload;
  };

}

export default alt.createStore(OpenMultipartyStore, 'OpenMultipartyStore');
