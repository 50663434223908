"use strict";

import React from 'react';

const MenuItem = props => {
  return (
    <li className="text-capitalize col-xs-12" onMouseDown={ props.onSelection.bind(this, props.option) }>
      <a className="col-xs-12" href="javascript:void(0)">
        { props.children }
      </a>
    </li>
  )
};

export default MenuItem;
