"use strict";

import React from 'react';
import { Typeahead, Menu, MenuItem, HighlightText } from './Typeahead';

import { delay } from '../../utils';

import { SearchActions } from '../../actions';
import { SearchStore } from '../../stores';

class SearchInput extends React.PureComponent {


  constructor() {
    super();

    this.state = {
      options: []
    };

    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleSelection = this._handleSelection.bind(this);
    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleSearchStore = this._handleSearchStore.bind(this);
    this._handleRenderMenu = this._handleRenderMenu.bind(this);
    this._handleLabelKey = this._handleLabelKey.bind(this);
  };

  static propTypes = {
    onChange: React.PropTypes.func,
    onSelection: React.PropTypes.func,
    onAdvancedSelection: React.PropTypes.func
  };

  componentWillMount() {
    const { value } = this.props;

    if (value) {
      // this.setState({options: [value], defaultSelected: [value]});
    }

    SearchStore.listen(this._handleSearchStore);
  };

  render() {
    const { options, searchResult } = this.state;

    return (
      <div>
        <Typeahead
          className="search-input"
          onSubmit={this._handleSubmit}
          // options={ options }
          searchResult={searchResult}
          labelKey={this._handleLabelKey}
          onInputChange={this._handleTextChange}
          onChange={this._handleSelection}
          placeholder="Search Here..."
          renderMenu={this._handleRenderMenu} />
      </div>
    );
  };

  _handleSearchStore(state) {
    // const options = [].concat(state._users).concat(state._organizations);
    const searchResult = state._searchResult;
    this.setState({ searchResult });
  };

  _handleSubmit(e) {
    this.setState({ options: [], isSelected: true });
    if (this.props.handleSubmit && typeof this.props.handleSubmit === 'function') {
      this.props.handleSubmit()
    }
    e.preventDefault();
  };

  _handleLabelKey(option) {
    switch (option._type) {
      case 'user':
        return `${option._source.firstName} ${option._source.lastName}`;
        break;
      case 'organization':
        return `${option._source.name}`;
        break;
    }
  };

  _handleSelection(value) {
    // delay(() => {
      this.props.onSelection(value);
    // }, 1000);
    this.setState({ options: [], isSelected: true });
  };

  _handleTextChange(value) {
    if (typeof this.props.onChange === 'function') this.props.onChange(value);

    const searchTimeStamp = Date.now();
    console.log(searchTimeStamp, value);
    SearchActions.searchAll({ keyword: value }, searchTimeStamp);

    // delay(() => {
    //   const searchTimeStamp = Date.now();
    //   console.log(searchTimeStamp, value);
    //   SearchActions.searchAll({ keyword: value }, searchTimeStamp);
    // }, 100);
  };

  _handleRenderMenu(results, menuProps, menuItemProps) {
    if (!results) return;
    const keyword = menuItemProps.text;

    const getProfilePic = (item, type) => {
      if (type === 'users') {
        if (item && item._source && item._source.profilePicUrl) {
          return item && item._source && item._source.profilePicUrl;
        }
        else {
          return '/images/profile-icon.png'
        }
      }
      else {
        if (item && item._source && item._source.logoUrl) {
          return item && item._source && item._source.logoUrl;
        }
        else {
          if ((item._source.url && item._source.url.indexOf('company') > -1) || (item._source.type && item._source.type.indexOf('company') > -1) || (item._source.liUrl && item._source.liUrl.indexOf('company') > -1)) {
            return '/images/university.jpg'
          }
          else {
            return '/images/school.png'
          }
        }
      }
    };

    const items = ["users"].map(type => {
      
      return [
        <li className="divider col-xs-12" role="separator" key={`footer-divider`} />,
        results[type].items.map((item, idx) => {
          const { _source } = item;
          const { jobs = [], schools = [] } = _source;

          return (
            <MenuItem key={idx} option={item} position={idx} {...menuItemProps}>
              <figure key="logo">
                <img src={getProfilePic(item, type)} />
              </figure>
              <div className="search-user-details">
                <span>{HighlightText(`${_source.firstName} ${_source.lastName}`, keyword)}</span>
                {this.getJobLabel(jobs, keyword)}
                {this.getSchoolLabel(schools, keyword)}
              </div>
              <strong key="name">
              </strong>
            </MenuItem>
          );
        })
      ];
    })
    .concat([
      <li className="divider col-xs-12" role="separator" key={`footer-divider`} />,
      //<li className="col-xs-12" key={`footer-content`} onClick={ () => {} }><a className="col-xs-12" href="javascript:;"> Use Advanced Filters </a></li>
    ]);

    return <Menu {...menuProps}>{items}</Menu>;
  };

  getJobLabel = (jobs = [], keyword = "") => {
    if (jobs.length === 0) return null;

    const latestJob = jobs.find(j => j.isCurrent) || jobs[0];
    const jobLabel = `${latestJob.position}, ${latestJob.companyName}`

    return (
      <span className="h6"> 
        <br />
        <i className="glyphicon glyphicon-briefcase"></i>
        {' '}
        {HighlightText(jobLabel, keyword)}
      </span>
    );
  }

  getSchoolLabel = (schools = [], keyword = "") => {
    if (schools.length === 0) return null;

    const latestSchool = schools.find(s => s.isCurrent) || schools[0];
    const { major, degree, schoolName } = latestSchool;

    let schoolLabel = `${major ? major+"," : ""} ${degree ? degree+"," : ""} ${schoolName || ""}`;

    return (
      <span className="h6">
        <br />
        <i className="glyphicon glyphicon-education"></i>
        {' '}
        {HighlightText(schoolLabel, keyword)}
      </span>
    );
  }

}

export default SearchInput;
