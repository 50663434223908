"use strict";

import alt from '../altInstance';
import { browserHistory } from 'react-router';
import { ConferenceActions } from '../actions';
import {SessionStore } from '../stores'
import { Roles } from '../constants';
const resolutionArray = [
  {
    width : 1920,
    height: 1080
  },
  {
    width : 1600,
    height: 900
  },
  {
    width : 1366,
    height: 768
  },
  {
    width : 1280,
    height: 720
  },
  {
    width : 1152,
    height: 648
  },
  {
    width : 1024,
    height: 576
  },
  {
    width : 960,
    height: 540
  },
  {
    width : 896,
    height: 504
  },
  {
    width : 800,
    height: 450
  },
  {
    width : 768,
    height: 432
  },
  {
    width : 640,
    height: 360
  }
]
class ConferenceStore {
  constructor() {
    this._conferences = [];
    this._conference = null;
    this._activeConferenceId = null;
    this._currentQuestion = null;
    this._adminRawInput = [];
    this._token = null;
    // this._canConvert = true;
    this._isBusy = false;
    this._startingSlideUploadProgress = 0;
    this._originalPicturePath = null;
    this._resolutionArray = resolutionArray;
    this._completedConferences = [];
    this._incompleteConferences = [];
    this.eventList = [];
    this.fIsErrorInAdminRawInputAPI = false;
    this.fOverconstrainedError = false;
    this._adminRawHistories = [];
    this.bindListeners({
      create: ConferenceActions.CREATE,
      getUserConferences: ConferenceActions.GET_USER_CONFERENCES,
      getAdminConferences: ConferenceActions.GET_ADMIN_CONFERENCES,
      getConferenceById: ConferenceActions.GET_CONFERENCE_BY_ID,
      deleteConference: ConferenceActions.DELETE_CONFERENCE,
      deleteConfQuestion: ConferenceActions.DELETE_CONF_QUESTION,
      editConfQuestions: ConferenceActions.EDIT_CONF_QUESTIONS,
      getAdminRawInput: ConferenceActions.GET_ADMIN_RAW_INPUT,
      createToken: ConferenceActions.CREATE_TOKEN,
      updateConference: ConferenceActions.UPDATE_CONFERENCE,
      // cancelConference: ConferenceActions.CANCEL_CONFERENCE,
      // rescheduleConference: ConferenceActions.RESCHEDULE_CONFERENCE,
      // convertVideos: ConferenceActions.CONVERT_VIDEOS,
      processConference: ConferenceActions.PROCESS_CONFERENCE,
      changeQuestion: ConferenceActions.CHANGE_QUESTION,
      getSelfConference: ConferenceActions.getSelfConference,
      // completeConferenceByOther:ConferenceActions.COMPLETE_CONFERENCE_BY_OTHER,
      completeConference:ConferenceActions.COMPLETE_CONFERENCE,
      createOpenMultiPartyConferenceByUser: ConferenceActions.CREATE_OPEN_MULTI_PARTY_CONFERENCE_BY_USER,
      processHarkTagging:ConferenceActions.PROCESS_HARK_TAGGING,
      submitTalkToMentorRequest: ConferenceActions.SUBMIT_TALK_TO_MENTOR_REQUEST,
      isUserContentAvailable:ConferenceActions.IS_USER_CONTENT_AVAILABLE,
      oneToOneConferenceDetailsOfUser: ConferenceActions.ONE_TO_ONE_CONFERENCE_DETAILS_OF_USER,
      // createConferenceInvited: ConferenceActions.CREATE_CONFERENCE_INVITED,
      getQuestionSlots   : ConferenceActions.GET_QUESTION_SLOTS,
      kickoutHost:ConferenceActions.KICKOUT_HOST,
      deleteFromYouTube : ConferenceActions.DELETE_FROM_YOU_TUBE,
      createOpenMultiPartyConf : ConferenceActions.CREATE_OPEN_MULTI_PARTY_CONF,
      toggleHostVideo : ConferenceActions.toggleHostVideo,
      toggleMentorVideo : ConferenceActions.toggleMentorVideo,
      getMentorLatestConfRecordings: ConferenceActions.getMentorLatestConfRecordings,
      addNewQuestions: ConferenceActions.addNewQuestions
    });

    this.publicMethods = {
      getConference: function (conferenceId) {
        return this.state._conferences.find(c=>c. _id === conferenceId );
      },

      getUserConferences: function () {
        // return filter(this.state._conferences, { isCompleted: false, isConverted: false });
        return (this.state._conferences);
      },

      getUserRoleInConference: () => {
        const SessionUser = SessionStore.getSessionUser();
        return this._conference.participants.find(p => p.user._id === SessionUser._id).role || null;
      },

      getUserId: () => {
        const SessionUser = SessionStore.getSessionUser();
        return SessionUser._id || null;
      },

      getAdminConferences: function () {
        return this.state._conferences;
      },
      dimensionsList:function(){
        return this.state._resolutionArray;
      },
      getActiveConferenceId : function(){
        return this.state._activeConferenceId;
      },
      checkHasAudioPermission : ()=>{
        return new Promise((resolve, reject)=>{
          navigator.mediaDevices.getUserMedia({audio : true})
          .then((stream)=>{
            resolve();
          }).catch(Err=>{
            console.error("Err============",Err);
            reject(Err);
          })  
        })
      },
      checkHasWebCamPermission : ()=>{
        return new Promise((resolve, reject)=>{
          navigator.mediaDevices.getUserMedia({video : true})
          .then((stream)=>{
            resolve();
          }).catch(Err=>{
            console.error("Err============",Err);
            reject(Err);
          })  
        })
      },
      getDimensions: function(dimension){
        return new Promise((resolve, reject)=>{
          let possibleResolutions = [];
          if(dimension){
            for(let i =0;i<resolutionArray.length;i++){
              if(resolutionArray[i].width === dimension.width){
                possibleResolutions = resolutionArray.slice(i,resolutionArray.length);
                break;
              }
            }
            if(possibleResolutions.length===0) return reject({error:'dimensionNotFound'});
            this.getBestResolution(possibleResolutions)
            .then((resultDimension)=>{
              if(!resultDimension) {
                return resolve(dimension);
              }
              else {
                return resolve(resultDimension);
              }
            }).catch(err=>{
              return reject(err);
            })
          }
          else{
             this.getBestResolution(resolutionArray).then((resultDimension)=>{
              if(!resultDimension) {
                return resolve(dimension);
              }
              else {
                return resolve(resultDimension);
              }
            }).catch(err=>{
              return reject(err);
            })
          }  
        })
      //  return cb(null,  {width : 640,height: 360} );
      },
      getBestResolution : function(possibleResolutions,counter = 0){
        let self = this;
        let constraints = null;
        if (!this.fOverconstrainedError) {
          constraints = {
            audio : true,
            video : {
              width : {exact : possibleResolutions[counter].width},
              height : {exact : possibleResolutions[counter].height}  
            }
          };
        }
        else {
          constraints = {
            audio : true,
            video : {
              width: possibleResolutions[counter].width,
              height: possibleResolutions[counter].height  
            }
          };
        }

        return this.checkMediaConstraints(constraints).then(()=>{
          return possibleResolutions[counter];
        })
        .catch((error)=>{
          console.log("getBestResolution -> error", error);
          console.log("getBestResolution -> error.name", error.name);
          if(error && error.name == 'NotAllowedError'){
            throw new Error('NotAllowedError');
          }
          else if(error && error.name == 'NotFoundError'){
            throw new Error('NotFoundError');
          }
          
          const cond1 = error.name === 'ConstraintNotSatisfiedError';
          const cond2 = error.name  === 'OverconstrainedError';
          const cond3 = error.message === "Invalid constraint";

          if(cond1 || cond2 || cond3) {
            if (!self.fOverconstrainedError) {
              self.fOverconstrainedError = true;
              return self.getBestResolution(possibleResolutions, counter);
            }
          }

          counter++;
          if(!possibleResolutions[counter]){
            return null;
          }
          else{
            return self.getBestResolution(possibleResolutions,counter)
          }
          // else if(error.name === 'ConstraintNotSatisfiedError' || error.name  === 'OverconstrainedError'){
          //   // console.log("again call getBestResolution");
          //   return self.getBestResolution(possibleResolutions,counter)
          // }
          // else return constraints;
        }) 
      },
      checkMediaConstraints : function(constraints){
        return new Promise((resolve,reject)=>{
          navigator.mediaDevices.getUserMedia(constraints)
          .then((stream)=>{
            // stream.close();
            stream.getTracks().forEach((trackInput) => {
              const track = trackInput;
              track.onended = null;
              track.stop();
            });
    
            resolve();
          })
          .catch(function (error) {
            console.log("error===========",error);
            reject(error);
          });
        })
      },
      getQuestionSlotsPublic : function(){
        const obj = {questionSlots:this.state._questionSlots, recordings:this.state._recordings};
        return obj;
      },
      getFOverconstrainedError: function() {
        // console.log("this.fOverconstrainedError", !!this.fOverconstrainedError);
        return !!this.fOverconstrainedError;
      }
    };
  };
  create (payload) {
    if (!payload.error) {
      this._conferences.push(payload.data.conference);
      this._conference = payload.data.conference;
    }
  };

  getAdminConferences(payload) {
    if (!payload.error) {
      this._conferences = payload.data.conferences;
    }
  };

  getUserConferences(payload) {
    if (!payload.error) {
      this._conferences = payload.data.conferences;
    }
  };

  getConferenceById(payload) {
    if (!payload.error) {
      let {conference} = payload.data;
      if(conference) {
        if(!conference.currentQuestion) {
          conference.currentQuestion = conference.questions[0] && conference.questions[0]._id
                                            ? conference.questions[0]._id
                                            : null;
        }
        this._currentQuestion = conference.currentQuestion;
        this._conference = conference;
        this._activeConferenceId = conference._id;
        const idx = this._conferences.findIndex(_conference=>_conference && _conference._id === conference._id);
        if(idx>-1){
          this._conferences[idx]=conference;
        } else {
          this._conferences.push(conference);
        }
      } else {
        this._conference = null;
        this._activeConferenceId = null;
      }
    }
  };

  getSelfConference(payload) {
    if(!payload.error && payload.data ) {
      let {conference, fSpeakingSlotExists} = payload.data;
      if(conference) {
        this._currentQuestion = conference.currentQuestion  || conference.questions[0]._id;
        conference.currentQuestion = this._currentQuestion;
        this._conference = conference;
        this._activeConferenceId = conference._id;
        const idx = this._conferences.findIndex(c => c && c._id === conference._id);
        if (idx > -1) {
          this._conferences[idx] = conference;
        } else {
          this._conferences.push(conference);
        }
        this._fSpeakingSlotExists = fSpeakingSlotExists;
      } else {
        this._conference = null;
        this._activeConferenceId = null;
      }
    }
  };

  deleteConference(payload) {
    if (!payload.error) {
      const {conferenceId} = payload.data;
      const idx = this._conferences.findIndex(e=>e._id===conferenceId);
      if(idx>-1){
        this._conferences.splice(idx,1);
        this._conference = null;
      }
    }
  }

  deleteConfQuestion(payload){
    if (!payload.error) {
      const {conferenceId, questionId} = payload.data;
      const idx = this._conferences.findIndex(e=>e._id===conferenceId);
      if(idx>-1){
        let conference = this._conferences[idx];
        const idx2 = conference.questions.findIndex(i=>i._id===questionId);
        if(idx2>-1){
          conference.questions.splice(idx2,1);
        }
        this._conferences[idx] = JSON.parse(JSON.stringify(conference));
        this._conference = this._conferences[idx];
      }
    }
  }

  editConfQuestions(payload) {
    if (!payload.error) {
      const {conferenceId} = payload.data;
      const idx = this._conferences.findIndex(e=>e._id===conferenceId);
      if(idx>-1){
        this._conferences.splice(idx,1);
      }
    }
  }

  getAdminRawInput(payload){
    if (!payload.error && payload.data) {
      this._adminRawInput = payload.data.adminRawInput;
      this._isError = payload.data.isError || false;
      this._error = payload.data.error || '';
      this._adminRawHistories = payload.data.adminRawHistories;
      this.s3VideoUrl = payload.data.s3VideoUrl;
      this.isContentHidden = payload.data.isContentHidden;
      this.fHideConference = payload.data.fHideConference;
    }
  }

  createToken (payload) {
    if(!payload.error) {
      this._token = payload.data.token;
    }
  };

  // convertVideos (payload) {
  //   this._canConvert = payload.canConvert || false;
  // };

  processConference (payload) {
    // console.log("this._conference----------",this._conference);
    if(!payload.error ) {
      if(payload.data && payload.data.conference && payload.data.conference._id){
        if(!this._conference){
          this._conference = this._conferences && this._conferences
          .find(conference => {
            return conference._id === payload.data.conference._id ? payload.data.conference : conference;
          });  
        }
        this._conference.isCompleted = true;
        this._conference.currentQuestion = this._conference.questions[0]._id;
        this._currentQuestion = this._conference.questions[0]._id;
      }
        const SessionUser = SessionStore.getSessionUser();
        const participant = payload.data.conference.participants.find( participant => {
          return participant.user._id
            ? participant.user._id===SessionUser._id
            : participant.user === SessionUser._id
        });
        if(participant.role !== Roles.MENTOR.type){
          // browserHistory.push('/');
        }
      }
      else if(!payload.error && payload.data && payload.data.adminRawInput){
        this._adminRawInput = payload.data.adminRawInput;   
      }
      // <editor-fold desc=">> toggling flags based on api response">
        if (payload.error) this.fIsErrorInAdminRawInputAPI = true;
        else this.fIsErrorInAdminRawInputAPI = false
      // </editor-fold>
  };


  // completeConferenceByOther (payload) {
  //   //if(!payload.error)
  //   //browserHistory.push('/');
  // };

  updateConference (payload) {
    if(!payload.error) {  
      this._conferences = this._conferences && this._conferences
        .map(conference => {
          return conference._id === payload.data.conference._id ? payload.data.conference : conference;
        });
    }
  };

  // cancelConference (payload) {
  //   if(!payload.error) {
  //     this._conferences = this._conferences && this._conferences
  //       .map(conference => {
  //         return conference._id === payload.data.conference._id ? payload.data.conference : conference;
  //       });
  //   }
  // };

  // rescheduleConference (payload) {
  //   if(!payload.error) {  
  //     this._conferences = this._conferences && this._conferences
  //       .map(conference => {
  //         return conference._id === payload.data.conference._id ? payload.data.conference : conference;
  //       });
  //   }
  // };
  completeConference (payload) {
  };
  changeQuestion(payload) {
    const {conferenceId, currentQuestion, error} = payload.data;
    if(!error && !!currentQuestion && !!conferenceId) {
      this._conference.currentQuestion = currentQuestion;
      this._currentQuestion = currentQuestion;
    }
  };

  createOpenMultiPartyConferenceByUser (payload) {
    if (!payload.error) {
      
    }
  }

  createOpenMultiPartyConf(payload){
    if(payload.data.isError){
        this.fErrorInCreateOpenPartySessions = true;      
        this.ErrorInScheduledFor = payload.data.scheduledFor;
    }
  }

  processHarkTagging(payload){
    if (!payload.error) {
      
    }
    // no need to update store
  }
  submitTalkToMentorRequest(payload){
    // TODO haandle me here
  }
  isUserContentAvailable(payload){

  }
  oneToOneConferenceDetailsOfUser(payload){
  }
  // createConferenceInvited(payload){
  //   if (!payload.error) {
  //    this.isEmailSubmitted = true;
  //   }
  // }
  // sendWSMessage(payload){
  //   // handle here
  // }
  kickoutHost(payload) {
    // if(!payload.error) {  
    //   this._conferences = this._conferences && this._conferences
    //     .map(conference => {
    //       return conference._id === payload.data.conference._id ? payload.data.conference : conference;
    //     });
    // }
  };
  getQuestionSlots(payload){
    if(!payload.error){
      this._questionSlots = payload.data.questionSlots;
      this._recordings = payload.data.recordings;
    }
    else{
      this._questionSlots = [];
      this._recordings = [];
      const {f} = this.props.location.query;
      if(f){
        browserHistory.push(`/user/ms/${payload.data.confCode}?er=false`);
        window.location.reload();
      }
    }
  }

  deleteFromYouTube(payload){
    if(!payload.error && payload.data){
      // TODO need to cross check with Danish
      const idx = this._conferences.findIndex(e=>e._id===payload.data.conference);
      if(idx>-1){
        this._conferences[idx].youTubeId = null;
      }
    }
  }
  toggleHostVideo(payload){
    if(!payload.error && payload.data){
      let { conference } = payload.data;
      if(conference){
        this._conference = conference;
        this._activeConferenceId = conference._id;
        const idx = this._conferences.findIndex(c => c && c._id === conference._id);
        if (idx > -1) {
          this._conferences[idx] = conference;
        } else {
          this._conferences.push(conference);
        }  
      }
    }
  }
  toggleMentorVideo(payload){
    if(!payload.error && payload.data){
      let { conference } = payload.data;
      if(conference){
        this._conference = conference;
        this._activeConferenceId = conference._id;
        const idx = this._conferences.findIndex(c => c && c._id === conference._id);
        if (idx > -1) {
          this._conferences[idx] = conference;
        } else {
          this._conferences.push(conference);
        }  
      }
    }
  }

  getMentorLatestConfRecordings(payload){
    console.log("enter in getMentorLatestConfRecordingsin store------", payload);
    if(!payload.error && payload.data){
      let { recordings } = payload.data;
      this._recordings = recordings;
    }
  }

  addNewQuestions(payload) {
    if (!payload.error && payload.data) {
      const { conference } = payload.data;
      if (conference) {
        this._conference = conference;
        const idx = this._conferences.findIndex(c => c && c._id === conference._id);
        if (idx > -1) {
          this._conferences[idx] = conference;
        } else {
          this._conferences.push(conference);
        }
      }
    }
  };
}

export default alt.createStore(ConferenceStore, 'ConferenceStore');
