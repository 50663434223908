import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';
class EmailTemplateActions {
  updateEmailTemplate(emailTemplateid, payload){
    return dispatch =>{
        http
        .put(`/api/email-templates/edit/${emailTemplateid}`)
        .send(payload)
        .end((err,res)=>{
        var error = err || res.error ? ServerError(res) : null;
        dispatch({
            error: error,
            data: res.body
        });
        if(error) return AlertActions.error(error);
        else AlertActions.info({message : 'Updated successfully'});
        })
    }
  }
  createEmailTemplate(payload){
    return dispatch =>{
        http
        .post(`/api/email-templates/create`)
        .send(payload)
        .end((err,res)=>{
        var error = err || res.error ? ServerError(res) : null;
        dispatch({
            error: error,
            data: res.body
        });
        if(error) return AlertActions.error(error);
        else AlertActions.info({message : 'Created successfully'});
        })
    }
  }
  seeEmailBody(payload, cb){
    return dispatch =>{
      http
      .post(`/api/template/see-email-body`)
      .send(payload)
      .end((err,res)=>{
        var error = err || res.error ? ServerError(res) : null;
        
        dispatch({ error: error, data: res.body });

        if(error) return AlertActions.error(error);
        if(typeof cb === 'function') cb(res.body);
      })
    }
  }
}

export default alt.createActions(EmailTemplateActions);


