"use strict";

import alt from '../altInstance';
import { browserHistory } from 'react-router';
import AccountActions from '../actions/AccountActions';
import SessionActions from '../actions/SessionActions';
import { Roles } from '../constants';
const isSecure = window.location.protocol =='https:' ? true : false;
let cookieOptions = {
  "ttl": 315569260000,
  "isSecure": isSecure,
  "secure": isSecure,
  "isHttpOnly": true,
  "isSameSite":false,
  "path":"/",
  "encoding": "none",
  "clearInvalid": true, // remove invalid cookies
  "strictHeader": false, // true mean don't allow violations of RFC 6265
  "ignoreErrors": true
} 
class SessionStore {

  constructor() {
    
    this.signInPopup = false;

    this.bindListeners({
      loadSession: SessionActions.LOAD_SESSION,
      openSignInPopup: SessionActions.OPEN_SIGN_IN_POPUP,
      closeSignInPopup: SessionActions.CLOSE_SIGN_IN_POPUP,
      signIn: SessionActions.SIGN_IN,
      signOut: SessionActions.SIGN_OUT,
      getProfile: AccountActions.GET_PROFILE,
      updateUser: AccountActions.UPDATE_USER,
      userAutoLogin: SessionActions.USER_AUTO_LOGIN,
      userContentAutoLogin: SessionActions.USER_CONTENT_AUTO_LOGIN,
    });

    this.publicMethods = {
      getSessionUser: function () {
        // console.log("enter in get session user", window.location.pathname);
        return this.state._user;
      },
      isLoggedIn: function () {
        return !!this.state._user;
      },
    }
  }

  openSignInPopup(payload) {
    this.signInPopup = payload.signInPopup || false;
  };

  closeSignInPopup(payload) {
    this.signInPopup = payload.signInPopup || false;
  };

  loadSession(payload) {
    if (!payload.error && payload.user && payload.user.credentials) {
      if (!this._user || this._user.phone !== payload.user.credentials.phone) {
        this._user = payload.user.credentials;
      }
    }
  }

  signIn(payload) {
    if(!payload.error) {
      this.signInPopup = false;
      this._user = payload.user.credentials;

      if(!!this._user) window.location.href = "/";
    }
  }

  userAutoLogin(payload){
    if (!payload.error) {
      const {credentials} = payload.data;
      if(credentials){
        if(!this._user) this._user = credentials;
      }
    }
  }

  userContentAutoLogin(payload){
    if (!payload.error) {
      const {credentials} = payload.data;
      if(credentials){
        if(!this._user) this._user = credentials;
      }
    }
  }

  signOut(payload) {
    if (!payload.error) {
      delete this._user;
      window.location.href = '/';
    }
  }

  getProfile(payload) {
    if(!payload.error && payload.data) {
      const type = this._user.type;
      this._user = payload.data.user;
      this._user.type = type;
    }
  }

  updateUser(payload) {
    if(!payload.error) {
      const { user } = payload.data;
      if(user) this._user = user;
    }
  }
}

export default alt.createStore(SessionStore, 'SessionStore');
