'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class TagActions {

  addChildTag(payload, tagFor,cb){
    return(dispatch) => {
      http
        .post('/api/tags')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (typeof cb === 'function'){
            if(error) cb(error);
            else cb(null, res.body);
          } 

          dispatch({
            error: error,
            data: Object.assign({ tagFor: tagFor }, res.body)
          });
          if(error) return AlertActions.error(error);
          // AlertActions.success({ message: 'Tag added' });
        });
    }
  };

 
  
  // addInterpretedTag(payload,tagFor){
  //   return(dispatch) => {
  //     http
  //       .post('/api/itags')
  //       .send(payload)                                                                                                                                                                                                                                                                                                                              
  //       .end((err, res) => {
  //         var error = err || res.error ? ServerError(res) : null;
  //         dispatch({
  //           error: error,
  //           data: Object.assign({tagFor: tagFor},res.body)
  //         });
  //         if(error) return AlertActions.error(error);
  //         AlertActions.success({ message: 'Itag added' });
  //       });                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
  //   }
  // };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                

  //(delete/add) itags for a tag
  updateParentTagsInChild(payload,tagId){
    // let payload = {};
    // payload.parentTags = tag.parentTags || [];
    // if(tag.hasOwnProperty("newITag")) payload.newITag = tag.newITag;

    return(dispatch) => {
      http
        .put(`/api/tag/childTag/${tagId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          // tag.parentTags = res.body;
          // console.log("res.body============",res.body);
          dispatch({
            error: error,
            data: res.body
          });
          if(error) return AlertActions.error(error);
          // AlertActions.success({ message: "tag updated"})
        })
    }
  }


  getSuggestions(payload,tagFor){
    return(dispatch) => {
      http
        .get('/api/tags/suggestions')
        .query(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: Object.assign({tagFor:tagFor},res.body)
          });

          if (error) return AlertActions.error(error);
          // AlertActions.success({message: 'got some suggestions'});
        });
    }
  };

  getSuggestionsForCopiedFrom = (payload,tagFor) => {
    return (dispatch) => {
      http
        .get('/api/tags/suggestionsForCopiedFrom')                                                                                                                                                                              
        .query(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,                                       
            data: Object.assign({tagFor: tagFor}, res.body)
          });

          if (error) return AlertActions.error(error);
          // AlertActions.success({message: 'got tags suggestions'});
        });
    }
};


  search(payload, callBack) {
    return dispatch => {
      http
        .get('/api/tags/search')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    }
  };
  getUnCategorizeTag(cb){
    return dispatch => {
      http
        .get('/api/tags/get-uncategorize-tag')
        .query()
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          // dispatch({
          //   error: error,
          //   data: res.body
          // });
          if(error) cb(error);
          else cb(error, res.body);
          if(error) AlertActions.error({ message: error.message });
        });
    }
  }
  searchConfereceTags(payload){
    return dispatch => {
      http
        .get('/api/tags/search-conference-tags')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    }
  }
  getSimilarTags(payload, cb){
    return dispatch => {
      http
        .get('/api/tag/get-similar-tags')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === 'function'){
            if(error) cb(error);
            else cb(null, res.body);
          } 
          if(error) AlertActions.error({ message: error.message });
        });
    }
  }
  getCopiedFromQuestionOfTag(payload, cb){
    return dispatch => {
      http
        .get('/api/tags/get-copied-from-questions-of-tag')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === 'function'){
            if(error) cb(error);
            else cb(null, res.body);
          } 
          if(error) AlertActions.error({ message: error.message });
        });
    }
  }

  
  create(payload) {
    return dispatch => {
      http
        .post('/api/tags')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if(error) return AlertActions.error(error);
        });
    }
  };
  setTagCategory(payload, cb) {
    return dispatch => {
      http
        .post('/api/tags/set-tag-category')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          cb(error);
          if(error) return AlertActions.error(error);
        });
    }
  };

  
  deleteNewtag (){
    return (dispatch)=>{
      dispatch();
    }
  }

  getUntaggedConference (query, callBack) {
    return () => {
      http
        .get("/api/rating/untagged-conference")
        .query(query)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    };
  }

  createCombinationTag (payload, callBack) {
    return () => {
      http
        .post("/api/tags/comb-tag")
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (!error) AlertActions.success({ message: "successfully created" });
          else AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error);
        });
    };
  }

  updateCombinationTag (payload, callBack) {
    return () => {
      http
        .put("/api/tags/comb-tag")
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (!error) AlertActions.success({ message: "successfully updated" });
          else AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error);
        });
    };
  }

  getCombinationTags (callBack) {
    return () => {
      http
        .get("/api/tags/comb-tags")
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    };
  }

  updateReOrderedTags (payload, callBack) {
    return () => {
      http
        .put("/api/tags/comb-tags-order")
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (!error) AlertActions.success({ message: "successfully updated" });
          else AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error);
        });
    };
  }

  createNewFileForHomepage () {
    return () => {
      http
        .get("/api/combination-tags/create-new-file-for-homepage")
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (!error) AlertActions.success({ message: "Started script" });
          else AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    };
  }
  
  getMentorsFromCombTag (combTagId, callBack) {
    return () => {
      http
        .get(`/api/comb-tag/mentors/${combTagId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    };
  }

  getCombTagById (combTagId, callBack) {
    return () => {
      http
        .get(`/api/comb-tag/${combTagId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    };
  }

  makeAllCombTagsVisible (callBack) {
    return () => {
      http
        .put(`/api/combination-tags/make-all-comb-tags-visible`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "updated successfully" });
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    };
  }

  makeAllCombTagsHidden (callBack) {
    return () => {
      http
        .put(`/api/combination-tags/make-all-comb-tags-hidden`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "updated successfully" });
          if (typeof callBack === 'function') callBack(error, res.body);
        });
    };
  }

}

export default alt.createActions(TagActions);
