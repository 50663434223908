"use strict";

import alt from '../altInstance';

import BootcampActions from "../actions/BootcampActions";

class BootcampStore {
  constructor() {

    this.courseSuggestions = [];

    this.bindListeners({
      getAllCourses: BootcampActions.getAllCourses
    });
  }

  getAllCourses(payload) {
    const { error, data } = payload;
    if (!error) {
      const { courses } = data;
      this.courseSuggestions = courses;
    }
  }
}

export default alt.createStore(BootcampStore, 'BootcampStore');