"use strict";

import alt from '../altInstance';
import OrganizationActions from '../actions/OrganizationActions';

class OrganizationStore {
  constructor() {
    this._organization = null;
    this._organizationPopup = false;
    this._organizations = [];
    this.conferences = []; 
    this.ambassadors = [];
    this.jobs = [];

    this.bindListeners({
      showOrganizationPopup: OrganizationActions.SHOW_ORGANIZATION_POPUP,
      hideOrganizationPopup: OrganizationActions.HIDE_ORGANIZATION_POPUP,
      create: OrganizationActions.CREATE,
      update: OrganizationActions.UPDATE,
      fetchOrganizations: OrganizationActions.FETCH_ORGANIZATIONS,
      fetchInactiveOrganizations: OrganizationActions.FETCH_INACTIVE_ORGANIZATIONS,
      getOrganization:OrganizationActions.GET_ORGANIZATION,
      getConferences:OrganizationActions.GET_CONFERENCES,
      getAmbassadors : OrganizationActions.GET_AMBASSADORS,
      getJobs : OrganizationActions.GET_JOBS
    });

    this.publicMethods = {
      getOrganizations: () => {
        return this._organizations.filter(org =>  { return org.isActive });
      },

      getInactiveOrganizations: () => {
        return this._organizations.filter(org =>  { return !org.isActive });
      },

      createdOrganization: () => {
        return this._organization;
      },

      resetCreated: () => {
        this._organization = null;
      }
    }
  };

  showOrganizationPopup(payload) {
    this._organizationPopup = payload.organizationPopUp;
  };

  hideOrganizationPopup(payload) {
    this._organizationPopup = payload.organizationPopUp;
  };

  create(payload) {
    if(!payload.error) {
      const data = payload.data;

      this._organizations.push(data.organization);
      this._organization = data.organization;
    }
  };

  update(payload) {
    if(!payload.error) {
      const data = payload.data;

      this._organizations = this._organizations.map(org => {
        if(org._id === data.organization._id) {
          org = data.organization;
        }
        return org;
      });
    }
  };

  fetchOrganizations(payload) {
    if(!payload.error) {
      // this._organizations = this._organizations.concat(payload.data.organizations);
    }
  };

  fetchInactiveOrganizations(payload) {
    if(!payload.error) {
      this._organizations = this._organizations.concat(payload.data.organizations);
    }
  };
  getOrganization(payload){
    if(!payload.error){
      this._organization = payload.data.organization;
    }
  }
  getAmbassadors(payload){
    if(!payload.error){
      this.ambassadors = payload.data.items;
    }
  }
  getJobs(payload){
    if(!payload.error){
      this.jobs = payload.data;
    }
  }

  getConferences(payload){
    if(!payload.error){
      this.conferences = payload.data;
    }
  }
}


export default alt.createStore(OrganizationStore, 'OrganizationStore');
