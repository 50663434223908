"use strict";

export const urlParser = function(url) {

    let liId = '';
  
    if(url.indexOf('/in/')>-1){
      [,liId] = url.split('/in/');
      [liId] = liId.split('/');[liId] = liId.split('?');
      return {liId:liId, type:'user'};
    }
  
    if(url.indexOf('/company-beta/')>-1) {
      [,liId] = url.split('/company-beta/');
      [liId] = liId.split('/');
      [liId] = liId.split('?');
      return {liId:liId, type:'company'};
    }
    if(url.indexOf('/company/')>-1) {
      [,liId] = url.split('/company/');
      [liId] = liId.split('/');
      [liId] = liId.split('?');
      return {liId:liId, type:'company'};
    }
    if (url.indexOf('/edu/school/')>-1) {
      [,liId] = url.split('/edu/school/');
      [liId] = liId.split('/');
      [liId] = liId.split('?');
      return {liId: liId, type: 'school'};
    }
  
    if (url.indexOf('/school/')>-1) {
      [,liId] = url.split('/school/');
      [liId] = liId.split('/');
      [liId] = liId.split('?');
      return {liId: liId, type: 'school'};
    }
  
    console.log('failed to parse liId from url in liIdParser, probably url format changed');
    return null;
  };

export const createUrl = function (liId, type) {

  const baseUrl = 'https://www/linkedin.com';

  switch (type) {
    case 'school':
      return `${baseUrl}/edu/school/${liId || ''}`;
      break;
    case 'company':
      return `${baseUrl}/company-beta/${liId || ''}`;
      break;
    case 'user':
      return `${baseUrl}/in/user/${liId || ''}`;
      break;
    // default:
    //   return `${baseUrl}/${liId}`;
    //   break;
  }
};
  
