"use strict";

import Interceptor from 'superagent-intercept';
import SessionActions from '../actions/SessionActions';
import SessionStore from '../stores/SessionStore';

export let AuthInterceptor = Interceptor((err, res) => {
  if (res && res.status == 401 && SessionStore.getSessionUser()) {
    SessionActions.signOut();
  }
});
