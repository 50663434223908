'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';
import { copyFileSync } from 'fs';

class WidgetActions {
  getWidgets() {
    return dispatch => {
      http
        .get(`/api/get-widgets`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        });
    }
  }
  getWidgetById(widgetId) {
    return dispatch => {
      http
        .get(`/api/get-widget-by-id/${widgetId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);

        });
    }
  }
  createWidget(payload, cb) {
    return dispatch => {
      http
        .post('/api/create-widget')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          cb(error);
          if (error) return AlertActions.error(error);
          AlertActions.success({ message: 'Created Successfully!!!' });

        })
    }
  }
  updateWidget(widgetId, payload, cb) {
    return dispatch => {
      http
        .put(`/api/update-widget/${widgetId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          cb(error);
          if (error) return AlertActions.error(error);
          AlertActions.success({ message: 'updated Successfully!!!' });
        })
    }
  }
  getManagers(payload, cb) {
    return (dispatch) => {
      http
        .get(`/api/widget/get-managers/${payload.widgetId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body.managers
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  };
  deleteManager(payload, cb) {
    return (dispatch) => {
      http
        .delete(`/api/widget/delete-manager/${payload.widgetId}`)
        .send({ userId: payload.userId })
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body.msg
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  };

  addManager(payload, cb) {
    return (dispatch) => {
      // console.log(payload);
      http
        .put(`/api/widget/add-manager/${payload.widgetId}`)
        .send({ user: payload.user })
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body.managers
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  };


  getManagerWidgets(cb) {
    return (dispatch) => {
      http
        .get(`/api/manager/get-widgets`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body.results
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  };

  getWidgetApprovedQuestions(widgetId, payload, cb) {
    return (dispatch) => {
      http
        .get(`/api/widget/get-approved-questions/${widgetId}`)
        .query(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          // console.log(' body is ', res.body);
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  }

  setSettings(payload, cb) {
    const { showQuestionForMentorButton, showTalkToMentorButton, closePopupAfterSec, widgetId } = payload;
    return (dispatch) => {
      http
        .put(`/api/widget/edit-settings/${widgetId}`)
        .send({ showQuestionForMentorButton, showTalkToMentorButton, closePopupAfterSec })
        .end((err, res) => {
          let error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body.results
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  }

  answerToggleUpVote(payload) {
    return (dispatch) => {
      http
        .put('/api/answers')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          AlertActions.success({ message: 'upvote status ' + payload.upVoteStatus + 'sent for answerId ' + payload.answerId });
        });
    }
  }
  searchWidgetQuestions(payload) {

    return (dispatch) => {
      http
        .get('/api/manager/questions')
        .query(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          //AlertActions.success({ message: 'Q&A posted successfully' });
        });
    }
  }

  questionToggleUpVote(payload) {
    return (dispatch) => {
      http
        .put('/api/questions')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
          AlertActions.success({ message: 'QuestionActions upvote status ' + payload.upVoteStatus + 'sent for questionId ' + payload.questionId });
        });
    }
  };

  updateAnswer(payload) {
    return (dispatch) => {
      dispatch({
        data: { ans: payload }
      });
    }
  }
  deleteManagerQuestion(qid, cb) {
    return (dispatch) => {
      http
        .delete(`/api/questions/${qid}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
          //AlertActions.success({ message: 'Q&A posted successfully' });
        });
    }
  };
  approveWidgetQuestion(qid, status, cb) {
    return (dispatch) => {
      http
        .put(`/api/manager/questions/${qid}/status`)
        .send(status)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') {
            cb(error, res.body);
          }
        });
    }
  };

  getSettings(payload, cb) {
    return (dispatch) => {
      http
        .get(`/api/widget/get-settings/${payload.widgetId}`)
        .end((err, res) => {
          let error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body.results
          });
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  };

  getMentors(widgetId, cb) {
    return (dispatch) => {
      http
        .get(`/api/widget/get-mentors/${widgetId}`)
        .end((err, res) => {
          let error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          })
          if (error) {
            return AlertActions.error(error);
          }
          if (cb && typeof cb === 'function') {
            return (cb, res.body);
          }
        })
    }
  }
  updateWidgetMentorByManager(userId, payload) {
    return (dispatch) => {
      http
        .put(`/api/manager/update-user/${userId}`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error(error);
          // if (cb && typeof cb == 'function') {
          //   cb(error, res.body);
          // }
        });
    }
  };

  getManagerTrackLeads(path, query, cb) {
    return (dispatch) => {
      http
        .get(path)
        .query(query)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          // console.log("Response ..........", res.body);
          if (error) return AlertActions.error(error);
          if (cb && typeof cb === 'function') {
            return cb(error, res.body);
          }
        });
    }
  }

  getManagerAggregatedTrackLeadsReport(payload) {
    return dispatch => {
      http
        .get('/api/widget/get-aggregated-report')
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({ error, data: res.body });

          if (error) return AlertActions.error(error);
        });
    }
  }

  getManagerWidgetsOfTypeSpecific() {
    return (dispatch) => {
      http
        .get(`/api/manager/get-widgets-type-specific`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
        });
    }
  }

  getMentorsByWidget(widget) {
    return dispatch => {
      http
        .get(`/api/manager/get-mentors-by-widget/${widget}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({ error: error, data: res.body });

          if (error) return AlertActions.error(error);
        })
    }
  }

  resetWidgetMentors() {
    return true;
  }

  saveSchoolInUserAndJobInUser(payload, callback) {
    return (dispatch) => {
      http
        .put(`/api/manager/update-school-in-user-and-job-in-user`)
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          dispatch({ error: error, data: res.body });

          if (typeof callback === 'function') {
            callback();
          }
        });
    }
  }

  addUserInWidgetMentor = (payload, cb) => {
    return (dispatch) => {
      http
        .post('/api/widget-mentor/add')
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          dispatch({ err: err, data: res.body });

          if (cb && typeof cb == 'function') cb();
          AlertActions.success({ message: 'Mentor added' });

        });
    }
  }

  removeUserfromWidgetMentor = (payload, cb) => {
    return (dispatch) => {
      http
        .delete('/api/widget-mentor/remove')
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          dispatch({ err: err, data: res.body });

          if (cb && typeof cb == 'function') cb();
          AlertActions.success({ message: 'Mentor removed' });

        });
    }
  }

  /**
   * url -> /api/widget-mentor/ambassadors
   * 
   * @param {object} query contains from, to, widgetid for API 
   */

  getWidgetAmbassadors(query) {
    return dispatch => {
      http
        .get(`/api/widget-mentor/ambassadors`)
        .query(query)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          dispatch({ err: err, data: res.body });
        });
    }
  }

  updateWidgetPopoutFlag(payload, callback) {
    return dispatch => {
      http
        .put('/api/widget/popout')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          AlertActions.success({ message: "widget popout successfully updated" });

          dispatch({ err: err, data: res.body });
          if (typeof callback === "function") callback();
        })
    }
  }

  getOmpcConferencesOfWidget(query, callback) {
    return dispatch => {
      http
        .get('/api/widget-mentor/ompc-conferences')
        .query(query)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          dispatch({ err: err, data: res.body });

          if (typeof callback === "function") callback(error);
        })
    }
  }

  uploadFeedbackQstnFile(file, payload, callback) {
    return dispatch => {
      http
        .post('/api/widget/upload-feedback-qstn-file')
        .field('content-type', file.type)
        // .field('content-length', file.size)
        // .field('data', JSON.stringify(data))
        .attach('file', file, file.fileName)
        .field('questionFor', payload.questionFor)
        .field('questionType', payload.questionType)
        .field('widget', payload.widget)
        // .on('progress', progress => {
        //   dispatch({
        //     err: null,
        //     progress: Math.round(progress.percent)
        //   });
        // })
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error({ message: error.message });
          else callback(res)
          AlertActions.success({ message: 'File Uploaded' });
        })
    }
  }

  getFeedbackQstnFileList(query, callback) {
    console.log("query==========",query);
    return dispatch => {
      http
        .get('/api/widget/get-feedback-questions')
        .query(query)
        .end((err, res) => {
          if (res) return callback(err, res.body)
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof callback === "function") callback(error);
        })
    }
  }

  updateFeedbackQstnFile(file, payload, callback) {
    // file = file === null ? false : file
    // console.log(file)
    if (payload.isPresentFile === false) {
      return dispatch => {
        http
          .post('/api/widget/update-feedback-qstn-without-file')
          .send(payload)
          .end((err, res) => {
            const error = err || res.error ? ServerError(res) : null;
            if (error) AlertActions.error({ message: error.message });
            else callback(res)
            AlertActions.success({ message: 'File Updated' });
          })
      }
    }
    return dispatch => {
      http
        .post('/api/widget/update-feedback-qstn-with-file')
        .field('content-type', file.type)
        .attach('file', file, file.name)
        .field('questionFor', payload.questionFor)
        .field('questionType', payload.questionType)
        .field('widget', payload.widget)
        .field('text', payload.text)

        .field('fileId', payload.editingFileId)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error({ message: error.message });
          else callback(res)
          AlertActions.success({ message: 'File Updated' });
        })
    }
  }
  removeFeedbackAudioFile = (payload, cb) => {
    return (dispatch) => {
      http
        .delete('/api/widget/remove-feedback-qstn-file')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') cb();
          AlertActions.success({ message: 'File Deleted' });

        });
    }
  }
  getPendingUserRequest(query, callback) {
    return dispatch => {
      http
        .get(`/api/widget/pending-request-details`)
        .query(query)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof callback === "function") callback(res.body);
        });
    }
  }
  verifyPendingRequest = (payload, cb) => {
    return (dispatch) => {
      console.log('action log', payload);

      http
        .put(`/api/widget/approve-reject-request`)
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          dispatch({ err: err, data: res.body });

          if (cb && typeof cb == 'function') cb();
          AlertActions.success({ message: 'Request Updated' });

        });
    }
  }
  getWidgetChatbotOptions(callback) {
    console.log("query==========");
    return dispatch => {
      http
        .get('/api/widget/get-chatbot-options')
        .query()
        .end((err, res) => {
          if (res) return callback(err, res.body)
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (typeof callback === "function") callback(error);
        })
    }
  }
  addChatbotOptions = (payload, cb) => {
    return (dispatch) => {
      http
        .post('/api/widget/add-chatbot-option')
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          dispatch({ err: err, data: res.body });

          if (cb && typeof cb == 'function') cb();
          AlertActions.success({ message: 'Option added' });

        });
    }
  }
  updateChatbotOption = (id, payload, cb) => {
    return (dispatch) => {
      http
        .put(`/api/widget/update-chatbot-option/${id}`)
        .send(payload)
        .end((err, res) => {

          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);

          dispatch({ err: err, data: res.body });

          if (cb && typeof cb == 'function') cb();
          AlertActions.success({ message: 'Option Updated' });

        });
    }
  }
  deleteChatbotOption = (id, cb) => {
    return (dispatch) => {
      http
        .delete(`/api/widget/delete-chatbot-option/${id}`)
        .send()
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          if (cb && typeof cb == 'function') cb(err);
          AlertActions.success({ message: 'Option deleted!' });
        });
    }
  }
}






export default alt.createActions(WidgetActions);
