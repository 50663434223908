'use strict';

import alt from '../altInstance';
import http from "superagent";
import { ServerError } from "../helpers";
import AlertActions from "./AlertActions";

class PairWiseRatingActions {
  getConferenceDetails (query, callback) {
    return () => {
      http
        .get(`/api/pair-wise-rating-details`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: error.message || "An application error has occurred" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

  getRatings (query, callback) {
    return () => {
      http
        .get(`/api/pair-wise-rating`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: "An application error has been occurred" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

  submitPairWiseRating (payload, cb) {
    return () => {
      http
        .post('/api/pair-wise-rating')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          
          if (error)return AlertActions.error({ message: "An application error has occurred" });
          if (typeof cb === 'function') cb();
        })
    }
  }
  
  getUnratedConferences (query, callback) {
    return () => {
      http
        .get(`/api/rating/get-pair-of-unrated-conference`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: error.message || "An application error has occurred" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

  getReviewersAnalytics (callback) {
    return () => {
      http
        .get(`/api/rating/get-reviewers-analytics`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: error.message || "An application error has occurred" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

  getReviewerDetails (query, callback) {
    return () => {
      http
        .get(`/api/rating/get-reviewer-details`)
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: error.message || "An application error has occurred" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

}

export default alt.createActions(PairWiseRatingActions);
