"use strict";

const height_fixed_header = 51;
const speed = 250;
const moving_frequency = 15;

const internals = {};

let scrollToElement = (element, route) => {

  const hop_count = (speed - (speed % moving_frequency)) / moving_frequency, // Always make an integer
    getScrollTopDocumentAtBegin = internals.getScrollTopDocument(),
    gap = (internals.getScrollToElement(element) - getScrollTopDocumentAtBegin) / hop_count;

  if (window.history && typeof window.history.pushState === 'function') {
    window.history.pushState({}, undefined, route);
  }

  for (let i = 1; i <= hop_count; i++) {
    (function () {
      const hop_top_position = gap * i;
      setTimeout(function () {
        window.scrollTo(0, hop_top_position + getScrollTopDocumentAtBegin);
      }, moving_frequency * i);
    })();
  }

  return false;
};

export default scrollToElement;

internals.getScrollToElement = function (e) {

  let top = height_fixed_header * -1;

  while (e && e.offsetParent && e.offsetParent !== undefined && e.offsetParent !== null) {
    top += e.offsetTop + (e.clientTop !== null ? e.clientTop : 0);
    e = e.offsetParent;
  }

  return top;
};

internals.getScrollTopDocument = function () {
  return window.scrollY || window.pageYOffset;
};
