'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class TaskServerActions {
  getSpaceLeftOnVps(cb){
    return(dispatch) => {
      http
        .get('/api/task-server/space-lefts')
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
        //   dispatch({
        //     error: error,
        //     data: res.body
        //   });
          if (typeof cb === 'function') cb(res.body);

          if(error) return AlertActions.error(error);
        });
    }
  };
  cleanAndFreeServer(payload, cb){
    return(dispatch) => {
        http
          .post('/api/task-server/clean-free-vps')
          .send(payload)
          .end((err, res) => {
            var error = err || res.error ? ServerError(res) : null;
          //   dispatch({
          //     error: error,
          //     data: res.body
          //   });
            if (typeof cb === 'function') cb(res.body);
  
            if(error) return AlertActions.error(error);
            else return AlertActions.info({message : "Cleaned successfully!"});
          });
      }  
  }
  getReadyForDeployment(payload, cb){
    return(dispatch) => {
        http
          .post('/api/task-server/get-ready-for-deployment')
          .send(payload)
          .end((err, res) => {
            var error = err || res.error ? ServerError(res) : null;
          //   dispatch({
          //     error: error,
          //     data: res.body
          //   });
            if (typeof cb === 'function') cb(error);
  
            if(error) return AlertActions.error(error);
            else {
              if(payload.fResumeService){
                return AlertActions.info({message : "Service Resumed!"});
              }
              else{
                return AlertActions.info({message : "Marked unavailable!"});
              }
            }
          });
      }  
  }

  clearWinstonLogFile() {
    return() => {
      http
        .get('/api/winston/empty-log-file')
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          else AlertActions.success({ message: "Log file cleared" });
        });
    }
  }

}

export default alt.createActions(TaskServerActions);
