'use strict';

import alt from '../altInstance';
import http from "superagent";
import { ServerError } from "../helpers";
import AlertActions from "./AlertActions";

class OpenMultipartyActions {
  muteUnmute(payload) {
    console.log("enter in mute unmute action----",payload);
    return (dispatch) => {
      dispatch(payload);
    }
  }

  getTwilioToken (clientName, callback) {
    return dispatch => {
      http
        .get(`/token/${clientName}`)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: "Error fetching token" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

  callHostsWhoNeedToBeCalled (payload, cb) {
    return dispatch => {
      http
        .post('/api/twilio/call-hosts-that-need-to-be-called')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) {
            console.error('error', error);
            return AlertActions.error({ message: "Failed to get hosts who need to be called" });
          }
          console.log(res.body)
          if (typeof cb === 'function') {
            cb(res.body.shouldMentorJoinConferenceCall);
          }
          // if (typeof callback === 'function') {
          //   else {
          //     const { hosts } = res.body;
          //     cb(null, hosts);
          //   }
          // }
        })
    }
  }

  checkIfAnyHostOnline(conferenceId, callback){
    return (dispatch) => {
      http
        .put(`/api/twilio/check-if-any-host-online/${conferenceId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          callback(error, res);
          if(!error){
            // AlertActions.success({message: "Answers restored successfully!"});
          }
          // dispatch({
          //   error: error,
          //   user: res.body
          // });
          // if(error) AlertActions.error(error);
        });
    }
  }

  getJoinedParticipants (conferenceId, callback) {
    return dispatch => {
      http
        .get(`/api/twilio/get-joined-participants/${conferenceId}`)
        .end((err, res) => {

          const error = err || res.error ? ServerError(res) : null;
          
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: "Error fetching participants" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

  completeConference (conferenceId, callback) {
    return dispatch => {
      http
        .put(`/api/twilio/complete-conference`)
        .send({ conferenceId })
        .end((err, res) => {

          const error = err || res.error ? ServerError(res) : null;
          
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: "Error while ending conference" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

  disconnectMentorAndDropCalls (conference) {
    return () => {
      http
        .put(`/api/twilio/drop-hosts-call`)
        .send({ conference })
        .end((err, res) => {

          const error = err || res.error ? ServerError(res) : null;
          
          if (typeof callback === 'function') {
            if (error) {
              callback(error, null);
              AlertActions.error({ message: "Error while dropping host calls" });
            } 
            else {
              callback(null, res.body);
            }
          }
        });
    }
  }

}

export default alt.createActions(OpenMultipartyActions);
