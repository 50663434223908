import React, { Component } from "react";

export default function lazy(loader) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        Component: null,
      };
    }
    componentWillMount() {
      loader().then((m) => this.setState({ Component: m.default }));
    }
    render() {
      let Component = this.state.Component;
      if (Component) {
        return <Component {...this.props} />;
      }
      return null;
    }
  };
}
