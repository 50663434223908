"use strict";

import './Search- Bar.less';

import React from 'react';
import Navbar from 'react-bootstrap/lib/Navbar';

import Search from './SearchInput';
import { ConferenceActions } from '../../actions';
class SearchBar extends React.Component {
  constructor() {
    super();

    this.state = {
      searchText: '',
      hasFocus: true,
      users: [],
      organizations: []
    };

    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleSelection = this._handleSelection.bind(this);
    this._handleSearchStore = this._handleSearchStore.bind(this);
    this._handleAdvancedSelection = this._handleAdvancedSelection.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._hanlderedirectToUser = this._hanlderedirectToUser.bind(this);
  };

  static propTypes = {
    router: React.PropTypes.object
  };

  render() {
    const { searchText } = this.state;

    return(
      <Navbar.Form className="search-bar col">
        <Search type="advanced"
                value={ searchText }
                handleSubmit= {this._handleSubmit}
                onSelection={ this._handleSelection }
                onChange={ this._handleInputChange }
                onAdvancedSelection={ this._handleAdvancedSelection } />
      </Navbar.Form>
    );
  };

  _handleSearchStore(state) {
    
    this.setState({ users: state._users, organizations: state._organizations });
  };
   _hanlderedirectToUser (userName,userId){
    if(!userName && !userId) return;
    ConferenceActions.isUserContentAvailable(userId,(err,data)=>{
      if(!err){
        if(data.isContentAvailable){
          this.props.router.push(`/q/${userName}`);      
        }else{
          // Redirect to user profile
          this.props.router.push(`/p/${userName}`);
        }
      }
    })
  }
  _handleSelection(item) {
    if(item._type == 'organization'){
      this.props.router.push(`/o/${item._source.name}`);
    }
    else{
      this._hanlderedirectToUser(item._source.userName,item._id);
    }
  };
  _handleSubmit(value){
    this.props.router.push(`/search?searchTerm=${this.state.searchText}`);
  }
  _handleAdvancedSelection() {
    this.props.router.push(`/search?searchTerm=${this.state.searchText}`);
  };

  _handleInputChange(value) {
    this.setState({ searchText: value });
  };
}

export default SearchBar;
