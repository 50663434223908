export default [
    {
        name:'public',
        role:1,
    },
    {
        name:'user',
        role:2,
    },
    {
        name:'student',
        role:3,
    },
    {
        name:'host',
        role:4,
    },
    {
        name:'mentor',
        role:8,
    },
    {
        name:'admin',
        role:16,
    },
    {
        name:'super-admin',
        role:32,
    }
];