import React from 'react';
import {WidgetActions} from '../../actions';
import {WidgetStore} from '../../stores';
import {DropdownButton,MenuItem} from 'react-bootstrap';

class WidgetList extends React.Component {

  constructor(props) {
    super(props);
    console.log("props.defaultWidget=========",props.defaultWidget);
    this.state = {
      widgets: [],
      title: props.defaultWidget ? props.defaultWidget.name :  'Choose your widget'
    };

  }

  componentDidMount() {
    WidgetActions.getManagerWidgets();
    WidgetStore.listen(this._handleWidgetStore);
  }

  componentWillUnmount() {
    WidgetStore.unlisten(this._handleWidgetStore);
  }

  render() {
    let optionItems = this.state.widgets.map((widget,idx) =>
      <MenuItem
        key={idx}
        eventKey={widget._id}
        onSelect={this._handleSelection}
      >
        {widget.name}
      </MenuItem>
    );

    return (
      <div className="">
        <DropdownButton
          title={this.state.title}
          id='dropdown-basic-3'
          className=""
        >
          {optionItems}
        </DropdownButton>
      </div>
    );

  }


  _handleSelection = (eventKey) => {
    this.props.onSelection(eventKey);
    this.state.widgets.find(widget => {
      if(widget._id === eventKey){
        this.setState({title : widget.name});
      }
    })
  }

  _handleWidgetStore = (state) => {
    if (state._widgets) {
      this.setState({widgets: state._widgets});
    }
  }

}

export default WidgetList;


