"use strict";

import Alt from "../altInstance";
import http from "superagent";
import { ServerError } from "../helpers";
import AlertActions from "./AlertActions";

class CommHistoryActions {
  getEmailHistory(query) {
    return dispatch => {
      http
        .get("/api/communication-history")
        .query(query)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          if (error) return AlertActions.error(error);
      });
    };
  }

  receiveCheckEmail(payload, cb){
    return () => {
      http
        .post("/api/receive-check-outlook-email")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          cb(error);
        })
    }
  }

  deletePendingComm (payload, cb) {
    return () => {
      http
        .delete("/api/delete-pending-comm")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === "function") {
            cb(error, res.body);
          }
        })
    }
  }

  deletePastComm (payload, cb) {
    return () => {
      http
        .delete("/api/delete-past-comm")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (typeof cb === "function") {
            cb(error, res.body);
          }
        })
    }
  }

  getEmailsFromHistory(query, callback) {
    return () => {
      http
        .get("/api/communication-history/email-invite")
        .query(query)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          if (typeof callback == 'function') callback(error, res.body);
      });
    };
  }

  deletePendingCommOfUser (payload, callback) {
    return () => {
      http
        .delete("/api/conference-invite/remove-pending-invites")
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error(error);
          else AlertActions.success({ message: "Deleted Successfully" });
          if (typeof callback == 'function') callback(error, res.body);
        })
    }
  }
}

export default Alt.createActions(CommHistoryActions);
