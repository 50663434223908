"use strict";

import alt from '../altInstance';

import { ConferenceInviteActions } from '../actions';

class ConferenceInviteStore {

  constructor() {
    // this._templates = [];
    // this._hostPicCdn='';
    // this._hostVideoCdn='';
    // this._isBusy = false;
    // this._newTemplate = null;

    this._parentTemplate = "";
    this._parentTemplateList = [];
    this._mergedEmails = [];
    this._emailFormats = [];
    this._formatWithVars = {};
    // this._updatedEmailFormatIdxObj = {idx:-1, reps:0};
    this._resetCounter = 0;
    this._templateName = null;


    this.bindListeners({
      // updateEmail: ConferenceInviteActions.UPDATE_EMAIL,
      getEmailFormats:ConferenceInviteActions.GET_EMAIL_FORMATS,
      // getLiFormat : ConferenceInviteActions.GET_LI_FORMAT,
      updateEmailFormat:ConferenceInviteActions.UPDATE_EMAIL_FORMAT,
      resetEmailFormatComponent: ConferenceInviteActions.resetEmailFormatComponent,
      // updateLiFormat : ConferenceInviteActions.UPDATE_LI_FORMAT,
      getParentTemplateList : ConferenceInviteActions.GET_PARENT_TEMPLATE_LIST,
      // getMergedLiMessage : ConferenceInviteActions.GET_MERGED_LI_MESSAGE,
      // deleteLiPendingConnection : ConferenceInviteActions.deleteLiPendingConnection,
      // getPendingConfSendMsgList : ConferenceInviteActions.getPendingConfSendMsgList,
      getConferenceInviteStatus : ConferenceInviteActions.getConferenceInviteStatus,
      insertUpdateCommFormatTemplateVars : ConferenceInviteActions.insertUpdateCommFormatTemplateVars,
      deleteCommFormatTemplateVars : ConferenceInviteActions.deleteCommFormatTemplateVars
    });
    this.publicMethods = {
      updateSelectedTemplate : function(selectedTemplate){
        this.state.selectedTemplate = selectedTemplate;
        // console.log("this.state.selectedTemplate=======",this.state.selectedTemplate);
      }
    }  
  };

  updateEmail(payload) {
    if(!payload.error) {
      this.isEmailSubmitted = true;
    }
  };


  getParentTemplateList (payload){
    if(!payload.error && payload.data){
      // Make the object to show as per select component
      const {templateList} = payload.data;
      this._parentTemplateList = templateList.map(template =>{
        const {name, _id} = template;
        template.value = _id;
        const lIdx = name.lastIndexOf('_');
        template.label = lIdx > -1 ? name.substring(lIdx+1) : name;
        return template;
      });
    }
  }

  getEmailFormats(payload){
    // console.log("getEmailFormats selectedTemplate======",this.selectedTemplate);
    if(!payload.error && payload.data){
      const {data:{emailFormats=[], formatWithVars={}}} = payload;
      this._emailFormats = emailFormats;
      this._formatWithVars = formatWithVars;
    }
  }


  updateEmailFormat(payload){
    if(!payload.error && payload.data ) {
      let {data:{emailFormat, formatWithVars}} = payload;
      if(emailFormat){
        const emailFormats = JSON.parse(JSON.stringify(this._emailFormats));
        let idx = emailFormats.findIndex(e => e.commType === emailFormat.commType);
        emailFormat.events = emailFormats[idx].events;
        emailFormats[idx] = emailFormat;
        this._emailFormats = emailFormats;
        // console.log(this._emailFormats[idx])
        // if(idx === this._updatedEmailFormatIdxObj.idx){
        //   this._updatedEmailFormatIdxObj['reps']++;
        // } else {
        //   this._updatedEmailFormatIdxObj = {idx, reps:0}
        // }
      }
      if (formatWithVars){
        this._formatWithVars = formatWithVars;
      }
    }
  }
  insertUpdateCommFormatTemplateVars(payload){
    const {name, value, parentTemplate:{name:templateName} = {}} = payload.data.commFormatTemplateVar;
    if(templateName === this.selectedTemplate.name){
      this._formatWithVars[name] = {value, templateName};
    }
  }
  deleteCommFormatTemplateVars(payload){
    const {name, value, parentTemplate:{name:templateName} = {}} = payload.data.commFormatTemplateVar;
    this._formatWithVars[name] = {value, templateName};
  }
  resetEmailFormatComponent(){
    this._resetCounter++;
    // console.log(this._resetCounter);
  }
  // getPendingConfSendMsgList(payload){
  //   if(!payload.error && payload.data){
  //     const {pendingConfSendMsg} = payload.data;
  //     this._pendingConfSendMsg = pendingConfSendMsg;
  //   }
  // }
  getConferenceInviteStatus(payload){
    // console.log("payload==========",payload);
    if(!payload.error && payload.data){
      const {conferenceInviteStatues} = payload.data;
      this._conferenceInviteStatues = conferenceInviteStatues;
    }
  }
  // getMergedLiMessage(payload){
  //   if(!payload.error && payload.data){
  //     const {message, data, notParsedMessageVars} = payload.data;
  //     this._mergedLiMessage = message;
  //     this._notParsedLiMessageVars = notParsedMessageVars;
  //     this._mergedLiMessageData = data;
  //   }
  // }

  // deleteLiPendingConnection(payload){
  //   if(!payload.error){
  //     this._liPendingConnObj = null;
  //   }
  // }

  // updateLiFormat(payload){
  //   if(!payload.error && payload.data){
  //     this._liFormat = payload.data.liFormat;
  //   }
  // }

  // getLiFormat(payload){
  //   // console.log("payload============",payload);
  //   if(!payload.error && payload.data){
  //     this._liFormat = payload.data.liFormat;
  //   }
  // }
}

export default alt.createStore(ConferenceInviteStore, 'ConferenceInviteStore');
