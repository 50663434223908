"use strict";

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import {ServerError} from '../helpers';
import {AuthInterceptor} from '../utils';
import SessionStore from '../stores/SessionStore'
import {Roles} from '../constants/index';

import {browserHistory} from 'react-router';

class ConferenceInvitesAnalyticsActions { 

  getConferenceInviteAnalytics (query) {
    return (dispatch) => {
      http
        .get('/api/conference-invite-analytics')
        .query(query)
        .end((err, res) => {
          
          const error = err || res.error ? ServerError(res) : null;
          
          dispatch({ error: error, data: res.body });

          if (error) return AlertActions.error(error);
        })
    }
  }  

}

export default alt.createActions(ConferenceInvitesAnalyticsActions);