import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class AutoLoginAsUserActions {

 create(payload, callback){
    return(dispatch) => {
      http
        .post('/api/auto-login-url')
        .send(payload)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if(error) return AlertActions.error(error);
          if(callback && typeof callback == 'function'){
            callback(error, res.body);
          }
          AlertActions.success({ message: 'Link created successfully' });
        });
    }
  }

  getUrls () {
    return(dispatch) => {
      http
        .get('/api/auto-login-url')
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          dispatch({ error: error, data: res.body });

          if(error) return AlertActions.error(error);
        });
    }
  }

  verifyUrl (userCode) {
    return(dispatch) => {
      http
        .get('/api/verify-auto-login-url')
        .query({ userCode })
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          
          dispatch({ error: error, data: res.body });

          if(error) return AlertActions.error(error);
        });
    }
  }

  deleteUrl (recordId, callback) {
    return(dispatch) => {
      http
        .delete('/api/auto-login-url')
        .query({ recordId })
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;
          
          dispatch({ error: error, data: res.body });

          if(error) return AlertActions.error(error);
          AlertActions.success({ message: "successfully deleted" });

          if (typeof callback === 'function') callback();
        });
    }
  }

}

export default alt.createActions(AutoLoginAsUserActions);
