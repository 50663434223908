"use strict";

import './Typeahead.less';

import React from 'react';
import Menu from './Menu';
import MenuItem from './MenuItem'
import { SearchActions } from "../../../actions";
import { SearchStore } from '../../../stores';

class Typeahead extends React.Component {
  constructor() {
    super();

    this.state = {
      text: '',
      showMenu: false,
      selected: null
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleSelection = this._handleSelection.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this._handleBlur = this._handleBlur.bind(this);
    this._handleRenderMenu = this._handleRenderMenu.bind(this);
  };

  static propTypes = {
    onSubmit: React.PropTypes.func,
    placeHolder: React.PropTypes.string,
    searchResult: React.PropTypes.object,
    onInputChange: React.PropTypes.func,
    onChange: React.PropTypes.func,
    placeholder: React.PropTypes.string,
    renderMenu: React.PropTypes.func,
    labelKey: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.func]),
    highlight: React.PropTypes.bool
  };

  render() {
    const { text } = this.state;
    const { placeholder, onSubmit } = this.props;

    return(
      <form 
        className="typeahead-input"
        onFocus={ this._handleFocus }
        onBlur={ this._handleBlur }
        onSubmit={ onSubmit }
        noValidate
      >
      <i id="search-bar"
         onClick={() => SearchActions.changeLogoSizeOnNav("small")} 
         className="fa fa-search" style={{fontSize: '26px', color: '#666', cursor: 'pointer'}}></i>
        <input 
          id="search-bar"
          type="text"
          className=" form-control input-main"
          value={text}
          onClick={() => SearchActions.changeLogoSizeOnNav("small")}
          onChange={ this._handleChange }
          placeholder={ placeholder } 
          onBlur={() => SearchActions.changeLogoSizeOnNav("large")}
        />
        {this._handleRenderMenu()}
      </form>
    )
  };

  _handleChange(e) {
    const value = e.target.value;
    const onInputChange = this.props.onInputChange;

    this.setState({ text: value, selected: null }, () => {
      if(typeof onInputChange === 'function') onInputChange(value);
    });
  };

  _handleSelection(option) {
    // console.log('selection', option);
    this.setState({ text: this.props.labelKey(option), showMenu: false, selected: option }, () => {
      this.props.onChange(option);
    });
  };

  _handleFocus() {
    this.setState({ showMenu: true });
  };

  _handleBlur() {
    this.setState({ showMenu: false });
  };

  _handleRenderMenu() {
    if(typeof this.props.renderMenu === 'function') {
      return this.props.renderMenu(
        this.props.searchResult,
        { showMenu: this.state.showMenu },
        { onSelection: this._handleSelection, text: this.state.text, highlight: this.state.highlight }
      );
    }
  };
}

export default Typeahead;
