import alt from '../altInstance';
import AutoLoginAsUserActions from "../actions/AutoLoginAsUserActions.js"

class AutoLoginAsUserStore {
  constructor() {
    
    this.urls = [];
    this.urlStatus = null;

    this.bindListeners({
      create: AutoLoginAsUserActions.create,
      getUrls: AutoLoginAsUserActions.getUrls,
      verifyUrl: AutoLoginAsUserActions.verifyUrl
    });
  }

  create (payload) {
    if(!payload.error && payload.data) {
      console.log('payload.data', payload.data);
    }
  }

  getUrls (payload) {
    if (!payload.error && payload.data && payload.data.urls) {
      this.urls = payload.data.urls;
    }
  }

  verifyUrl (payload) {
    if(!payload.error && payload.data) {
      const { status } = payload.data;

      if(status === 'Not Found') this.urlStatus = 'Not Found';
      
      else if (status === 'Expired') this.urlStatus = 'Expired';
      
      else if (status === 'Verified') {
        this.urlStatus = 'Verified';
        window.location.href = "/";
      }

      else this.urlStatus = 'Error';
    }
  }

}

export default alt.createStore(AutoLoginAsUserStore, 'AutoLoginAsUserStore');