"use strict";

import alt from '../altInstance';

import AnswerActions from '../actions/AnswerActions';
import QuestionActions from '../actions/QuestionActions';

class AnswerStore {
  constructor() {
    this.answerPopup = false;
    this.answerPopupQuestionId = null;
    this.editAnswerPopup = null;
    this.answers = [];
    this.total = 0;
    this.aPgNo = {};
    this.answerIds = {};
    this.count = 0;
    const self = this;
    this.bindListeners({
      openAnswerPopup: AnswerActions.OPEN_ANSWER_POPUP,
      closeAnswerPopup: AnswerActions.CLOSE_ANSWER_POPUP,
      create: AnswerActions.CREATE,
      getAnswersByQuestionId: AnswerActions.GET_ANSWERS_BY_QUESTION_ID,
      answerCreated: AnswerActions.ANSWER_CREATED,
      answerDeleted :AnswerActions.ANSWER_DELETED,
      toggleUpVote: AnswerActions.TOGGLE_UP_VOTE,
      getQuestions: QuestionActions.GET_QUESTIONS,
      getQuestionById: QuestionActions.GET_QUESTION_BY_ID,
      createQA: QuestionActions.CREATE_QA,
      searchAnswers:AnswerActions.SEARCH_ANSWERS,
      
      deleteAnswer:AnswerActions.ANSWER_DELETED,
      approveAnswer: AnswerActions.APPROVE_ANSWER

    });

    this.publicMethods = {
      getPageNumberPublic: function(aType){
        console.log("this.state.aPgNo===========",this.state.aPgNo);
        console.log("aType===========",aType);
        console.log("seeMoreAnswerClicked===========",this.state.seeMoreAnswerClicked);
        if(!this.state.seeMoreAnswerClicked || JSON.parse(aType).hasOwnProperty("qId") && !this.state.aPgNo[aType] ){
          return null
        }else{
          return this.state.aPgNo[aType]
        }
      },
      getAnswersByQuestionIdPublic: function(questionId){
        return this.state.answers.filter(ans =>ans.question === questionId);
      },
      getAnswers4QTypePublic: function(qType){
        let answerIds = this.state.answerIds[qType];
        if(answerIds){
          answerIds.forEach(ans => {
            let a = this.state.answers.filter(a => a._id=== ans._id);
            a ? this.state.answers = this.state.answers.concat(a) : "" ;
          })
        }
      },
    }
  }

  openAnswerPopup(payload) {
    this.answerPopup = payload.answerPopup || false;
    this.answerPopupQuestionId = payload.questionId || null;
    this.editAnswerPopup = payload.editAnswerPopup || null;
  };

  closeAnswerPopup(payload) {
    this.answerPopup = payload.answerPopup || false;
  };

  create(payload) {
    //todo 1) show added answer differently from other answers; 2) show message that the answer is pending approval;
    if(!payload.error){
      this.answerPopup = false;
      if(payload.data.type ==='created') {
        this.answers.unshift(payload.data.answer);
      } else {
        let idx = this.answers.findIndex(a => a._id === payload.data.answer._id);
        if(idx>-1){
          this.answers[idx].text = payload.data.answer.text;
          this.answers[idx].tags = payload.data.answer.tags;
          this.answers[idx].isAnonymous = payload.data.answer.isAnonymous;
          this.answers[idx].timestamp = Date.now();
        }
      }
    }
  };

  createQA(payload){
    if(!payload.error && payload.data && payload.data.answer){
      this.answers.unshift(payload.data.answer);
    }
  };

  getAnswersByQuestionId(payload) {
    if(!payload.error) {
      let aType =JSON.stringify({"qId":payload.data.questionId});
      console.log("aType=========",aType);
      if(this.seeMoreAnswerClicked){
        if(payload.data && payload.data.answers){
          payload.data.answers.forEach(ans => {
            let idx = this.answers.findIndex(a=>a._id=== ans._id);
            idx === -1 ? this.answers.push(ans)  : this.answers[idx] = ans ;
            !this.answerIds[aType] ? this.answerIds[aType]=[]: "";
            idx = this.answerIds[aType].indexOf(ans._id);
            idx===-1 ? this.answerIds[aType].push(ans._id): ""
          })
        }  
      }
      else{
        this.answers = [];
        this.seeMoreAnswerClicked = true;
        this.answerIds[aType]=[];
        if(payload.data && payload.data.answers){
          payload.data.answers.forEach(ans => {
            this.answers.push(ans);
            this.answerIds[aType].push(ans._id);
          })
        } 
      }
      !this.aPgNo[aType] ? this.aPgNo[aType] =1 : this.aPgNo[aType]++;
      // console.log("this.aPgNo===========", this.aPgNo);

    }
  };
  answerCreated(payload){
    this.getAnswersByQuestionId(payload);
  }
  getQuestions(payload) {
    if(!payload.error) {
      this.seeMoreAnswerClicked = false;
      let aType = payload.data.qType;
      if (payload.data && payload.data.answers){
        let {answers} = payload.data;
        answers.forEach(ans => {
          let idx = this.answers.findIndex(a=>a._id === ans._id);
          (idx===-1) ? this.answers.push(ans): this.answers[idx]=ans
          !this.answerIds[aType] ? this.answerIds[aType]=[]: ""
          idx = this.answerIds[aType].indexOf(ans._id);
          idx===-1 ? this.answerIds[aType].push(ans._id): ""
        })
      }
    }
  };

  getQuestionById(payload) {
    console.log("enter in getQuestionById---------",payload);
    if(!payload.error){
      this.seeMoreAnswerClicked = true;
      let aType =JSON.stringify({"qId":payload.data.questionId});
      if( payload.data && payload.data.answers){
        payload.data.answers.forEach(ans => {
          let idx = this.answers.findIndex(a=>a._id=== ans._id);
          idx === -1 ? this.answers.push(ans)  : this.answers[idx] = ans ;
          !this.answerIds[aType] ? this.answerIds[aType]=[]: ""
          idx = this.answerIds[aType].indexOf(ans._id);
          idx===-1 ? this.answerIds[aType].push(ans._id): ""
        })
      }
      !this.aPgNo[aType] ? this.aPgNo[aType] =1 : this.aPgNo[aType]++;
      // console.log("this.aPgNo===========", this.aPgNo);
    }
  };

  toggleUpVote(payload){
    // console.log("payload--------",payload);
    if(!payload.error) {
      let answerId = payload.data && payload.data.answerId;
      let upVoteStatus = payload.data && payload.data.upVoteStatus;
      let userId = payload.data && payload.data.userId;
      let index = this.answers.findIndex(a=>a._id=== answerId);
      // console.log("index--------",index);
      // console.log("this.answers[index]-------",this.answers && this.answers[index]);
      if(index>-1){
        this.answers[index].upVoteStatus = upVoteStatus;
        ! Array.isArray(this.answers[index].upVotes)
          ? this.answers[index].upVotes = [] : "";

        const upVoteIdx = this.answers[index].upVotes.indexOf(userId);
        upVoteStatus && upVoteIdx ===-1
          ? this.answers[index].upVotes.push(userId)
          : null;
        !upVoteStatus && upVoteIdx >-1
          ? this.answers[index].upVotes.splice(upVoteIdx, 1)
          : null;
      }
    }
  };
  answerDeleted (payload){
    let answerId = payload.data.answerId;
    let index = this.answers.findIndex(a=> a._id === answerId);
    this.answers.splice(index,1);
    for(let key in this.answerIds){
      let answersArray = this.answerIds[key];
      let index = answersArray.indexOf(answerId);
      if(index > -1){
        answersArray.splice(index,1);
        this.answerIds[key] = answersArray;
        break;
      }
    }
  }
  searchAnswers(payload){
    if(!payload.error && payload.data){
      // console.log("payload is....", payload.data);
      const {answers,total} = payload.data;
      this.answers = answers
      this.total = total;
      
    }
  }
  deleteAnswer(payload){
    if(!payload.error && payload.data){
      let idx = this.answers.findIndex(a=>a._id === payload.data.answer);
      if(idx>-1){
        this.answers.splice(idx,1);
      }
      
    }
  }
 
  
  approveAnswer(payload){
    if(!payload.error && payload.data){
      let idx = this.answers.findIndex(a=> a._id=== payload.data.answer._id);
      if(idx>-1){
        this.answers.splice(idx,1);
      }
      
    }
  }
  
  
}

export default alt.createStore(AnswerStore, 'AnswerStore');
