'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class ContentActions {
  getUserContent(payload,callback){
    return(dispatch) => {
      http
        .get('/api/content/user')
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });
          // console.log("res.body=============",res.body);
          if(callback){
            callback(error,res.body);
          }
          
          if (error) return AlertActions.error(error);
        });
    }
  };

  getUserProfile(payload) {
    return dispatch => {
      http
        .get('/api/content/profile')
        .query(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;

          dispatch({
            error: error,
            data: res.body
          });

          if (error) return AlertActions.error({ message: error });
        })
    }
  }
  hideShowConf(payload){
    return(dispatch)=>{
      http
        .put(`/api/show-hide-conference`)
        .send(payload)
        .end((err, res)=>{
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error:error,
            data:res.body
          });

          if(error) return AlertActions.error(error);
          // AlertActions.success({ message: "Updated Successfully" })
        })
    }
  };

  answerCreated(payload){
    return (dispatch) => {
      dispatch(payload);
    }    
  }
  conferenceProcessed(payload){
    return (dispatch) => {
      dispatch(payload);
    } 
  }
  answerEditPopup(payload){
    return (dispatch) => {
      dispatch(payload);
    } 
  }

  changeTranscriptionVisibility(payload, callback) {
    return () => {
      http
        .put('/api/content/transcription-visibility')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) AlertActions.error({ message: "An intenral has occurred" });
          else AlertActions.success({ message: "Successfully updated." });
          if (typeof callback === "function") callback(error, res.body);
        })
    }
  }

  submitGetInTouch(payload, callback) {
    return () => {
      http
        .post('/api/about-us/get-in-touch')
        .send(payload)
        .end((err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          if (error) return AlertActions.error(error);
          AlertActions.success({ message: "We will get back to you soon." });
          if (typeof callback === "function") callback();
        });
    }
  }
  
}

export default alt.createActions(ContentActions);
