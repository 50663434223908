import React from "react";
import './Upload-Feedback-Qstn-File.css';
// import moment from "moment-timezone";
import { WidgetActions } from "../../../actions";
import UploadedFileList from './Uploaded-File-List'
// import UploadQstnFileModal from './Upload-Feedback-Qstn-Modal'
import UploadQstnFileModal from './Upload-Feedback-Qstn-Modal'
import WidgetList from '../../../components/WidgetList';

export default class QuestionFeedbackUploadFile extends React.Component {

  constructor(props) {
    super(props)
    this.input = null;
    this.state = {
      file: null,
      fileList: [],
      isUploadingFile: false,
      isEditingFile: false,
      questionType: '',
      questionFor: '',
      editingFileId: ''
    }
  }

  loadFeedbackQstnFile = (query={}) => {
    WidgetActions.getFeedbackQstnFileList(query, (err, data) => {
      this.setState({ fileList: data.feedbackQuestions, selectedWidget : data.selectedWidget})
    })
  }

  onDeleteFile = (fileId) => {
    let paylaod = {
      fileId
    }
    WidgetActions.removeFeedbackAudioFile(paylaod, () => {
      this.loadFeedbackQstnFile()
    })
  }

  componentDidMount() {
    this.loadFeedbackQstnFile()
  }

  validateInputs = (file, questionFor, questionType, widget) => {
    let validationFailed = false;
    if (file === null && this.state.isEditingFile === false) {
      alert("Please choose a file");
      validationFailed = true;
    }
    else if (questionType === '' && questionFor === '') {
      alert('Please select type and question for');
      validationFailed = true;
    }
    else if (questionType === '') {
      alert('Please select what type of question is this');
      validationFailed = true;
    }
    else if (questionFor === '') {
      alert('Please select whom is this question for');
      validationFailed = true;
    }
    else if (widget === '') {
      alert('Please select widget');
      validationFailed = true;
    }
    return validationFailed
  }

  onFileUpload = (file, questionFor, questionType, widget, text) => {
    if (this.validateInputs(file, questionFor, questionType, widget, text)) {
      return;
    }
    const {selectedWidget, editingFileId, isEditingFile} = this.state;
    console.log("enter in onfileupload-------",text);
    console.log("file-------",file);

    let payload = {
      questionFor,
      questionType,
      widget : widget._id,
      text,
      editingFileId,
      isEditingFile,
    }
    console.log("isEditingFile-------",isEditingFile);
    
    if (isEditingFile === true) {
      (file === null) ? (payload.isPresentFile = false) : payload.isPresentFile = true
      console.log("payload.isPresentFile-------",payload.isPresentFile);

      WidgetActions.updateFeedbackQstnFile(file, payload, (res) => {
        this.setState({ isUploadingFile: false }, () => this.loadFeedbackQstnFile({widget : selectedWidget._id}))
      });
    } else {
      WidgetActions.uploadFeedbackQstnFile(file, payload, (res) => {
        this.setState({ isUploadingFile: false }, () => this.loadFeedbackQstnFile({widget : selectedWidget._id}))
      });
    }
  }

  onEditFile = (editingFileId, questionFor, questionType) => {
    this.setState({ isEditingFile: true, isUploadingFile: true, editingFileId, questionType, questionFor })
  }

  onClosePopup = () => {
    this.setState({ isUploadingFile: false, isEditingFile: false, editingFileId: null });
  }

  manageUploadPopup = () => {
    this.setState({ isUploadingFile: !this.state.isUploadingFile })
  }

  render() {
    let { fileList, editingFileId, selectedWidget } = this.state;
    let fileToBeEdited = null;
    if (editingFileId) {
      fileToBeEdited = fileList.find(file => file._id === editingFileId);
    }
    return (
      <div className="mt30">
        {this._showModal(fileToBeEdited)}
        <button button className="btn btn-primary pull-right mb10" onClick={this.manageUploadPopup}>Add File</button>
        {
          selectedWidget &&
          <WidgetList
            defaultWidget ={selectedWidget}
            onSelection={this._handleWidgetSelection}
          />
        }
        
        {this._showList()}
      </div>
    )
  }

  _showModal = (fileToBeEdited) => {
    console.log("fileToBeEdited=========",fileToBeEdited);
    const { isUploadingFile } = this.state;
    if (!isUploadingFile) return null;

    return (
      <UploadQstnFileModal
        fileToBeEdited={fileToBeEdited}
        onClosePopup={this.onClosePopup}
        onFileChange={this.onFileChange}
        onFileUpload={this.onFileUpload}
      />
    )
  }

  _handleWidgetSelection = (widget)=>{
    console.log("enter in _handleWidgetSelection",widget);
    this.loadFeedbackQstnFile({widget});
  }

  _showList = () => {
    const { fileList = [] } = this.state;
    return (
      <UploadedFileList
        onDeleteFile={this.onDeleteFile}
        onEditFile={this.onEditFile}
        fileList={fileList}
      />
    );
  }

}

