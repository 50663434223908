"use strict";

import Alt from '../altInstance';
import Http from 'superagent';

import { ServerError } from '../helpers';
import AlertActions from './AlertActions';

class OrganizationActions {
  showOrganizationPopup() {
    return dispatch => {
      dispatch({ organizationPopUp: true });
    };
  };

  hideOrganizationPopup() {
    return dispatch => {
      dispatch({ organizationPopUp: false });
    };
  };

  create(payload,file, cb) {
    return dispatch => {
        if(file){
          Http
          .post('/api/organizations')
          .field('content-type', file.type)
          .field('content-length', file.size)
          .field('data', JSON.stringify(payload))
          .attach('file', file, file.fileName)
          .on('progress', progress => {
          })
          .end((err, res) => {
            const error = err || res.error ? ServerError(res) : null;
            dispatch({
              err: error,
              data: res.body
            });
            if(typeof cb === 'function') cb(error);
            if(error) AlertActions.error({ message: error.message });
          })  
        }
        else{
          Http
            .post('/api/organizations')
            .field('data', JSON.stringify(payload))
            .end((err, res) => {
              const error = err || res.error ? ServerError(res) : null;
              dispatch({
                error: error,
                data: res.body
              });

              if(typeof cb === 'function') cb(error);
            });
      }
    }
  };

  update(payload, file, id, cb) {
    return dispatch => {
      if (file) {
        Http
          .put('/api/organizations/'+id)
          .field('content-type', file.type)
          .field('content-length', file.size)
          .field('data', JSON.stringify(payload))
          .attach('file', file, file.fileName)
          .end((err, res) => {
            const error = err || res.error ? ServerError(res) : null;
            dispatch({ err: error, data: res.body });
            if (typeof cb === 'function') cb(error);
            if (error) AlertActions.error({ message: error.message });
          })
      }
      else {
        Http
          .put('/api/organizations/'+id)
          .field('data', JSON.stringify(payload))
          .end((err, res) => {
            const error = err || res.error ? ServerError(res) : null;
            dispatch({ error: error, data: res.body });
            if (typeof cb === 'function') cb(error);
          });
      }
    }
  };

  getOrganization(payload){
    return dispatch =>{
      Http
      .get(`/api/organizations/${payload.name}`)
      .end((err,res) => {
        const error = err|| res.error?ServerError(res):null;
        dispatch({
          error:error,
          data:res.body
        })
        if(error) 
        AlertActions.error({message: error.message});
        if(!res.body.organization)
        AlertActions.error({message:'OOPs! The organization you\'re looking for isn\'t registered with us'})
        
      })
    }
   
    
      
  }
  getAmbassadors(organization,payload){
    // hardcoded for now, only for testing purposes
    let filter = {
      widgetId:"59d2f4b6396feb0d4fddc3f3",
      limit:10
    }
    if (payload && payload.widgetName) {
      filter.widgetId = '';
      filter.widgetName = payload.widgetName;
    }
    return dispatch => {
      Http
        .get(`/api/organizations/${organization.name}/ambassadors`)
        .query(filter)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  }

  getJobs(organization){
    return dispatch=>{
      Http
        .get('#')
        .query(organization)
        .end((err, res) =>{
          const error = err|| res.error?ServerError(res):null;
          dispatch({
            error:error,
            data:res.body
          })
          if(error) AlertActions.error({message:error.message});
        
        })
      }
  }

  getConferences(organization){
  
    return dispatch=>{
    Http
      .get(`/api/organization/${organization.name}/conferences`)
      .end((err, res) =>{
        const error = err|| res.error?ServerError(res):null;
        dispatch({
          error:error,
          data:res.body
        })
        if(error) AlertActions.error({message:error.message});
        else{
        }
      })
    }
  }

  fetchOrganizations(payload) {
    return dispatch => {
      Http
        .get('/api/organizations')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };

  fetchInactiveOrganizations(payload) {
    return dispatch => {
      Http
        .get('/api/organizations/inactive')
        .query(payload)
        .end( (err, res) => {
          const error = err || res.error ? ServerError(res) : null;
          dispatch({
            error: error,
            data: res.body
          });

          if(error) AlertActions.error({ message: error.message });
        });
    };
  };
}

export default Alt.createActions(OrganizationActions)
