import "./assets/styles/app.less";
import "babel-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import {
  Router,
  Route,
  IndexRoute,
  Redirect,
  browserHistory,
} from "react-router";

import { redirectToDashboard, redirectToLogin } from "./helpers/route-helper";
import RestrictedContainer from "./components/RestrictedContainer";
import AppContainer from "./components/App-Container";

import QuestionFeedbackUploadFile from "./views/Admin/Upload-Feedback-Qstn-File";

// function loadRoute(cb) {
//   return module => cb(null, module.default);
// }
//
// function errorLoading(error) {
//   throw new Error(`Dynamic page loading failed: ${error}`);
// }

ReactDOM.render(
  <Router history={browserHistory}>
    <Route path="/" component={AppContainer}>
      <IndexRoute
        onEnter={redirectToDashboard}
        getComponent={(location, cb) => {
          import("./views/Public/Home-Page").then((m) => cb(null, m.default));
        }}
      />

      {/*--------------------------------------------------PUBLIC----------------------------------------------------------------------*/}
      <Route
        path="/doc-formatter-by-ms"
        getComponent={(location, cb) => {
          import("./views/Public/DocFormatterByMs").then((m) =>
            cb(null, m.default)
          );
        }}
      />

      <Route
        path="/terms-and-conditions"
        getComponent={(location, cb) => {
          import("./views/Public/Terms").then((m) => cb(null, m.default));
        }}
      />
      <Route
        path="/about-us"
        getComponent={(location, cb) => {
          import("./views/Public/AboutUs").then((m) => cb(null, m.default));
        }}
      />
      <Route
        path="/blog"
        getComponent={(location, cb) => {
          import("./views/Public/Blogs").then((m) => cb(null, m.default));
        }}
      />
      <Route
        path="/blog/:slug"
        getComponent={(location, cb) => {
          import("./views/Public/Blog-Contents").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/privacy-policy"
        getComponent={(location, cb) => {
          import("./views/Public/Privacy").then((m) => cb(null, m.default));
        }}
      />

      <Route
        path="/widget/:widgetName"
        getComponent={(location, cb) => {
          import("./views/Public/Widget").then((m) => cb(null, m.default));
        }}
      />
      <Route
        path="/widget/:widgetId/qa"
        getComponent={(location, cb) => {
          import("./views/Public/WidgetQ&A").then((m) => cb(null, m.default));
        }}
      />

      <Route
        path="/videos"
        getComponent={(location, cb) => {
          import("./views/Public/Videos").then((m) => cb(null, m.default));
        }}
      />

      <Redirect from="/org/:orgname" to="/o/:orgname" />
      <Route
        path="/o/:orgname"
        getComponent={(location, cb) => {
          import("./views/Public/Org").then((m) => cb(null, m.default));
        }}
      />

      <Route
        path="/question-feed"
        getComponent={(location, cb) => {
          import("./views/Public/Question-Feed").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/question/:id"
        getComponent={(location, cb) => {
          import("./views/Public/Question").then((m) => cb(null, m.default));
        }}
      />
      {/* <Redirect from="/user/content/:id" to="/q/:id" /> */}
      <Route
        path="/q-ms/:id"
        getComponent={(location, cb) => {
          import("./views/Public/User-Content").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/user/content/:id"
        getComponent={(location, cb) => {
          import("./views/Public/User-Content").then((m) =>
            cb(null, m.default)
          );
        }}
      />

      <Route
        path="/p/:username"
        getComponent={(location, cb) => {
          import("./views/Public/Profile").then((m) => cb(null, m.default));
        }}
      />
      <Redirect from="/user/profile/:username" to="/p/:username" />

      <Route
        path="/user/response/recorded"
        getComponent={(location, cb) => {
          import("./views/User/Response-Page").then((m) => cb(null, m.default));
        }}
      />

      <Route
        path="/pt/:token"
        getComponent={(location, cb) => {
          import("./views/User/Profile-Token-Page").then((m) =>
            cb(null, m.default)
          );
        }}
      />

      <Route
        path="/ct/:token"
        getComponent={(location, cb) => {
          import("./views/User/Profile-Token-Page").then((m) =>
            cb(null, m.default)
          );
        }}
      />

      <Route
        path="/search"
        getComponent={(location, cb) => {
          import("./views/Public/Search").then((m) => cb(null, m.default));
        }}
      />

      <Route
        path="/l/:confCode"
        getComponent={(location, cb) => {
          import("./views/Public/Question-Link").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/wl/:confCode"
        getComponent={(location, cb) => {
          import("./views/Public/Widget-Question-Link").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/i/:widgetAlias"
        getComponent={(location, cb) => {
          import("./views/Public/Hosted-Conf-Invitation").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      {/*the route below is for conf invite process that was created earlier*/}
      {/*<Route*/}
      {/*path="/user/questions/:questCode"*/}
      {/*getComponent={(location, cb) => {*/}
      {/*import('./views/User/Question-Page').then((m) => cb(null, m.default));*/}
      {/*}}*/}
      {/*/>*/}

      <Route
        path="/r/:type/:confCode"
        getComponent={(location, cb) => {
          import("./views/Public/User-Auto-Login").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/feedback/:confCode"
        getComponent={(location, cb) => {
          import("./views/Public/Feedback-Questions").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/auto-q/:autoLoginCode"
        getComponent={(location, cb) => {
          import("./views/Public/User-Content-Auto-Login").then((m) =>
            cb(null, m.default)
          );
        }}
      />

      <Route
        path="/cd-to-ms/:sessId/:redirect"
        getComponent={(location, cb) => {
          import("./views/Public/Cd-To-Ms").then((m) => cb(null, m.default));
        }}
      />

      <Route
        path="/auto-login-as-user/:userCode"
        getComponent={(location, cb) => {
          import("./views/Public/Auto-Login-As-User").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/email-verification-landing-page/:status"
        getComponent={(location, cb) => {
          import("./views/Public/Email-Verification-Landing-Page").then((m) =>
            cb(null, m.default)
          );
        }}
      />
      <Route
        path="/cert-id/:certId"
        getComponent={(location, cb) => {
          import("./views/Public/CertificateVerification").then((m) =>
            cb(null, m.default)
          );
        }}
      />

      {/*-----------------------------------------------NOT LOGGED IN------------------------------------------------------------------*/}

      <Route authorize={[1]} component={RestrictedContainer}>
        <Route
          path="/reset-password/:email/:token"
          getComponent={(location, cb) => {
            import("./views/Public/Reset-Password").then((m) =>
              cb(null, m.default)
            );
          }}
        />
      </Route>

      {/*----------------------------------------------------COMMON----------------------------------------------------------------------*/}

      <Route authorize={[2, 4, 8, 16, 32]} component={RestrictedContainer}>
        <Route
          path="/user/dashboard"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/User/Dashboard").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/user/p"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/User/Profile").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/user/settings"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/User/Account-Settings").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/user/widgetadmin"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/User/Widget-Admin-Dashboard").then((m) =>
              cb(null, m.default)
            );
          }}
        />
      </Route>

      {/*----------------------------------------------------CONFERENCE----------------------------------------------------------------------*/}

      <Route authorize={[2, 4, 8, 16, 32]} component={RestrictedContainer}>
        <Route
          path="/user/conferences"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/User-Dashboard").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/user-conference/add-to-calendar/:id"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Add-To-Calender").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/mh/:confCode" // /mh/qLinkCode
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Live-Conference/Mentor-Conference").then(
              (m) => cb(null, m.default)
            );
          }}
        />
        <Route
          path="/ms/:confCode"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Self-Video/Wrapper").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/msw/:confCode"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Answer-Widget-Question/Wrapper").then(
              (m) => cb(null, m.default)
            );
          }}
        />

        <Route
          path="/user/conferences/:type/:id"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Open-Multiparty/Wrapper").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        {/* Below url are based on the role in conference not on system role*/}
        {/* <Route path="/mentor/host-record/:id" // /mh/qLinkCode */}
        <Route
          path="/host/conferences/:id"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Live-Conference/Host-Conference").then(
              (m) => cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/conferences/:id"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Live-Conference/Admin-Conference").then(
              (m) => cb(null, m.default)
            );
          }}
        />

        <Route
          path="/phone-verification/:key/:otpOrWebsiteUrl/:phone/(:widgetId)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Phone-Verification").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/ompc-audio/:conferenceId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import(
              "./views/Conference/Open-Multiparty-audio/Open-Multiparty"
            ).then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/ompc-audio-phone/:conferenceId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import(
              "./views/Conference/Open-Multiparty-audio/Open-Multiparty-Phone"
            ).then((m) => cb(null, m.default));
          }}
        />

        {/* <Route
        path="/ompc-audio-parking/:conferenceId"
        onEnter={redirectToLogin}
        getComponent={(location, cb) => {
          import('./views/Conference/Open-Multiparty-audio/Ompc-Parking').then((m) => cb(null, m.default));
        }}
      /> */}
        <Route
          path="/admin-raw-input/:conferenceId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Admin-Raw-Input").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        {/* <Route path="/mentor/self-record/:id" // /ms/qLinkCode */}
      </Route>
      {/*----------------------------------------------------Student----------------------------------------------------------------------*/}
      <Route authorize={[3]} component={RestrictedContainer}>
        <Route
          path="/bootcamp/assignment-page(/:assignmentId)"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Bootcamp/Assignment-Page/Assignment-Page").then(
              (m) => cb(null, m.default)
            );
          }}
        />
        <Route
          path="/bootcamp/student-dashboard"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Bootcamp/Student-Dashboard").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/bootcamp/user-courses"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Bootcamp/User-Course/User-Course").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/bootcamp/group-members-review/:assignment"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Bootcamp/Group-Members-Review").then((m) =>
              cb(null, m.default)
            );
          }}
        />
      </Route>

      {/*----------------------------------------------------MENTOR----------------------------------------------------------------------*/}
      <Route authorize={[4]} component={RestrictedContainer}>
        <Route
          path="/host-availability"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/User/Host-Availablity").then((m) =>
              cb(null, m.default)
            );
          }}
        />
      </Route>
      <Route
        path="/user/interview-questions/:confCode"
        onEnter={redirectToLogin}
        getComponent={(location, cb) => {
          import("./views/User/interview-questions/interview-questions").then(
            (m) => cb(null, m.default)
          );
        }}
      />
      {/*-----------------------------------------------------ADMIN--------------------------------------------------------------------*/}
      <Route authorize={[16, 32]} component={RestrictedContainer}>
        <Route
          path="/admin/dashboard"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Dashboard").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/admin/conferences"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Conferences-List").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/upcoming-hosted-confs/:from/:to"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Upcoming-Hosted-Conferences").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/create-host"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Host").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/create-mentor"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Mentor").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/organizations"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Manage-Organization").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/question-templates"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Question-Template").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/question-templates/edit/hostVideoPic/:templateId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Host-Video-Pic").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/edit-image"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Edit-Image").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/admin/create-conference/:mentorId(/:oldConfCode)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Conference").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/edit-conf-questions/:conferenceId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Edit-Conference-Questions").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        {/* <Route
          path="/admin/admin-raw-input/:conferenceId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import('./views/Admin/Admin-Raw-Input').then((m) => cb(null, m.default));
          }}
        /> */}
        <Route
          path="/admin/copied-from-question"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Copied-From-Question").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/copied-from-question/create"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Copied-From").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/copied-from-question/edit/:questionId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Copied-From").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        {/* <Route
          path="/admin/availability"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import('./views/Admin/Availability').then((m) => cb(null, m.default));
          }}
        /> */}
        <Route
          path="/admin/add-user-widget"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Add-User-Widget").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/user-widgets"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/User-Widgets").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/upload-email-template/:id"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Upload-Email-Template").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/update-users"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Update-Users").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/reporting-message"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Reporting-Messages").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/widgets"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Admin-Widgets").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/widget/create"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Widget").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/widget/edit/:widgetId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Widget").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/merge-accounts"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Merge-Accounts").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/email-history"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Email-History").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/receive-check-outlook-email"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Receive-Check-Outlook-Email").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/confirm-ompc-pending-requests"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Verify-Unverified-Ompc-Hosts").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/conf-invite-statuses"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Conference-Invite-Status").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/edit-image-for-video/:conferenceId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Edit-Image-For-Video").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/task-servers-space-left"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Task-Servers-Space-Left").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/conference-invite-analytics"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Conference-Invite-Analytics").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/create-template"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Template").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/edit-template/:templateId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Edit-Template").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/missed-received-email"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Missed-Received-Mail").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/host-availability"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/User/Host-Availablity").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/pair-wise-rating(/:leftConfId)(/:rightConfId)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Pair-Wise-Ratings").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/add-video-tags(/:mentor)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Add-Video-Tags").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/create-edit-combination-tags"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Combination-Tags").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/conferences/watch/:id"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Watch-Conference").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/conferences/edit-conf-tags/:id"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Conference/Edit-Conference-Tags").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/edit-email-body"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Edit-Email-Body").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/send-template-email/:templateId"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Send-Email-Template-Mail").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/manage-widget-managers"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Manage-Widget-Managers").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/manage-widget-questions"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Manage-Widget-Questions").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/manage-answers"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Manage-Answers").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/send-message(/:mentorId)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Send-Message-New").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/communication-format/:parentTemplate/:version/:confType"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Communication-Format").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/Pending-Conf-Send-Message"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Pending-Conf-Send-Message").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/create-update-question-tags"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Update-Question-Tags").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/communication-history"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Communication-History").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/edit-org"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Edit-Org").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/admin/error-logs"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Error-Logs").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/admin/upload-feedback-questions"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Upload-Feedback-Qstn-File").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/add-chatbot-options"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Add-Chatbot-Options").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/assignments"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Bootcamp/Assignments").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/lectures(/:lectureId)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Bootcamp/Lectures").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/quizs(/:quizId)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Bootcamp/Quizs").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/bootcamp/create-external-course(/:courseId)"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Create-External-Course").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/bootcamp/create-internal-course"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Create-Internal-Course").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/internal-course-list"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Internal-Courses-List").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/cohort-material-list"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import(
              "./views/Admin/Bootcamp/Cohort-Materials/CohortMaterialList"
            ).then((m) => cb(null, m.default));
          }}
        />

        {/* <Route
          path="/admin/bootcamp/cohort-material-creation(/:cohortId)"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import('./views/Admin/Bootcamp/Cohort-Materials/CohortMaterialCreation.js')
              .then((m) => cb(null, m.default));
          }}
        /> */}
        <Route
          path="/admin/bootcamp/cohort(/:cohortId)"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Cohort/Cohort").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/demo/userId"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Cohort/Cohort").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/bootcamp/add-students-in-cohort"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Add-Students-In-Cohort").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/cohort-student-groups"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Cohort-Student-Groups").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/demo-review"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Demo-Review").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/bootcamp/daily-audios(/:cohortId)"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Daily-Audios").then((m) =>
              cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/bootcamp/daily-audios-feedback-template"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Daily-Audio-Feedback-Template").then(
              (m) => cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/bootcamp/daily-audios-feedback-question"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/Bootcamp/Daily-Audio-Feedback-Question").then(
              (m) => cb(null, m.default)
            );
          }}
        />

        <Route
          path="/admin/phoneApp/phone-questions"
          onEnter={redirectToLogin}
          getComponent={(component, cb) => {
            import("./views/Admin/PhoneApp/Phone-Questions").then((m) =>
              cb(null, m.default)
            );
          }}
        />
      </Route>

      {/*---------------------------------------------------SUPER-ADMIN----------------------------------------------------------------*/}
      <Route authorize={[32]} component={RestrictedContainer}>
        <Route
          path="/admin/create-url-for-auto-login-as-user"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Url-For-Auto-Login-As-User").then(
              (m) => cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/navigation-urls"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Navigation-Urls").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/create-admin"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Create-Admin").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/user-roles-management"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/User-Roles").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/admin/cron-page"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Cron-Page").then((m) => cb(null, m.default));
          }}
        />
        <Route
          path="/admin/question-link-tracking-reports"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Question-Link-Tracking-Report").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/invite-send-report"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Invites-Sent-Report").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/rating-details"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Rating-Details").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/get-profile-to-parse-again"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Get-Profile-To-Parse-Again").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/admin/manage-mentor-in-combination-tag(/:combTagId)"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Admin/Manage-Mentor-In-Combination-Tag").then((m) =>
              cb(null, m.default)
            );
          }}
        />
      </Route>

      {/*------------------------------------------------------MANAGER-------------------------------------------------------------------*/}
      <Route authorize={[12, 16, 32]} component={RestrictedContainer}>
        <Route
          path="/manager/add-widget-managers"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Add-Widget-Manager").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/manage-widget-questions"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Manage-Widget-Questions").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/manage-widget-mentors"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Manage-Widget-Mentors").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/widget-track-leads"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Widget-Track-Leads").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/mentor-analytics"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Mentor-Analytics").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/widget-popout"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Manage-Widget-Popout").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/conferences"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Ompc-Conferences-Details").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/set-widget-position"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Set-Widget-Position").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/verify-pending-question"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Verify-Pending-Questions").then((m) =>
              cb(null, m.default)
            );
          }}
        />
        <Route
          path="/manager/verify-pending-request"
          onEnter={redirectToLogin}
          getComponent={(location, cb) => {
            import("./views/Manager/Pending-Request").then((m) =>
              cb(null, m.default)
            );
          }}
        />
      </Route>
    </Route>
    {/*------------------------------------------------------NO-ROUTE------------------------------------------------------------------*/}

    <Route
      path="*"
      getComponent={(location, cb) => {
        import("./views/Public/NoRoute").then((m) => cb(null, m.default));
      }}
    />
  </Router>,
  document.getElementById("root")
);
