'use strict';

import alt from '../altInstance';
import http from 'superagent';
import AlertActions from './AlertActions';
import { ServerError } from '../helpers';

class CertificateActions {
  getQuestionLinkInfo(slugCertId, callback) {
    return (dispatch) => {
      http
        .get(`/api/certificate/${slugCertId}`)
        .end((err, res) => {
          var error = err || res.error ? ServerError(res) : null;

          if (error) {
            return AlertActions.error(error);
          }
          else {
            if (typeof callback === "function") callback(res.body);
          }
        });
    }
  }
}


export default alt.createActions(CertificateActions);
